import { Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text, Tooltip, useDisclosure } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FormButton } from "../../../../components/login/button.component";
import useLoginFormStore from "../../../../store/useLoginFormStore";
import { IResponse } from "../../../../_services/interface/user.interface";
import { checkClientPermission } from "../../Users/utils/checkPermission";
import { ECardStatus} from "../cardRegistration.dto";
import { ICardCompany, ICardList } from "../interfaces/ICardList";
import { KanbanElement } from "./KanbanElement";
import { EditTask } from "../modals/Task/EditTask";
import { EPriority } from "../../../../_services/enum/irs.enum";

interface IKanbanCardProps {
  color: string;
  data: ICardList;
  companyList: ICardCompany[];
	flushHook: React.Dispatch<React.SetStateAction<boolean>>;
  guid_client: string;
  title: string;
}

export const KanbanCard = (props: IKanbanCardProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { validate } = useLoginFormStore();

  const clickCard = () => {
    if (checkClientPermission(validate as IResponse, props.guid_client, 'kanban.write')) {
      onOpen();
    }
  }

  const isFinished = () => {
    let isConcluded = true;
    props.data.companies.map((_x) => _x.status).forEach((_x) => {
      if (_x !== 'concluded') {
        isConcluded = false;
      }
    });

    return isConcluded;
  }

  return (
    <>
      <KanbanElement onClick={clickCard} color={props.color} guid_client={props.guid_client} card={props.data} isFinished={isFinished()} />

      <EditTask
        guid_client={props.guid_client}
        guid_card={props.data.guid_card}
        isOpen={isOpen}
        onClose={onClose}
        flushHook={props.flushHook}
      />
    </>
  )
}

interface IConfirmOperationLote {
  status: string | undefined;
  companyChecked: string[];
  changeAllStatus: () => void;
}

export const ConfirmOperationLote = (props: IConfirmOperationLote) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const yesOption = async () => {
    props.changeAllStatus();
    onClose();
  };

  const disabled = () => {
    if (props.companyChecked.length > 0 && props.status !== undefined && props.status !== '') {
      return false;
    } else {
      return true;
    }
  }

  return (
    <>
      <Tooltip label="Remover Certidão">
        {disabled() ? (
          <FormButton color="#d9d9d9" height="40px" disabled={true}>Confirmar Operação</FormButton>
        ) : (
          <FormButton height="40px" onClick={onOpen}>Confirmar Operação</FormButton>
        )}
      </Tooltip>

      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="sm">
        <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px)' alignItems="center" />
        <ModalContent flexGrow={1} flexShrink={1} borderLeft="12px solid #0263FF" borderRadius={8}>
          <ModalHeader fontFamily="Poppins-SemiBold">Operação em Lote</ModalHeader>
          <ModalCloseButton />
          <ModalBody fontFamily="Poppins-medium" fontSize={12}>
            <Flex justifyContent="center" flexDirection="column" gap={5}>
              <Text>
                Ao realizar essa ação, todas as empresas selecionadas terão seu status alterado para <b>{Object.values(ECardStatus)[Object.keys(ECardStatus).indexOf(props.status as string)]}</b>.
              </Text>

              <Flex gap={2} flexGrow={1} justifyContent="space-between">
                <FormButton onClick={yesOption} width="50%">Sim</FormButton>
                <FormButton onClick={onClose} width="50%">Não</FormButton>
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}