import { FilterRow, FilterTypeEnum } from '../../../../../../components/Filters'
import { formatCnpj } from '../../../../Companies/Company/util/cnpj'
import { stateRegionForTag } from '../../../../../../components/Table/utils'
import { IColumn } from '../../../../../../components/Table/table.interface'
import { ICNDResponse } from '../../../../../../_services/interface/cnd.interface';
import { statusForTagList } from '../../../../CNDManager/util/cndUtils';

export const filterRows = [
  {
    rowFields: [
      {
        name: FilterTypeEnum.companyFilter,
        position: 1,
        size: 6,
      },
      {
        name: FilterTypeEnum.companyGroupFilter,
        position: 2,
        size: 6,
      },
    ],
    position: 1
  }
] as FilterRow[];

export const columns = [
  {
    title: 'Tipo',
    name: 'type',
    sortable: true,
    align: 'center',
    tags: [
      {
        text: 'Matriz',
        value: 'holding',
        color: '#ECFCCB',
        textColor: '#365314'
      },
      {
        text: 'Filial',
        value: 'subsidiary',
        color: '#C4F1F9',
        textColor: '#065666'
      },
    ],
  },
  {
    title: 'CNPJ',
    name: 'cnpj',
    formatter: formatCnpj,
    sortable: true,
    align: 'center',
  },
  {
    title: 'Nome - (Cód)',
    name: 'nameWithCode',
    orderFieldName: 'nameWithCode',
    sortable: true,
    align: 'center',
  },
  {
    title: 'Estado',
    name: 'initials',
    sortable: true,
    align: 'center',
    dynamicTag: {
      function: stateRegionForTag,
      field: 'initials'
    },
  },
  {
    title: 'Cidade',
    name: 'city',
    sortable: true,
    align: 'center',
    tags: [
      {
        color: '#E2E8F0',
        textColor: '#1A202C'
      },
    ]
  }
] as IColumn[];

export const filterRowsCertificates = [
  {
    rowFields: [
      {
        name: FilterTypeEnum.companyFilter,
        position: 1,
        size: 12,
      },
    ],
    position: 1
  },
  {
    rowFields: [
      {
        name: FilterTypeEnum.companyTypeFilter,
        position: 1,
        size: 4,
      },
      {
        name: FilterTypeEnum.companyCodeFilter,
        position: 2,
        size: 4,
      },
      {
        name: FilterTypeEnum.certificateTypeFilter,
        position: 3,
        size: 4,
      },
    ],
    position: 2
  },
  {
    rowFields: [
      
      {
        name: FilterTypeEnum.certificateStatusFilter,
        position: 1,
        size: 4,
      },
      {
        name: FilterTypeEnum.validUntilFilter,
        position: 2,
        size: 4,
      },
      {
        name: FilterTypeEnum.searchTypeFilter,
        position: 3,
        size: 4,
      },
    ],
    position: 3
  },
  {
    rowFields: [
      {
        name: FilterTypeEnum.jurisdictionFilter,
        position: 1,
        size: 4,
      },
      {
        name: FilterTypeEnum.certificateStateFilter,
        position: 2,
        size: 4,
      },
      {
        name: FilterTypeEnum.certificateCityFilter,
        position: 3,
        size: 4,
      },
    ],
    position: 4
  }
] as FilterRow[];

export const columnsCertificate = [
  {
    title: 'Certidão',
    name: 'certificate_name',
    sortable: false,
    align: 'center',
  },
  {
    title: 'Status',
    name: 'status.name',
    orderFieldName: 'slug_status',
    sortable: true,
    align: 'center',
    dynamicTag: {
      function: statusForTagList
    },
  },
  {
    title: 'Titular',
    name: 'entity.displayName',
    orderFieldName: 'company.name,persona.name',
    sortable: true,
    align: 'center',
  },
  {
    title: 'Tipo',
    name: 'entity.type',
    orderFieldName: 'company.type',
    sortable: true,
    align: 'center',
    tags: [
      {
        text: 'Matriz',
        value: 0,
        textColor: '#365314',
        color: '#ECFCCB',
      },
      {
        text: 'Filial',
        value: 1,
        textColor: '#065666',
        color: '#C4F1F9',
      },
      {
        text: 'Pessoa Física',
        value: 3,
        textColor: '#134E4A',
        color: '#CCFBF1',
      },
    ],
  },
  {
    title: 'CNPJ/CPF',
    name: 'entity.documentFormatted',
    orderFieldName: 'company.cnpj,persona.document',
    sortable: true,
    align: 'center',
    icon: (row: ICNDResponse) => {
      if(!row.errorMessage) {
        return {
          tooltipText: '',
          iconUrl: '',
          show: false
        }
      }

      return {
        tooltipText: row.errorMessage,
        iconUrl: '/icons/alert.svg',
        show: true  
      }
    }
  },
  {
    title: 'Grupo',
    name: 'groups_names',
    sortable: true,
    align: 'center',
  },
  {
    title: 'Emissão',
    name: 'issuedAtFormatted',
    orderFieldName: 'issuedAt',
    sortable: true,
    align: 'center',
  },
  {
    title: 'Vencimento',
    name: 'validUntilFormatted',
    orderFieldName: 'validUntil',
    sortable: true,
    align: 'center',
  },
  {
    title: 'Prazo',
    name: 'displayDeadline',
    orderFieldName: 'validUntil',
    sortable: true,
    align: 'center',
    textColor: (row: ICNDResponse) => {
      if(row.deadline && row.deadline < 30) {
        return '#fc1616';
      }
      return '#171923';
    }
  },
  {
    title: 'Jurisdição',
    name: 'jurisdiction',
    sortable: true,
    align: 'center',
    tags: [
      {
        text: 'Federal',
        value: 'federal',
        textColor: '#065666',
        color: '#C4F1F9',
      },
      {
        text: 'Municipal',
        value: 'municipal',
        textColor: '#134E4A',
        color: '#CCFBF1',
      },
      {
        text: 'Estadual',
        value: 'state',
        textColor: '#322659',
        color: '#E9D8FD',
      },
    ],
  },
  {
    title: 'Estado',
    name: 'state.initials',
    sortable: true,
    align: 'center',
    dynamicTag: {
      function: stateRegionForTag,
      field: 'state.initials'
    },
  },
  {
    title: 'Cidade',
    name: 'city.name',
    sortable: true,
    align: 'center',
    tags: [
      { 
        color: '#E2E8F0',
        textColor: '#1A202C'
      },
    ]
  },
  
] as IColumn[];