import { Flex, Text } from "@chakra-ui/react";
import { Draggable } from "react-beautiful-dnd";
import { DragIcon } from "../icons/Drag";
import { IWidgetBase } from "../interfaces/IWidget";

export const WidgetBase = (props: IWidgetBase) => {

  return (
    <Draggable key={props.id} draggableId={props.id} index={props.index}>
      {(provided) => (
        <Flex bgColor="white" flexDirection="column" boxShadow="1px 1px 5px #b7b4b4" borderRadius={4} overflow="hidden" {...provided.draggableProps} ref={provided.innerRef} >
          <Flex bgColor="gray.50" p="14px" borderBottom="1px solid" borderColor="gray.300" alignItems="center" zIndex={10} justifyContent="space-between" {...provided.dragHandleProps} overflow='hidden'>
            <Flex alignItems="center" gap={2} overflow='hidden'>
              <DragIcon boxSize={6} color="gray.300" overflow='hidden'/>
              <Text fontWeight="bold" color="gray.700">{props.title}</Text>
            </Flex>
          </Flex>
          <Flex p={props.p ? props.p : 4} position="relative">
            {props.isLoading ? (
              <Flex bg="whiteAlpha.800" position="absolute" top={0} bottom={0} left={0} right={0} zIndex={997} alignItems="center" justifyContent="center" fontWeight="bold" color="#4B4EFF">
                Carregando Dados...
              </Flex>
            ) : null}
            {props.children}
          </Flex>
        </Flex>
      )}
    </Draggable>
  );
}