import { Flex, Text } from "@chakra-ui/react";
import moment from "moment";
import Chart from "react-google-charts";
import { ICNDResponse } from "../../../../_services/interface/cnd.interface";
import { ICardList } from "../../TaxKanban/interfaces/ICardList";
import { IWidgetIdent } from "../interfaces/IWidget";
import { WidgetBase } from "./WidgetBase";
import { ICertificate } from "../../../../_services/interface/certificate.interface";
import { getCertificatesPropertyValuesRecursively } from "../../Certificate/Util/CertificateUtils";
import { IClientDataResponse } from "../../../../_services/interface/client.interface";
import { RectShape, RoundShape } from "react-placeholder/lib/placeholders";

interface ICndPieWidget extends IWidgetIdent {
  data?: ICNDResponse[] | ICardList[];
  isLoading?: boolean;
  certificates?: ICertificate[];
  currentClient?: IClientDataResponse;
}

interface IStatusReport {
  name: string;
  count: number;
}

export const CndPieWidget = (props: ICndPieWidget) => {
  const cndData = props.data ? props.data as ICNDResponse[] : [];

  const products = props.currentClient?.products ? props.currentClient.products : [];

    const slugsAvailable: string[] = [];
    props.certificates?.forEach((certificate) => {
      if (products[certificate.slug as string]) {
        const slugs = getCertificatesPropertyValuesRecursively([certificate], 'slug' as keyof ICertificate);
        slugs[0]?.forEach((slug: string) => {
          slugsAvailable.push(slug);
        });
      }
    }); 

  if (props.id && props.index !== undefined) {

    const refreshChartData = (cndData: ICNDResponse[]) => {
      const status: IStatusReport[] = [];

      cndData.filter((x: ICNDResponse) => slugsAvailable.includes(x.type))
        .forEach((_x: ICNDResponse) => {
          const currentStatus = _x.status;

          if(!currentStatus) return;

          if (!status.find((x) => x.name === currentStatus.name)) {
            status.push({ name: currentStatus.name as string, count: 0 });
          }

          const findStatus = status.find((x) => x.name === currentStatus.name);
          if(findStatus) findStatus.count++;
        });

      const returnData: (string | number)[][] = [
        ['Emissões', 'CNDs']
      ];

      status.forEach((x) => {
        returnData.push([x.name, x.count]);
      });

      return returnData;
    }

    const refreshBarData = (cndData: ICNDResponse[]) => {
      const months = [
        { month: 1, name: "Jan" },
        { month: 2, name: "Fev" },
        { month: 3, name: "Mar" },
        { month: 4, name: "Abr" },
        { month: 5, name: "Mai" },
        { month: 6, name: "Jun" },
        { month: 7, name: "Jul" },
        { month: 8, name: "Ago" },
        { month: 9, name: "Set" },
        { month: 10, name: "Out" },
        { month: 11, name: "Nov" },
        { month: 12, name: "Dez" },
      ];
  
      //Get the current month
      const currentMonth = parseInt(moment.utc().format("M"));
      //get the current year
      const currentYear = parseInt(moment.utc().format('YYYY'));
  
      const baseObject: any[] = [];
      let yearStart = currentYear;
      let monthStart = currentMonth - 1;
      for (let i = 1; i < 13; i++) {
        if (monthStart === 12) {
          monthStart = 1;
          yearStart++;
        } else {
          monthStart++;
        }
  
        baseObject.push({
          month: monthStart,
          // eslint-disable-next-line no-loop-func
          name: months.find((x) => x.month === monthStart)?.name,
          year: yearStart,
          count: 0,
        })
      }
  
      cndData.forEach((_x: ICNDResponse) => {
        //TODO Verificar porque aqui está dando erro. Não deveria haver nenhum valor undefined
        if (_x.validUntil) {
          const base = baseObject.find((y) => y.month === parseInt(moment.utc(_x.validUntil).format("M")) && y.year === parseInt(moment.utc(_x.validUntil).format("YYYY")));
          if (base) {
            baseObject.find((y) => y.month === parseInt(moment.utc(_x.validUntil).format("M")) && y.year === parseInt(moment.utc(_x.validUntil).format("YYYY"))).count++;
          }
        }
      });
  
      //this part of code search in the array "baseObject" for this and the 5 next's months
      let sixMonthsForNow = baseObject
        .slice(
          baseObject.indexOf(baseObject.find((y) => y.month === parseInt(moment.utc().format("M")) && y.year === parseInt(moment.utc().format("YYYY")))),
          baseObject.indexOf(baseObject.find((y) => y.month === parseInt(moment.utc().format("M")) + 7 && y.year === parseInt(moment.utc().format("YYYY"))))
        )
      const finalData: any[] = [];
      finalData.push(["", ""]);
      sixMonthsForNow.forEach(_x => {
        finalData.push([`${_x.name}/${_x.year.toString().substring(2, 4)}`, _x.count]);
      });
      return finalData;
    }

    const showGraphs = () => {
      const chart = refreshChartData(cndData)
      const showGraph = chart.length > 1;

      let showBar = false;
      const bar = refreshBarData(cndData)
      bar.forEach(item => {
        if (item[1] > 0) showBar = true;
      })

      return (
        <Flex alignItems="center" justifyContent="center" position="relative" gap={2} w="100%">
          <Flex flexDirection="column" w="50%" gap={4} display="grid">
            <Text fontWeight="bold" textAlign="center" color="gray.500">Situação das Certidões</Text>
            {
              props.isLoading ? (
                <RoundShape color="#e0e0e0" style={{width: 170, height: 170, justifySelf: "center"}} />
              ) : showGraph ? 
              <Chart chartType="PieChart" data={chart} options={{ tooltip: { trigger: 'selection' }, chartArea: { width: "85%", height: "85%" }, legend: { position: "right" } }} />
              : "Não há certidões emitidas para esta(s) empresa(s)"
            }
          </Flex>
          <Flex flexDirection="column" w="50%" gap={4}>
            <Text fontWeight="bold" textAlign="center" color="gray.500">Quantidade de certidões (Vencimento)</Text>
            {
              props.isLoading ? (
                <RectShape color='#E0E0E0' style={{ width: 413, height: 200, marginBottom:'5px' }} />
              ) : showBar ? 
                <Chart chartType="Bar" data={bar} options={{ chartArea: { width: "1%", height: "40%" }, legend: { position: 'none' }, colors: ["#4b4eff", "#ce2b2b"] }} />
              : "Não há certidões à vencer nos próximos 6 meses"
            }
          </Flex>
        </Flex>
      )
    }

    return (
      <WidgetBase title="Gerenciador de Certidões - Status e vencimentos" id={props.id} index={props.index}>
        {showGraphs()}
      </WidgetBase>
    );
  } else {
    return null;
  }
}
