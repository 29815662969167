import {
    Image,
    Tooltip,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
    Button,
    Flex,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { useJune } from "../../../../components/June";

interface IViewPDFModalProps {
    title: string,
    file: string,
    openModal?: boolean;
    openModalHook?: React.Dispatch<React.SetStateAction<boolean>>;
    moduleName?: string
}

export const ViewPdfModal = (props: IViewPDFModalProps) => {
    const analytics = useJune();
    const [pdfFile, setPdfFile] = useState("");
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [ext, setExt] = useState<string | null>(null);

    useEffect(() => {
        if (props.openModal && props.openModalHook) {
          onOpen();
          props.openModalHook(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.openModal]);

    useEffect(() => {
        if(isOpen) {
            const extension = props.file.split('.').pop();
            setExt(extension||null);
            setPdfFile(props.file);
        }
        if(isOpen && analytics && props.moduleName === 'Certificate') {
            analytics.track(`Visualizar/Baixar uma Certidão / Consulta Individual`, {
                file: props.file,
                title: props.title,
            });
        }
    }, [props.file, isOpen]);

    const eventDownload = () => {
        if(analytics && props.moduleName && props.moduleName === 'Certificate') {
            analytics.track(`Visualizar/Baixar uma Certidão / Consulta Individual`, {
                file: props.file,
                title: props.title,
            });
        }
    }

    return (
        <>
            {!props.openModalHook ?
                <Flex width="35px" height="35px" justifyContent="center" alignItems="center">
                    <Tooltip label="Clique para visualizar o arquivo">
                        <Flex className="icon-bg-blue">
                            <Button onClick={onOpen} color="white" variant="ghost" padding="0" cursor="default" width="30px" height="30px" minWidth="30px" minHeight="30px">
                                <Image height="20px" width="20px" src="/icons/eye.png" resize="none" _hover={{ cursor: "pointer" }}/>
                            </Button>
                        </Flex>
                    </Tooltip>
                </Flex>
                : null
            }
            <Modal closeOnOverlayClick={true} isOpen={isOpen} onClose={onClose} size="4xl">
                <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" alignItems="center" />
                <ModalContent flexGrow={1} flexShrink={1} borderRadius={8} borderLeft="12px solid #0263FF" >
                    <Flex justifyContent="space-between" w="90%" alignItems="center">
                        <div>
                            <ModalHeader fontFamily="Poppins-Medium">
                                <>{props.title}</>
                            </ModalHeader>
                        </div>
                        {/*needed to be a div with an 'a' tag inside because target=_blank doesnt work with button - a*/}
                        <div style={{ background: '#4B4EFF', color: 'white', padding: '7px', borderRadius: "7px", display: "flex", alignItems: "center" }}>
                            <a href={pdfFile} target="_blank" rel="noopener noreferrer" onClick={eventDownload}>Baixar arquivo</a>
                            <Image height="15px" width="15px" marginLeft="5px" src="/icons/download_icon.png" resize="none"/>
                        </div>

                    </Flex>

                    <ModalCloseButton />
                    <ModalBody fontFamily="Poppins-Medium">
                        {ext === 'pdf' ? (
                            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.min.js">
                                <Viewer fileUrl={pdfFile} />
                            </Worker>
                        ) : ext === 'html' ? (
                            <object data={pdfFile} type="application/pdf" title="Arquivo" height={1000} width={800}>PDF</object>
                        ) : (
                            <p>Este não é um arquivo PDF</p>
                        )}
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>



    )

}


