import { Flex } from "@chakra-ui/react";

export const SUPPORTED_FORMATS = [
  {
    mime: "application/pdf",
    name: "pdf"
  },
  {
    mime: "text/plain",
    name: "txt"
  },
  {
    mime: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    name: "xlxs"
  },
  {
    mime: "application/vnd.ms-excel",
    name: "xlx"
  },
  {
    mime: "application/msword",
    name: "doc"
  },
  {
    mime: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    name: "docx"
  }
];

export const jurisdictionTag = (jurisdiction: string) => {
  if (jurisdiction === 'federal') {
    return (
      <Flex 
        color="#065666" 
        backgroundColor="#C4F1F9" 
        alignItems="center"
        justifyContent="center"
        p="2px 15px"
        fontSize="12px"
        fontFamily="Poppins-medium"
        lineHeight="16.8px"
        fontWeight="100"
        borderRadius={5}
        height="25px"
        width="fit-content"
        minWidth="auto"
      >
        Federal
      </Flex>
    );
  } else if (jurisdiction === 'state') {
    return (
      <Flex 
        color="#322659"
        backgroundColor="#E9D8FD"
        alignItems="center"
        justifyContent="center"
        p="2px 15px"
        fontSize="12px"
        fontFamily="Poppins-medium"
        lineHeight="16.8px"
        fontWeight="100"
        borderRadius={5}
        height="25px"
        width="fit-content"
        minWidth="auto"
      >
        Estadual
      </Flex>
    );
  } else if (jurisdiction === 'municipal') {
    return (
      <Flex 
        color="#134E4A"
        backgroundColor="#CCFBF1"
        alignItems="center"
        justifyContent="center"
        p="2px 15px"
        fontSize="12px"
        fontFamily="Poppins-medium"
        lineHeight="16.8px"
        fontWeight="100"
        borderRadius={5}
        height="25px"
        width="fit-content"
        minWidth="auto"
      >
        Municipal
      </Flex>
    );
  }
}

export const validateFile = (fileUploadValue: FileList) => {
  if (fileUploadValue) {
    if (fileUploadValue.item(0)) {
      if (SUPPORTED_FORMATS.map((e) => e.mime).includes(fileUploadValue.item(0)?.type as string)) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  } else {
    return true;
  }
}

export const cutText = (text: string, size: number): string => {
  return text.length > size ? `${text.substring(0, size)}...` : text;
}
