import { IResponse } from "../../../../_services/interface/user.interface";

export const checkClientPermission = (
  validation: IResponse,
  guid_client: string,
  permission: string
) => {
  const _c = validation.clients.find((x) => x.guid_client === guid_client);
  if (_c) {
    if (_c.permissions.includes("org.owner")) {
      return true;
    }

    if (_c.permissions.includes("org.admin")) {
      return true;
    }

    return _c.permissions.includes(permission);
  } else {
    return false;
  }
};
