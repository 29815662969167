import React from "react";
import ReactDOM from "react-dom/client";
import "../src/styles/index.css";
import "../src/styles/bootstrap.min.css";
import "../src/styles/components/selectizeCustom.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ChakraProvider } from "@chakra-ui/react";
import { CookiesProvider } from "react-cookie";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
//import { theme } from "./styles/theme";

const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

//TODO the "currentLocale" information should be filled using the browser data from the user
//Removed Strict to avoid call the backend two times
root.render(
  <ChakraProvider>
    <CookiesProvider>
      <MsalProvider instance={msalInstance}>
        <App />
      </MsalProvider>
    </CookiesProvider>
  </ChakraProvider>
);

reportWebVitals();
// theme={theme}
