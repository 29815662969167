import {
  AddIcon,
  ArrowDownIcon,
  ArrowUpDownIcon,
  ArrowUpIcon,
} from '@chakra-ui/icons'
import {
  Button,
  Checkbox,
  Flex,
  Image,
  Select,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr
} from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from "react";
import ReactPlaceholder from "react-placeholder/lib";
import { DashInput } from "../../pages/dashboard/Users/components/DashInput";
import { Actions } from "./Actions";
import { Pagination } from "./Pagination";
import { ResultsPerPage } from "./ResultsPerPage";
import { IAction, ICheckRow, IColumn, IData, IDataMeta, ITableMetaData, Field, TransformedRow, IFieldOption } from "./table.interface";
import { Tag } from "./Tag";
import * as XLSX from 'xlsx';
import CustomAsyncSelect from './Inputs/CustomAsyncSelect';
import '../../styles/components/customStyle.css';

interface IProps<T> {
  name: string;
  columns: IColumn[];
  actions?: IAction[];
  customActions?: (row: any) => IAction[];
  data: IData<T>|null;
  dataHook?: React.Dispatch<React.SetStateAction<IData<T>|null>>;
  isLoading?: boolean;
  metaDataHook?: React.Dispatch<React.SetStateAction<ITableMetaData|undefined>>;
  showTotalRows?: boolean;
  orderField?: string;
  orderDirection?: 'asc' | 'desc';
  showHeader?: boolean;
  children?: React.ReactNode;
  refreshSubTableHook?: (row: T) => void;
  pagination?: boolean;
  rowByPage?: boolean;
  checkBox?: ICheckRow;
  fatherRow?: any;
  heigth?: string;
  maxHeigth?: string;
  guid_client?: string;
  customButtons?: any;
}

export const Datatables = <T,>(props: IProps<T>) => {
  const [name, setName] = useState<string>('');
  const [openRow, setOpenRow] = useState<number | null>(null);
  const [rowByPage, setRowByPage] = useState<number>(props.rowByPage === false ? -1 : 10);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [orderField, setOrderField] = useState<string>(props.orderField ? props.orderField : 'createdAt');
  const [orderDirection, setOrderDirection] = useState<string>(props.orderDirection ? props.orderDirection : 'desc');

  const [rederizedSubtable, setRederizedSubtable] = useState<boolean>(false);
  const [data, setData] = useState<any[]|null>(null);
  const [meta, setMeta] = useState<IDataMeta|null>(null);
  const endOfTableRef = useRef<HTMLDivElement>(null);

  const tag = (column: IColumn, row: any) => {
    const tags = column.tags || [];
    let selectedTag;
  
    if (tags[0].value !== undefined) {
      selectedTag = tags.find(tag => {
        if (tag.field !== undefined) {
          return tag.value === row[tag.field];
        } 
        
        const propertyValue = getNestedProperty(row, column);
        const formattedValue = getNestedProperty(row, column, true);
        
        return tag.value === propertyValue || tag.value === formattedValue;
      });
    } else {
      selectedTag = tags[0];
    }
  
    if (selectedTag) {
      return <Tag tag={selectedTag} text={getNestedProperty(row, column, true)} />;
    }
  
    return null; // Retorna null se não houver tag selecionada
  };

  const changeOrder = (column: IColumn) => {
    if(column.sortable === false) {
      return;
    }

    const orderFieldName = column.orderFieldName ?? column.name;

    if (orderFieldName !== orderField) {
      setOrderField(orderFieldName);
      setOrderDirection("desc");
    } else {
      setOrderDirection(orderDirection === "asc" ? "desc" : "asc");
    }
  };

  const elipse = (column: IColumn, row: any) => {
    if(column.elipse === undefined) {
      return (<></>);
    }

    const elipse = column.elipse;

    if (elipse.validation(row[elipse.columnName])) {
      return (
        <Tooltip label={elipse.tooltipTextTrue}>
          <div style={{ backgroundColor: "#43af33", width: "10px", height: "10px", borderRadius: "100%", marginLeft: "10px" }} />
        </Tooltip>
      );
    } else {
      return (
        <Tooltip label={elipse.tooltipTextFalse}>
          <div style={{ backgroundColor: "#999999", width: "10px", height: "10px", borderRadius: "100%", marginLeft: "10px" }} />
        </Tooltip>
      );
    }
  }

  function getNestedProperty(row: any, column: IColumn | string, useFormatter: boolean = true) {
    const columnName = typeof column === 'string' ? column : column.name;
    let value = columnName.split('.').reduce((acc, part) => acc && acc[part], row);
  
    if (typeof column !== 'string' && column.formatter && useFormatter) {
      value = column.formatter(value);
    }
  
    return value;
  }

  const reduceString = (text: string|null, limit: number): string => {
    if(text === null) {
      return '';
    }

    if (text.length <= limit) {
      return text;
    } else {
      return text.substring(0, limit) + '...';
    }
  }

  const tdContent = (column: IColumn, row: any) => {
    if(column.actions) {
      const action = column.actions.find(action => action.getValue(row) === action.value);

      if(action) {
        const validation = action.validation(row);
        return (
          <Flex 
            alignItems="center" 
            justifyContent="center" 
            cursor={action.onClick && validation ? 'pointer' : 'default'}
            onClick={() => action.onClick && validation ? action.onClick(row) : null}
          >
            {action.icon}
          </Flex>
        );
      } else {
        return ' - ';
      }
    }

    const value = getNestedProperty(row, column, true);

    let response = <>{value}</>;

    if(column.reduce) {
      response = (
        <Tooltip label={value}>
          {reduceString(value, column.reduce)}
        </Tooltip>
      );
    }

    if(column.elipse) {
      const elipseContent = elipse(column, row);
      response = (
        <Flex alignItems="center">
          {value}
          {elipseContent}
        </Flex>
      );
    }
  
    if(column.icon) {
      const { tooltipText, iconUrl, show } = column.icon(row);

      if(show) {
        response = (<>
          {value}
          <Tooltip label={tooltipText}>
            <Image ml="5px" src={iconUrl} width={5} height="auto" />
          </Tooltip>
        </>);
      }
    }

    if(column.tooltip) {
      const tooltipMessage = column.tooltip(row);
      if(tooltipMessage) {
        response = (
          <Tooltip label={tooltipMessage}>
            {response}
          </Tooltip>
        );
      }
    }

    if(column.hyperlink) {
      response = (
        <Text 
          color="#007bff" 
          cursor="pointer"
          _hover={{ textDecoration: "underline" }}
          onClick={() => column.hyperlink ? column.hyperlink(row) : null}
        >
          {response}
        </Text>
      );
    }

    return response;
  }

  useEffect(() => {
    if(props.name) {
      setName(props.name.toLowerCase());
    }
  }, [props.name]);

  useEffect(() => {
    if(props.metaDataHook) {
      props.metaDataHook({
        currentPage: page,
        rowByPage: rowByPage,
        totalPages: totalPages,
        orderField: orderField,
        orderDirection: orderDirection,
        totalRows: meta?.totalCount
      });
    }
  }, [page, rowByPage, orderField, orderDirection]);

  useEffect(() => {
    if(props.data) {
      setData(props.data.data);
      setMeta(props.data.meta);
      setOpenRow(null);
    }
  }, [props.data]);

  useEffect(() => {
    if(meta) {
      if (meta.currentPage !== page) {
        setPage(meta.pageCount < meta.currentPage ? meta.pageCount : meta.currentPage);
      }
      setTotalPages(meta.pageCount);
    }
  }, [meta]);

  const renderSubtable = (row: any) => {
    if (props.children && props.refreshSubTableHook) {
      if(!rederizedSubtable) {
        props.refreshSubTableHook(row);
        setRederizedSubtable(true);
      }

      return (
        <Tr>
          <Td colSpan={props.columns.length + (props.actions ? 1 : 0)} p="0">
            <Flex display="block" p="0" backgroundColor="gray.50" borderRadius="6px" border="1px solid var(--primary-600)">
              {React.cloneElement(props.children as React.ReactElement, { fatherRow: row })}
            </Flex>
          </Td>
        </Tr>
      );
    }
    return null;
  }

  const selectAllCheckBox = () => {
    const checkBox = props.checkBox;
    const filteredData = data?.filter(row => row.isActive === undefined || row.isActive === true) || [];
  
    if (checkBox) {
      if(checkBox.checkAll) {
        checkBox.checkAll();
      } else {
        if (checkBox.selecteds.length === filteredData.length) {
          checkBox.setSelecteds([]);
        } else {
          const selectedData = filteredData.map(row => row[checkBox.key]);
          checkBox.setSelecteds(selectedData);
        }
      }
    }
  }

  const handleCheckBox = (e: { target: { value: string, checked: boolean } }) => {
    if(props.checkBox) {
      var updatedList = [...props.checkBox.selecteds];
      if (e.target.checked) {
        updatedList = [...props.checkBox.selecteds, e.target.value];
      } else {
        updatedList.splice(props.checkBox.selecteds.indexOf(e.target.value), 1);
      }
      props.checkBox.setSelecteds(updatedList);
    }
  }

  const randomNumber = (number: number) => {
    const minutosAtuais = new Date().getMinutes();
    let resultado = number * minutosAtuais;

    resultado = (resultado ^ (resultado << 5)) & 0xFFFFFFFF;
    resultado = (resultado + (resultado >> 3)) & 0xFFFFFFFF;
    resultado = Math.abs(resultado);

    const numeroAleatorio = (resultado % 81) + 20;

    return numeroAleatorio;
  }
  const textTotalRows = () => {
    if(props.checkBox) {
      return `Total de ${name} selecionadas: ${props.checkBox.selecteds.length}`;
    }
    return `Total de ${name}: ${meta && meta.totalCount && props.isLoading === false ? meta.totalCount : 0}`;
  }

  const addValueToNewLine = (value: any, column: IColumn, index: number, customName?: string) => {
    if(!props.data) {
      return;
    }

    const dataValues = props.data.data;

    (dataValues as any)[index][customName ? customName : column.name] = value;

    if(props.dataHook) {
      props.dataHook({
        meta: props.data.meta,
        data: dataValues
      })
    }
  }

  const newLineField = (
    field: Field,
    index: number,
    value: string | number | readonly string[] | undefined,
    column: IColumn,
    row: any
  ): React.ReactNode => {
    if(field.type === 'text' && (!field.visible || field.visible(row))) {
      const getValue = (value: string) => {
        if(field.formatter) {
          return field.formatter(value);
        }
        return value;
      }
      return (
        <DashInput
          type={field.type}
          borderBottomColor="gray.300"
          p="6px"
          mt="6px"
          w={column.width ?? '100%'}
          backgroundColor="white"
          borderRadius={6}
          placeholder={field.placeholder}
          onChange={(e) => addValueToNewLine(getValue(e.target.value), column, index, field.customName)}
          value={value}
          disabled={field.disabled !== undefined && field.disabled(row) ? true : false}
        />
      )
    }

    if(field.type === 'number' && (!field.visible || field.visible(row))) {
      return (
        <DashInput
          type="number"
          borderBottomColor="gray.300"
          p="6px"
          mt="6px"
          w={column.width ?? '100%'}
          backgroundColor="white"
          onChange={(e) => {
            let inputValue = e.target.value;
    
            // Convertendo o valor para número e comparando com o valor máximo, se especificado
            if (field.max !== undefined && Number(inputValue) > field.max) {
              inputValue = String(field.max); // Se o valor exceder, definir para o máximo permitido
            }
    
            // Atualizando o valor no estado ou na função
            addValueToNewLine(inputValue, column, index, field.customName);
          }}
          borderRadius={6}
          placeholder={field.placeholder}
          value={value}
          disabled={field.disabled !== undefined && field.disabled(row) ? true : false}
        />
      )
    }

    if(field.type === 'text_line') {
      return (
        <Text
          textAlign="center"
        >
          {field.formatter ? field.formatter(value) : value}
        </Text>
      )
    }

    if (field.type === 'select' && (!field.visible || field.visible(row))) {
      return (
        <CustomAsyncSelect
          field={field}
          row={row}
          column={column}
          value={value}
          addValueToNewLine={addValueToNewLine}
          index={index}
        />
      );
    }
  }

  const createNewLine = (columns: IColumn[]): any => {
    const randomString = Array(36).fill(0).map((_, i) => i === 8 || i === 13 || i === 18 || i === 23 ? '-' : Math.floor(Math.random() * 16).toString(16)).join('');

    const newLine: any = {
      isDatatablesEditable: true,
      datatablesIdentify: randomString,
    };

    columns.forEach(column => {
      newLine[column.name] = null;
    });


    setTimeout(() => {
      endOfTableRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 300);

    return newLine;
  }

  const addNewRow = (): void  =>{
    if (!props.data) {
      return;
    }

    const newLine = createNewLine(props.columns);

    const updatedData = {
      meta: props.data.meta,
      data: [...props.data.data, newLine]
    };

    if (props.dataHook) {
      props.dataHook(updatedData);
    }
  }

  const transformDataXlsx = (jsonData: any[][]): TransformedRow[] => {
    const header = jsonData[0];
    const data = jsonData.slice(1);
  
    const headerMap: { [key: number]: string } = props.columns.reduce((map, col) => {
      const index = header.indexOf(col.title);
      if (index !== -1) {
        map[index] = col.name;
      }
      return map;
    }, {} as { [key: number]: string });
  
    const getSelectValue = (column: IColumn, label: string): string | undefined => {
      const options = column.fieldDataToAdditionalRow?.options;
      if (options) {
        const option = options.find((opt: { label: string; }) => opt.label === label);
        return option ? option.value : undefined;
      }
      return undefined;
    };
  
    return data.map(row => {
      return row.reduce((obj, value, index) => {
        const key = headerMap[index];
        if (key) {
          const column = props.columns.find(col => col.name === key);
          if (column?.fieldDataToAdditionalRow?.type === 'select') {
            const selectValue = getSelectValue(column, value);
            obj[key] = selectValue !== undefined ? selectValue : value;
          } else {
            obj[key] = value;
          }
        }
        return obj;
      }, {} as TransformedRow);
    }).filter(row => {
      return Object.values(row).some(value => value !== null && value !== undefined && value !== '');
    });
  }

  const processXslx = (file: File) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      if (e.target && e.target.result) {
        const data = new Uint8Array(e.target.result as ArrayBuffer);
        const workbook = XLSX.read(data, { type: 'array' });

        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData: any[][] = XLSX.utils.sheet_to_json(worksheet, { header: 1 }) as any[][];
        const transformedData = transformDataXlsx(jsonData);
        
        const lines = transformedData.map((data) => {
          let newLine = createNewLine(props.columns);
          const keys = Object.keys(data);

          keys.map((key) => {
            if(newLine[key] !== undefined) {
              newLine[key] = data[key];
            }
          });

          return newLine;
        })

        if(props.data) {
          const newData = [];
          lines.map((line) => {
            newData.push(line);
          })

          const updatedData = {
            meta: props.data.meta,
            data: [...props.data.data, ...lines]
          };
      
          if (props.dataHook) {
            props.dataHook(updatedData);
          }
        }

      }
    };

    reader.readAsArrayBuffer(file);
  };

  const uploadXslx = () => {
    // Create a file input element
    const fileInput = document.createElement('input');

    // Set the type to 'file' to allow file selection
    fileInput.type = 'file';

    // Set the 'accept' attribute to only allow .xlsx files
    fileInput.accept = '.xlsx';

    // Add a 'change' event listener to process the file when the user selects a file
    fileInput.addEventListener('change', (e) => {
      const target = e.target as HTMLInputElement;
      const file = target.files ? target.files[0] : null;
      if (file) {
        processXslx(file);
      }
    });

    setTimeout(() => {
      endOfTableRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 300);

    // Simulate a click on the file input element to open the file selection window
    fileInput.click();
  };

  type FunctionMapKeys = 'addNewRow';
  const functionMap = {
    addNewRow,
    uploadXslx,
  };

  const isChecked = (): boolean => {
    // props.checkBox.selecteds.length >= (data?.filter((row) => row.isActive === undefined || row.isActive === true).length || 0)

    let allIsChecked = true;

    data?.filter((row) => row.isActive === undefined || row.isActive === true).forEach((row) => {
      if(!props.checkBox?.selecteds.includes(row[props.checkBox?.key])) {
        allIsChecked = false;
      }
    });

    return allIsChecked;
  }

  return (<>
    <Flex flexDirection="column" overflow="auto">
      {props.rowByPage === undefined || props.rowByPage ? (
        <Flex justifyContent="flex-end" marginBottom="15px">
          <ResultsPerPage rowByPage={rowByPage} setRowByPage={setRowByPage}/>
        </Flex>
      ) : null}

      {props.isLoading ? (
        <TableContainer 
          overflow="inherit"
          overflowY="inherit"
          backgroundColor="white" 
          fontFamily="Poppins-medium" 
          flexGrow="1" 
          flexShrink="1" 
          border="1px" 
          borderColor="gray.200" 
          borderRadius="6px"
          className='custom-scroll'
        >
          <Table
            variant='striped' 
            colorScheme='gray' 
            fontSize="12px" 
            borderRadius={5}
          >
            {(props.showHeader === undefined || props.showHeader) && (
              <Thead
                height="50px"    
              >
                <Tr>
                  {
                    props.columns.map((column, index) => (
                      <Th 
                        key={index}
                      >
                        <Flex
                          display="flex"
                          alignItems="center"
                          justifyContent={column.align || 'left'}
                          flexDirection="row"
                          fontWeight="600"
                          fontFamily="Poppins-medium"
                          fontSize="14px"
                          textTransform="none"
                          lineHeight="19.6px"
                          color="#1A202C"
                        >
                          {column.title}
                        </Flex>
                      </Th>
                    ))
                  }
                  
                </Tr>
              </Thead>
            )}

            <Tbody>
              {
                Array.from({ length: 10 }, (_, index) => (
                  <React.Fragment key={index}>
                    <Tr key={index}>
                      {
                        props.columns.map((column, indexColumn) => (
                          <Td 
                            key={indexColumn}
                            w={column.width ? column.width : 'auto'}
                          >
                            <Flex 
                              w="100%"
                              color="#171923"
                              fontSize="12px"
                              fontFamily="Poppins-medium"
                              textAlign={column.align || 'left'}
                              justifyContent={column.align || 'left'}
                              cursor="default"
                            >
                              <ReactPlaceholder showLoadingAnimation  type='text' ready={false} rows={1} style={{width: `${randomNumber(index)}%`}}>
                                <Text></Text>
                              </ReactPlaceholder>
                            </Flex>
                          </Td>
                        ))
                      }
                    </Tr>
                  </React.Fragment>
                ))
              }
              
            </Tbody>
          </Table>
        </TableContainer>
      ) : (
        <>
          <TableContainer
            overflow="inherit"
            overflowY="inherit"
            backgroundColor="white"
            fontFamily="Poppins-medium"
            flexGrow="1"
            flexShrink="1"
            border="1px"
            borderColor="gray.200"
            borderRadius="6px"
            className='custom-scroll'
            style={{
              height: props.heigth || 'auto',
              maxHeight: props.maxHeigth || 'auto',
              overflowY: props.heigth ? 'auto' : 'inherit'
          }}
          >
            <Table
              variant='striped'
              colorScheme='gray'
              fontSize="12px"
              borderRadius={5}
            >
              {(props.showHeader === undefined || props.showHeader) && (
                <Thead
                  height="50px"
                >
                  <Tr>
                    {props.checkBox && (
                      <Th
                        width={'5%'}
                        textAlign="center"
                        textTransform="none"
                        _hover={{ cursor: "pointer" }}
                        fontFamily="Poppins-Medium"
                      >
                        <Checkbox
                          size="md"
                          colorScheme="green"
                          isChecked={isChecked()}
                          onChange={selectAllCheckBox}
                        />
                      </Th>
                    )}
                    {
                      props.columns.map((column, index) => (
                        <Th
                          key={index}
                          w={column.width ? column.width : 'auto'}
                        >
                          <Flex
                            display="flex"
                            alignItems="center"
                            justifyContent={column.align || 'left'}
                            flexDirection="row"
                            fontWeight="600"
                            fontFamily="Poppins-medium"
                            fontSize="14px"
                            textTransform="none"
                            lineHeight="19.6px"
                            color="#1A202C"
                            cursor={column.sortable ? 'pointer' : 'default'}
                            onClick={() => {
                              changeOrder(column);
                            }}
                          >
                            {column.title}

                            {
                              column.sortable ?
                                (<>
                                  {orderField !== column.name && orderField !== column.orderFieldName ? (
                                    <ArrowUpDownIcon />
                                  ) : orderDirection === "desc" ? (
                                    <ArrowDownIcon />
                                  ) : (
                                    <ArrowUpIcon />
                                  )}
                                </>)
                              : null
                            }
                          </Flex>
                        </Th>
                      ))
                    }

                    {props.actions && (<Th></Th>)}
                  </Tr>
                </Thead>
              )}

              <Tbody>
                <>
                  {
                    data && data.map((row, index) => (
                      row.isDatatablesEditable === undefined || row.isDatatablesEditable === false
                      ? (
                        <React.Fragment key={index}>
                          <Tr key={index}>
                            {props.checkBox && (
                              <Td display="table-cell">
                                <Checkbox
                                  isDisabled={row.isActive === false}
                                  size="md"
                                  colorScheme="green"
                                  value={row[props.checkBox.key]}
                                  isChecked={props.checkBox.selecteds.includes(row[props.checkBox.key])}
                                  onChange={handleCheckBox}
                                />
                              </Td>
                            )}
                            {
                              props.columns.map((column, indexColumn) => (
                                <Td
                                  key={indexColumn}
                                  w={column.width ? column.width : 'auto'}
                                >
                                  {
                                    column.alwaysField
                                    ? (
                                      newLineField(
                                        column.fieldDataToAdditionalRow,
                                        index,
                                        getNestedProperty(row, column, false),
                                        column,
                                        row
                                      )
                                    ) : (
                                      column.customComponent ? column.customComponent(row) : (
                                        <Flex
                                          w="100%"
                                          color={column.textColor ? (typeof column.textColor === 'function' ? column.textColor(row) : column.textColor) : '#171923'}
                                          fontSize="12px"
                                          fontFamily="Poppins-medium"
                                          textAlign={column.align || 'left'}
                                          justifyContent={column.align || 'left'}
                                          cursor={(column.subTable && column.subTable(row)) || (column.subTableNoIcon && column.subTableNoIcon(row)) ? 'pointer' : 'default'}
                                          onClick={() => {
                                            if((column.subTable && column.subTable(row)) || (column.subTableNoIcon && column.subTableNoIcon(row))) {
                                              setOpenRow(openRow === index ? null : index);
                                              setRederizedSubtable(false);
                                            }
                                          }}
                                        >
                                          {
                                            column.dynamicTag
                                            ? <Tag 
                                                tag={
                                                  column.dynamicTag.field
                                                  ? column.dynamicTag.function(getNestedProperty(row, column.dynamicTag.field)) 
                                                  : column.dynamicTag.function(row)
                                                }
                                                elipse={column.dynamicTag.elipse}
                                                row={row}
                                              />
                                            : column.tags
                                                ? tag(column, row)
                                                : tdContent(column, row) ?? ' - '
                                          }
                                          {column.subTable && column.subTable(row) && (
                                            <Image
                                              ml="10px"
                                              src="/icons/arrow-chevron-black.svg"
                                              className={openRow === index ? "rotate" : "rotate-reverse"}
                                            />
                                          )}
                                        </Flex>
                                      )
                                    )
                                  }
                                </Td>
                              ))
                            }
                            {props.actions && (
                              <Td display="table-cell">
                                <Actions
                                  guid_client={props.guid_client}
                                  actions={props.actions}
                                  customActions={props.customActions}
                                  row={row}
                                  rows={data}
                                  rowFather={props.fatherRow}
                                  index={index}
                                />
                              </Td>
                            )}
                          </Tr>
                          { openRow === index ? renderSubtable(row) : null}
                        </React.Fragment>
                      )
                      : (
                        <React.Fragment key={index}>
                          <Tr key={index}>
                            {props.columns.map((column, indexColumn) => (
                              <Td key={indexColumn}>
                                <Flex flexDirection="row" alignItems="center">
                                  {Array.isArray(column.fieldDataToAdditionalRow) ? (
                                    column.fieldDataToAdditionalRow.map((field, fieldIndex) => (
                                      <React.Fragment key={fieldIndex}>
                                        {newLineField(
                                          field,
                                          index,
                                          getNestedProperty(row, column, false),
                                          column,
                                          row
                                        )}
                                      </React.Fragment>
                                    ))
                                  ) : column.fieldDataToAdditionalRow && column.fieldDataToAdditionalRow.type ? (
                                    newLineField(
                                      column.fieldDataToAdditionalRow,
                                      index,
                                      getNestedProperty(row, column, false),
                                      column,
                                      row
                                    )
                                  ) : (
                                    ' - '
                                  )}
                                </Flex>
                              </Td>
                            ))}
                            {props.actions && (
                                <Td display="table-cell">
                                  <Actions
                                    guid_client={props.guid_client}
                                    actions={props.actions}
                                    customActions={props.customActions}
                                    row={row}
                                    rows={data}
                                    rowFather={props.fatherRow}
                                    index={index}
                                  />
                                </Td>
                              )}
                          </Tr>
                        </React.Fragment>
                      )
                    ))
                  }
                </>

                <div ref={endOfTableRef}></div>
              </Tbody>
            </Table>
          </TableContainer>


          {props.customButtons && (
            <Flex p={5} justifyContent={'center'} flexDirection={'row'}>
              {props.customButtons.map((button: any) => (
                  button.customComponent ? (
                    <Flex
                      mr={button.mr ?? null}
                      ml={button.ml ?? null}
                      mt={button.mt ?? null}
                      mb={button.mb ?? null}
                      pr={button.pr ?? null}
                      pl={button.pl ?? null}
                      pt={button.pt ?? null}
                      pb={button.pb ?? null}
                      justifyContent={button.justifyContent ?? null}
                    >
                      {button.customComponent}
                    </Flex>
                  ) : (
                    <Button
                      leftIcon={button.leftIcon ?? <AddIcon />}
                      bgColor={button.bgColor ?? '#4B4EFF'}
                      color={button.color ?? 'white'}
                      fontWeight={button.fontWeight ?? '500'}
                      fontSize={button.fontSize ?? '12px'}
                      mr={button.mr ?? null}
                      ml={button.ml ?? null}
                      mt={button.mt ?? null}
                      mb={button.mb ?? null}
                      pr={button.pr ?? null}
                      pl={button.pl ?? null}
                      pt={button.pt ?? null}
                      pb={button.pb ?? null}
                      border={button.border ?? null}
                      borderRadius={button.borderRadius ?? null}
                      onClick={button.action ? functionMap[button.action as FunctionMapKeys] : undefined}
                      _hover={button.hover ?? { bg: '#282be0' }}
                    >
                      {button.label ?? null}
                    </Button>
                  )
              ))}
            </Flex>
          )}
        </>
      )}

      {props.pagination === undefined || props.pagination ? (
        <Flex flexDirection="row" justifyContent="space-between" alignItems="center">
          <Flex>
            {props.showTotalRows && (
              <Text
                color="#171923"
                fontSize="14px"
                fontFamily="Poppins-medium"
              >
                {textTotalRows()}
              </Text>
            )}
          </Flex>
          
          <Flex>
            <Pagination
              currentPage={page}
              pageCallback={setPage}
              totalPages={totalPages}
            />
          </Flex>
        </Flex>
      ) : null}
    </Flex>
  </>);
}