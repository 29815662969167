import { Button, Flex, IconButton, Image, Input, Text } from '@chakra-ui/react'
import { JSXElementConstructor, ReactElement, ReactFragment, ReactPortal,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  ICardStructureHistory,
  IComment,
} from '../../../../../../_services/interface/irs.interface'
import CommentCard from './CommentCard'
import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { EIRSHistory } from '../../../cardRegistration.dto'
import { ECompanyStatus } from "../../../../../../_services/enum/irs.enum";
import { sendComments } from '../../../../../../_services/irs.service'
import useLoginFormStore from '../../../../../../store/useLoginFormStore'
import { MentionsInput, Mention } from 'react-mentions'
import { mentionsInputStyles, mentionStyle } from './MentionStyle'
import { FileComponent } from './FileMentionStyle';
import { listUsers } from '../../../../../../_services/user.service';
import { IFilterListUser } from '../../../../../../_services/interface/user.interface';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { At } from '../../../../../../utils/Icons/At';
import { Attach } from '../../../../../../utils/Icons/Attach';
import {
  ICertificate
} from '../../../../../../_services/interface/certificate.interface'
import {
  ICNDListData
} from '../../../../../../_services/interface/cnd.interface'

interface FieldTranscription {
  [key: string]: string;
}

interface StatusTranslations {
  [key: string]: string;
}

interface ICommentPanel {
  history?: ICardStructureHistory[];
  guid_card: string;
  guid_client: string;
  comments: IComment[] | undefined;
  refresh?: () => void;
  setCommentPanelReduced: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IUserComment {
  id: string;
  display: string;
}

export const CommentPanel = (props: ICommentPanel) => {
  const { validate } = useLoginFormStore();
  const [sending, setSending] = useState<boolean>(false);
  const [openComment, setOpenComment] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [filesComment, setFilesComment] = useState<File[]>([]);
  const [comments, setComments] = useState<IComment[]>([]);
  const [users, setUsers] = useState<IUserComment[]>([]);
  const [tabIndex, setTabIndex] = useState<number>(0);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const mentionsInputRef = useRef<HTMLTextAreaElement>(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [panelReduced, setPanelReduced] = useState<boolean>(false);

  const clickedButtonRef = useRef<string | null>(null);

  useEffect(() => {
    props.setCommentPanelReduced(panelReduced)
  }, [panelReduced]);

  useEffect(() => {
    if (props.comments) {
      setComments(props.comments);
    }
  }, [props.comments]);

  useEffect(() => {
    getCollaboratorsList();
  }, []);

  useEffect(() => {
    if (openComment && mentionsInputRef.current) {
      mentionsInputRef.current.focus();
    }
  }, [openComment]);

  useEffect(() => {
    scrollToBottom();
  }, [comments, tabIndex]);

  const scrollToBottom = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = scrollContainerRef.current.scrollHeight;
    }
  };

  const getCollaboratorsList = async () => {
    if (props.guid_client) {
      const filters: IFilterListUser = {
        page: 1,
        row_by_page: -1,
        order_field: 'createdAt',
        order_direction: 'desc',
        isActive: 'true',
      };

      const { status, response } = await listUsers(props.guid_client, filters);

      if (status === 200 && response.meta.pageCount !== undefined && response.data !== undefined) {
        const usersList: IUserComment[] = [];
        response.data.map((x: { organizations: { guid_client: string; guid_collaborator: any; }[]; name: any; email: any; isActive: boolean }) => {
          x.organizations.map((organization: { guid_client: string; guid_collaborator: any; }) => {
            if (organization.guid_client === props.guid_client) {
              usersList.push({
                id: organization.guid_collaborator,
                display: x.name || x.email.split('@')[0],
              });
            }
          });
        });
        setUsers(usersList);
      }
    }
  };

  const handleBlur = () => {
    if (!clickedButtonRef.current && message.trim() === '' && filesComment.length === 0) {
      setOpenComment(false);
    }
    clickedButtonRef.current = null;
  };

  const handleAttachmentClick = () => {
    if (fileInputRef.current && !sending) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files as FileList);
    setFilesComment((prevFiles) => (prevFiles ? [...prevFiles, ...files] : [...files]));
  };

  const handleRemoveFile = (index: number) => {
    setFilesComment((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const arrayToFileList = (files: File[]): FileList => {
    const dataTransfer = new DataTransfer();
    files.forEach((file) => dataTransfer.items.add(file));
    return dataTransfer.files;
  };

  const sendMessage = async () => {
    setSending(true);

    const processMentions = (text: string) => {
      const mentionPattern = /@\{(.*?)\}\((.*?)\)/g;
      let match;
      const mentions = [];
      let processedText = text;
  
      while ((match = mentionPattern.exec(text)) !== null) {
        mentions.push(match[2]);
        processedText = processedText.replace(match[0], `@{${match[2]}}`);
      }
  
      return { processedText, mentions };
    };

    const fileList = arrayToFileList(filesComment);
    const { processedText, mentions } = processMentions(message);

    const { status } = await sendComments(props.guid_client, props.guid_card, {
      files: fileList,
      text: processedText,
      mentions: mentions,
    })
    
    if(props.refresh) {
      props.refresh();
    }

    if (status === 200) {
      setFilesComment([]);
      setMessage('');
      setOpenComment(false);

      const formatMessageForDisplay = (text: string) => {
        const mentionPattern = /@\{(.*?)\}\((.*?)\)/g;
        return text.replace(mentionPattern, '@{$1}');
      };

      const uploadedFiles = Array.from(fileList).map(file => {
        const url = URL.createObjectURL(file);
        return {
          createdAt: new Date(),
          url: url,
          name: file.name,  // Add the file name separately
        };
      });

      const comment = {
        author: validate?.name ?? '',
        files: uploadedFiles,
        replies: [],
        text: formatMessageForDisplay(message),
        createdAt: new Date(),
        updatedAt: new Date(),
      };

      setComments((prev) => [...prev, comment]);
    }
    setSending(false);
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const formattedDate = format(date, 'dd MMMM', { locale: ptBR });
    return formattedDate;
  };

  const formatDateComment = (date: Date) => {
    return format(date, "MMM dd 'às' HH:mm", { locale: ptBR });
  };

  const authorOrSystem = (history: ICardStructureHistory) => {
    if (history.author) {
      return <Text as="b" color="#4B4EFF">{history.author.users.name}</Text>;
    } else {
      return <Text as="b" color="gray.500">Sistema</Text>;
    }
  };

  const getHistoryCertificateTitle = (certificate: any) => {
    if (certificate) {
      switch (certificate.jurisdiction) {
        case 'federal':
          return `federal <b>${certificate.certificate_name}</b> da empresa <b>${certificate.entity_name}</b>`;

        case 'state':
          return `<b>${certificate.certificate_name}</b> do estado de <b>${certificate.state_name}</b> da empresa <b>${certificate.entity_name}</b>`;

        case 'municipal':
          return `<b>${certificate.certificate_name}</b> do munícipio de <b>${certificate.city_name} (${certificate.state_initials})</b> da empresa <b>${certificate.entity_name}</b>`;
      }
    }

    return;
  }

  const getHistoryUpdatedTitle = (history: ICardStructureHistory) => {
    const fieldTranscription: FieldTranscription = {
      'priority': 'a prioridade',
      'dueDate': 'a data de vencimento',
      'status': 'o status',
      'description': 'a descrição',
      'name': 'o nome',
    };

    const priorityTranslations: StatusTranslations = {
      'URGENT': 'urgente',
      'HIGH': 'alta',
      'REGULAR': 'normal',
      'LOW': 'baixa',
      'DOING': 'Fazendo',
      'PENDENCY': 'Pendente',
      'DONE': 'Finalizado',
      'open': 'Aberto',
      'rectified': 'Retificado',
      'filled': 'Preenchido',
      'reviewed': 'Revisado',
      'transmitted': 'Transmitido',
      'executed': 'Executado',
      'inprogress': 'Em progresso',
      'pending': 'Pendente',
      'paidout': 'Pago',
      'concluded': 'Concluído',
    };

    let field;
    let oldData;
    let newData;

    if (typeof history.newData === 'string' && typeof history.oldData === 'string') {
      if (history.type === 'updated') {
        field = fieldTranscription[history.field as string];
        oldData = priorityTranslations[history.oldData as string];
        newData = priorityTranslations[history.newData as string];
      }

      if (history.type === 'statusChanged') {
        field = `o status d${history.certificate ? `o certificado ${getHistoryCertificateTitle(history.certificate)}` : `a empresa <b>${history.company?.company?.name}</b>`}`;
        oldData = priorityTranslations[history.oldData as string];
        newData = priorityTranslations[history.newData as string];

        return `alterou ${field} de <b>${oldData}</b> para <b>${newData}</b>`;

      }

      if (history.field === 'status') {
        field = 'o status';
        oldData = priorityTranslations[history.oldData as string];
        newData = priorityTranslations[history.newData as string];
      }

      if (history.field === 'dueDate') {
        field = fieldTranscription[history.field as string];
        oldData = formatDateToDDMMYY(history.oldData);
        newData = formatDateToDDMMYY(history.newData);
      }

      if (history.field === 'description' || history.field === 'name') {
        field = fieldTranscription[history.field as string];
        oldData = history.oldData;
        newData = history.newData;
        if (history.field === 'description' && !oldData) {
          return `adicionou a descrição <b>${newData}</b> ao card`;
        }
      }
    }
    if (field && oldData && newData) {
      return `alterou ${field} do card de <b>${oldData}</b> para <b>${newData}</b>`;
    }

    return 'alterou dados do card.'
  }

  const formatDateToDDMMYY = (dateString: string): string => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear()).slice(-2);
    return `${day}/${month}/${year}`;
  };

  const finalText = (history: ICardStructureHistory) => {
    const companyName = history.company?.company?.name;
    let certificateName = '';
    if(history?.certificate && Array.isArray(history.certificate) && history.certificate[0].certificate_name) {
      certificateName = ` - ${history.certificate[0].certificate_name}`;
    }

    if(history?.certificate && !Array.isArray(history.certificate) && history.certificate.certificate_name) {
      certificateName = ` - ${history.certificate.certificate_name}`;
    }

    const responsibleName = (responsible: any) => {
      if(!responsible || !responsible.users) {
        return;
      }

      return responsible.users.name;
    }

    switch (history.type) {
      case EIRSHistory.created:
        return (<>{authorOrSystem(history)} criou o card.</>);
      case EIRSHistory.updated:
        return (<>{authorOrSystem(history)} <span dangerouslySetInnerHTML={{ __html: `${getHistoryUpdatedTitle(history)}.` }} /></>);
      case EIRSHistory.statusChanged:
        return (<>{authorOrSystem(history)} <span dangerouslySetInnerHTML={{ __html: `${getHistoryUpdatedTitle(history)}.` }} /></>);
      case EIRSHistory.addedFile:
        return (<>{authorOrSystem(history)} adicionou o anexo <b>{history.action}</b> à empresa <b>{companyName}</b>.</>);
      case EIRSHistory.deletedFile:
          return (<>{authorOrSystem(history)} excluiu o anexo <b>{history.action}</b> à empresa <b>{companyName}</b>.</>);
      case EIRSHistory.addedComment:
        return (<>{authorOrSystem(history)} adicionou um comentário.</>);
      case EIRSHistory.concluded:
        return (<>{authorOrSystem(history)} concluiu o card.</>);
      case EIRSHistory.addedResponsible:
        return (<>{authorOrSystem(history)} adicionou o responsável <b>{responsibleName(history?.responsible)}</b> no card.</>);
      case EIRSHistory.removedCompany:
        return (<>{authorOrSystem(history)} removeu a empresa <b>{companyName}</b>.</>);
      case EIRSHistory.removedResponsible:
        return (<>{authorOrSystem(history)} removeu o responsável <b>{responsibleName(history?.responsible)}</b> do card.</>);
      case EIRSHistory.addedCompany:
        return (<>{authorOrSystem(history)} adicionou a empresa <b>{companyName}</b>.</>);
      case EIRSHistory.addedCertificate:
        if (history.certificate) {
          return (<>{authorOrSystem(history)} adicionou a certidão <span dangerouslySetInnerHTML={{__html: `${getHistoryCertificateTitle(history.certificate)}`}}/>.</>);
        }
        return;
      case EIRSHistory.removedCertificate:
        if (history.certificate && !Array.isArray(history.certificate)) {
          return (<>{authorOrSystem(history)} removeu a certidão <span dangerouslySetInnerHTML={{ __html: `${getHistoryCertificateTitle(history.certificate)}.` }} /></>);
        }

        if (history.certificate && Array.isArray(history.certificate)) {
          return history.certificate.map((cert) => {
            return (<>{authorOrSystem(history)} removeu a certidão <span dangerouslySetInnerHTML={{ __html: `${getHistoryCertificateTitle(cert)}.` }} /></>);
          })
        }
        return;
      case EIRSHistory.certificateStatusChanged:
        return (
          <>
            <Text as="b" color="#4B4EFF">
              {companyName} {certificateName}
            </Text> alterou o status de "{history.oldData}" para "{history.newData}".
          </>
        );

      default:
        return null;
    }
  };

  const Circle = () => {
    const circleStyle = {
      width: "4px",
      height: "4px",
      backgroundColor: "#A0AEC0",
      borderRadius: "50%",
      borderColor: "var(--chakra-colors-chakra-border-color)",
    } as React.CSSProperties;

    const divParentStyle = {
      paddingTop: "6px",
      display: "flex",
      justifyContent: "start",
      alignItems: "start",
      height: "100%",
    } as React.CSSProperties;

    return (
      <div style={divParentStyle}>
        <div style={circleStyle}></div>
      </div>
    );
  };

  const getUserInitials = (name: string) => {
    const initials = name.split(' ').map((part) => part[0]).join('');
    return (initials.length > 2 ? initials[0] + initials[1] : initials).toUpperCase();
  };

  return (
    <>
      {
        panelReduced
        ? (
          <Flex
            justifyContent="center"
            alignItems="flex-start"
            borderRadius="6px"
            border="1px solid #CBD5E0"
            h="100%"
            width="50px"
            onClick={() => setPanelReduced(!panelReduced)}
            cursor="pointer"
            paddingTop="15px"
          >
            <Image
              width="30px"
              height="30px"
              src="/icons/two_arrow_to_right.svg"
              cursor="pointer"
              onClick={() => setPanelReduced(!panelReduced)}
              className={panelReduced ? 'rotate' : 'rotate-reverse'}
            />
          </Flex>
        )
        : (
          <Tabs
            selectedIndex={tabIndex}
            onSelect={(index) => setTabIndex(index)}
          >
            <Flex
              justifyContent="space-between"
              bg="white"
            >
              <Image
                width="50px"
                height="50px"
                paddingLeft="10px"
                paddingRight="10px"
                src="/icons/two_arrow_to_right.svg"
                cursor="pointer"
                onClick={() => setPanelReduced(!panelReduced)}
                className={panelReduced ? 'rotate' : 'rotate-reverse'}
              />

              <TabList style={{width: "auto", margin: "-1px 0 0 0"}}>
                <Tab style={{display: "flex", justifyContent: "center"}}>Comentários</Tab>
                <Tab style={{display: "flex", justifyContent: "center"}}>Histórico de Atividades</Tab>
              </TabList>
            </Flex>

            <TabPanel>
              <Flex flexDirection={"column"} height="100%" justifyContent="space-between">
                <Flex
                  pl="15px"
                  height="100%"
                  maxHeight="958px"
                  pr="15px"
                  flexDirection="column"
                  flexGrow={1}
                  mb="25px"
                  className="cardsComment"
                >
                  <Flex
                    ref={scrollContainerRef}
                    className="cardsCommentScroll"
                    flexDirection="column"
                    flexGrow={1}
                    overflowY="auto"
                    w="100%"
                  >
                    {comments.map((comment: IComment) => (
                      <Flex mt="15px" w="100%" key={comment.createdAt.toString()}>
                        <CommentCard
                          username={comment.author}
                          userInitials={getUserInitials(comment.author)}
                          date={formatDateComment(comment.createdAt)}
                          mention=""
                          message={comment.text}
                          files={comment.files}
                        />
                      </Flex>
                    ))}
                  </Flex>
                </Flex>

                <Flex alignItems="flex-end" mb="15px" className="sendComment">
                  <Flex alignItems="center" width="100%" pr="16px" pl="16px">
                    <Flex
                      flexDirection="column"
                      alignItems="flex-end"
                      border="1px solid var(--chakra-colors-chakra-border-color)"
                      borderRadius="4px"
                      style={{
                        padding: 16,
                        cursor: !openComment ? 'pointer' : 'default',
                      }}
                      flex="1"
                      boxShadow="0px 11px 24px -22px rgba(0,0,0,0.75)"
                      height="auto"
                      bg="white"
                      justifyContent="flex-end"
                      onClick={!openComment ? () => setOpenComment(true) : undefined}
                    >
                      <Flex flexDirection="column" w="100%" maxHeight="100vh">
                        <Flex w="100%" display={openComment ? 'flex' : 'none'}>
                          <MentionsInput
                            style={mentionsInputStyles}
                            value={message}
                            onChange={(e: { target: { value: SetStateAction<string>; }; }) => setMessage(e.target.value)}
                            placeholder="Digite um comentário..."
                            disabled={sending}
                            inputRef={mentionsInputRef}
                            onBlur={handleBlur}
                          >
                            <Mention
                              className={'commentClass'}
                              trigger="@"
                              markup="@{__display__}(__id__)"
                              data={users}
                              displayTransform={(id: any, display: any) => `@${display}`}
                              style={mentionStyle}
                              renderSuggestion={(
                                suggestion: any, search: any,
                                highlightedDisplay: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | ReactFragment | ReactPortal | null | undefined,
                                index: any, focused: any) => (
                                  <>{highlightedDisplay}</>
                              )}
                            />
                          </MentionsInput>

                          <input
                            type="file"
                            multiple={true}
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                          />
                        </Flex>

                        {filesComment.length > 0 && openComment ? (
                          <Flex paddingLeft="9px" paddingRight="9px" paddingBottom="10px" w="100%" flexDirection="column">
                            {filesComment?.map((file, index) => (
                              <FileComponent
                                key={index}
                                name={file.name}
                                size={file.size}
                                onRemove={() => handleRemoveFile(index)}
                              />
                            ))}
                          </Flex>
                        ) : null}
                      </Flex>

                      <Flex alignItems="center" flexDirection="row" w="100%" justifyContent="flex-end">
                        <Flex w="100%" display={openComment ? 'none' : 'flex'} cursor="pointer">
                          <Text
                            id={'comment'}
                            fontFamily="Poppins-medium"
                            fontSize="12px"
                            style={{
                              marginLeft: '8px',
                              color: '#A0AEC0',
                              display: openComment ? 'none' : 'block',
                            }}
                          >
                            Digite um comentário...
                          </Text>
                        </Flex>

                        <Flex w="30%" justifyContent="flex-end" alignItems="center">
                          <IconButton
                            aria-label="Fazer menção"
                            icon={<At />}
                            variant="ghost"
                            onMouseDown={() => clickedButtonRef.current = 'mention'}
                            onClick={() => {
                              setMessage((prevMessage) => {
                                const newMessage = `${prevMessage}@`;
                                setTimeout(() => {
                                  if (mentionsInputRef.current) {
                                    mentionsInputRef.current.focus();
                                    mentionsInputRef.current.setSelectionRange(newMessage.length, newMessage.length);

                                    // Simula o evento de tecla "@" pressionada
                                    const keyboardEvent = new KeyboardEvent('keydown', {
                                      key: '@',
                                      code: 'Digit2',
                                      keyCode: 50, // Código de tecla para "@"
                                      which: 50,
                                      shiftKey: true,
                                      bubbles: true,
                                      cancelable: true,
                                    });
                                    mentionsInputRef.current.dispatchEvent(keyboardEvent);

                                    const keyboardEventUp = new KeyboardEvent('keyup', {
                                      key: '@',
                                      code: 'Digit2',
                                      keyCode: 50, // Código de tecla para "@"
                                      which: 50,
                                      shiftKey: true,
                                      bubbles: true,
                                      cancelable: true,
                                    });
                                    mentionsInputRef.current.dispatchEvent(keyboardEventUp);
                                  }
                                }, 0);
                                return newMessage;
                              });
                            }}
                            _hover={{ bg: 'none' }}
                            display={openComment ? 'block' : 'none'}
                          />


                          <IconButton
                            aria-label="Anexar arquivo"
                            icon={<Attach />}
                            variant="ghost"
                            onMouseDown={() => clickedButtonRef.current = 'attach'}
                            onClick={handleAttachmentClick}
                            _hover={{ bg: 'none' }}
                            display={openComment ? 'block' : 'none'}
                            style={{
                              marginRight: '5px',
                            }}
                          />

                          <Button
                            bgColor="#4B4EFF"
                            color="white"
                            width="auto"
                            height={37}
                            onClick={sendMessage}
                            disabled={message.length === 0 || sending}
                            isLoading={sending}
                            alignItems={'center'}
                            _hover={ message.length ? { bg: '#282be0' } : {}}
                            style={{
                              height: '29px',
                              fontSize: '14px',
                              fontWeight: 500,
                              fontFamily: 'Poppins-medium',
                            }}
                            _disabled={{
                              backgroundColor: 'var(--chakra-colors-gray-300)',
                              cursor: 'default',
                            }}
                          >
                            Enviar
                          </Button>
                        </Flex>
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            </TabPanel>

            <TabPanel className="custom-tab-panel">
              <Flex
                flexDirection="column"
                paddingLeft={5}
                paddingRight={5}
                height="100%"
                overflow="auto"
                maxHeight="1085px"
              >
                {props.history?.map((x) => {
                  return (
                    <Flex
                      color="#718096"
                      fontSize="12px"
                      fontFamily="Poppins-medium"
                      justifyContent="space-between"
                      w="100%"
                      mt="15px"
                      key={x.createdAt.toString()}
                    >
                      <Flex justifyContent="start" alignItems="start">
                        {Circle()}
                        <Text ml="5px">{finalText(x)}</Text>
                      </Flex>
                      <Flex width="70px" justifyContent="flex-end" minW="fit-content">
                        <Text>{formatDate(x.createdAt.toString())}</Text>
                      </Flex>
                    </Flex>
                  );
                })}
              </Flex>
            </TabPanel>
          </Tabs>
        )
      }
    </>
  );
};
