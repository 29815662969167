import { ApiAuth, ApiCall } from "./api";
import { TPremiseResponse } from "./interface/IDefaults";
import {
  IClientCompanies,
  ICompanyBulkCreate,
  ICompanyCreatedResponse,
  ICompanyCreateInputs,
  ICompanyEditedInputs,
  ICompanyEditedResponse,
  ICompanyList,
  ICompanyExportForm,
  ICompanyUpdatedResponse,
  IFilterListCompany,
  IGetCompany,
  IGetEmployeesResponse,
} from "./interface/company.interface";
import queryString from 'query-string';
import { CancelToken } from "axios";


export const getEmployees = async (
  guid_client: string
): Promise<TPremiseResponse<IGetEmployeesResponse>> => {
  return await new ApiCall(
    `/company/employees/${guid_client}`,
    ApiAuth()
  ).get<IGetEmployeesResponse>();
};

export const getClientCompanies = async (
  guid_client: string
): Promise<TPremiseResponse<IClientCompanies[]>> => {
  return await new ApiCall(`/company/get-client-companies/${guid_client}`, ApiAuth()).get<IClientCompanies[]>();
};

export const listCompany = async (guid_client: string, filters: IFilterListCompany): Promise<TPremiseResponse<any>> => {
  const url = `/company/list/${guid_client}`;
  return await new ApiCall(url, ApiAuth()).post<any, any>(filters);
};

export const listCompanyWithoutPermission = async (guid_client: string, filters: IFilterListCompany): Promise<TPremiseResponse<any>> => {
  const queryStringParams = queryString.stringify(filters);
  const url = `/company/list/without-permission/${guid_client}?${queryStringParams}`;
  return await new ApiCall(url, ApiAuth()).get<any>();
};

export const getCompany = async (
  guid_client: string,
  guid_company: string
): Promise<TPremiseResponse<IGetCompany>> => {
  return await new ApiCall(
    `/company/info/${guid_client}/${guid_company}`,
    ApiAuth()
  ).get<IGetCompany>();
};

export const getCompanyWeeklyParticipantsEmails = async (
  guid_client: string
): Promise<TPremiseResponse<any>> => {
  return await new ApiCall(
    `/client/weekly-update-emails/${guid_client}`,
    ApiAuth()
  ).get<any>();
};

export const getCompanyPossibleEmails = async (
  guid_client: string
): Promise<TPremiseResponse<any>> => {
  return await new ApiCall(
    `/client/possible-emails/${guid_client}`,
    ApiAuth()
  ).get<any>();
};

export const postEditCompany = async (
  guid_client: string,
  guid_company: string,
  formdata: ICompanyEditedInputs
): Promise<TPremiseResponse<ICompanyEditedResponse>> => {
  return await new ApiCall(
    `/company/edit/${guid_client}/${guid_company}`,
    ApiAuth()
  ).put<ICompanyEditedResponse, ICompanyEditedInputs>(formdata);
};

export const postAddCompany = async (
  guid_client: string,
  formdata: ICompanyCreateInputs
): Promise<TPremiseResponse<ICompanyCreatedResponse>> => {
  return await new ApiCall(
    `/company/create/${guid_client}`,
    ApiAuth()
  ).post<ICompanyCreatedResponse, ICompanyCreateInputs>(formdata);
};

export const patchTerminateCompany = async (
  guid_company: string
): Promise<TPremiseResponse<ICompanyUpdatedResponse>> => {
  return await new ApiCall(
    `/company/terminate/${guid_company}`,
    ApiAuth()
  ).patch<ICompanyUpdatedResponse, unknown>();
};

export const toggleCompany = async (
  guid_company: string
): Promise<TPremiseResponse<any>> => {
  return await new ApiCall(
    `/company/toggle/${guid_company}`,
    ApiAuth()
  ).put<any, unknown>();
};

export const getCnpjInformation = async (
  cnpj: string,
): Promise<TPremiseResponse<any>> => {
  return await new ApiCall(
    `/company/get-cnpj-information/${cnpj}`,
    ApiAuth()
  ).get<any>();
};


export const postBulkCreateCompany = async (
  guid_client: string,
  formdata: ICompanyBulkCreate
): Promise<TPremiseResponse<any>> => {
  return await new ApiCall(
    `/company/bulk-create/${guid_client}`,
    ApiAuth()
  ).post<any, ICompanyBulkCreate>(formdata);
};

export const postReprocessBulkCreateCompany = async (
  guid_client: string,
  guid_bulk_update: string,
  formdata: ICompanyBulkCreate
): Promise<TPremiseResponse<any>> => {
  return await new ApiCall(
    `/company/bulk-create/reprocess/${guid_bulk_update}/${guid_client}`,
    ApiAuth()
  ).post<any, ICompanyBulkCreate>(formdata);
};

export const postFinishBulkCreateCompany = async (
  guid_client: string,
  guid_bulk_update: string,
  searchCertificate: '1'|'0',
  scheduleAppointment: '1'|'0'
): Promise<TPremiseResponse<any>> => {
  return await new ApiCall(
    `/bulk-item-company/${guid_client}/finish/${guid_bulk_update}/${searchCertificate}/${scheduleAppointment}`,
    ApiAuth()
  ).post<any, any>();
};

export const postCancelBulkCreateCompany = async (
  guid_client: string,
  guid_bulk_update: string
): Promise<TPremiseResponse<any>> => {
  return await new ApiCall(
    `/bulk-item-company/${guid_client}/cancel/${guid_bulk_update}`,
    ApiAuth()
  ).post<any, any>();
};

export const getBulkUpdateCompanyPending = async (guid_client: string): Promise<TPremiseResponse<any>> => {
  return await new ApiCall(`/company/bulk-update/pending/${guid_client}`, ApiAuth()).get<any>();
};

export const getBulkUpdateCompanyItens = async (guid_bulk_update: string, cancelToken?: CancelToken): Promise<TPremiseResponse<any>> => {
  return await new ApiCall(`/company/bulk-update/items/${guid_bulk_update}`, ApiAuth(), cancelToken).get<any>();
};

export const downloadListCompanies = async (guid_client: string, data: ICompanyExportForm): Promise<TPremiseResponse<string[]>> => {
  return await new ApiCall(
    `/company/export/${guid_client}`,
    ApiAuth()
  ).post<string[], ICompanyExportForm>(data);
};
