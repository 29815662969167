import { Flex, Image, Text, Divider, Select, Tooltip, useToast } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Company } from "../pages/dashboard/Companies/Company";
import { DashboardPanel } from "../pages/dashboard/Dashboard/dashboardPanel";
import { TaxKanban } from "../pages/dashboard/TaxKanban/TaxKanban";
import { checkClientPermission } from "../pages/dashboard/Users/utils/checkPermission";
import { LogoutModal } from "../pages/dashboard/Users/modais/LogoutModal";
import { UserModal } from "../pages/dashboard/Users/modais/UserModal";
import { UserPanel } from "../pages/dashboard/Users/UserPanel";
import { IResponse } from "../_services/interface/user.interface";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { ConfigPanel } from "../pages/dashboard/ConfigPanel/ConfigPanel";
import { ICertificate } from "../_services/interface/certificate.interface";
import { getCertificates, getClientCertificates } from "../_services/certificate.service";
import { CertificateManagerPanel } from "../pages/dashboard/CNDManager/CertificateManagerPanel";
import { IClientDataResponse } from "../_services/interface/client.interface";
import { PersonaPanel } from "../pages/dashboard/Persona/PersonaPanel";
import { Socket } from 'socket.io-client';
import { DefaultEventsMap } from "@socket.io/component-emitter";
import { ModalContinueEditing } from "../modal/ModalContinueEditing";

interface ISidebarProps {
  validation: IResponse;
  onUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  defaultComponent?: string;
  socket: Socket<DefaultEventsMap, DefaultEventsMap> | undefined;
}

interface ISelectClient {
  clientGuid: string;
}

interface IMenuItemProps {
  onClick: any;
  img: string;
  label: string;
  small: boolean;
  selected: boolean;
  depth?: boolean;
  title?: string;
  isOpen?: boolean;
  style?: object;
  ml?: string;
}

const MenuItem = (props: IMenuItemProps) => {
  const justifyContent = props.isOpen ? "flex-start" : "center";
  if (props.small) {
    if (props.selected) {
      return (
        <Tooltip label={props.label}>
          <Flex  style={props.style} justifyContent={justifyContent} gap="8px" p="10px 30px" alignItems="center" backgroundColor="rgb(255,255,255,0.1)" _hover={{ cursor: "pointer", backgroundColor: 'rgb(255,255,255,0.1)', transition: '300ms' }} onClick={props.onClick}>
            <Image w="22px" h="22px" src={props.img} />
          </Flex>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip label={props.label}>
          <Flex  style={props.style} justifyContent={justifyContent} gap="8px" p="10px 30px" alignItems="center" _hover={{ cursor: "pointer", backgroundColor: 'rgb(255,255,255,0.1)', transition: '300ms' }} onClick={props.onClick}>
            <Image w="22px" h="22px" src={props.img} />
          </Flex>
        </Tooltip>
      );
    }
  } else {
    if (props.selected) {
      return (
        <Flex  style={props.style} justifyContent={justifyContent} gap='8px' marginLeft={props.depth ? props.ml ? props.ml : '50px' : '0px'} p="10px 30px" alignItems="center" backgroundColor="rgb(255,255,255,0.1)" _hover={{ cursor: "pointer", backgroundColor: 'rgb(255,255,255,0.1)', transition: '300ms' }} onClick={props.onClick}>
          {!props.depth ?
            <Image w="22px" h="22px" src={props.img} />
            : null
          }
          <Text fontSize="12px">{props.label}</Text>
        </Flex>
      );
    } else {
      return (
        <Flex  style={props.style} justifyContent={justifyContent} gap='8px' marginLeft={props.depth ? props.ml ? props.ml : '50px' : '0px'} p="10px 30px" alignItems="center" _hover={{ cursor: "pointer", backgroundColor: 'rgb(255,255,255,0.1)', transition: '300ms' }} onClick={props.onClick}>
          {!props.depth ?
            <Image w="22px" h="22px" src={props.img} />
            : null
          }
          <Text fontSize="12px" title={props.title ? props.title : undefined}>{props.label}</Text>
        </Flex>
      );
    }
  }
}

export default function Sidebar(props: ISidebarProps) {
  const toast = useToast();

  const [location, setLocation] = useState<string>(props.defaultComponent ? props.defaultComponent : "dashboardPanel");
  const [isOpen, setIsOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isExpandedFederal, setIsExpandedFederal] = useState(false);
  const [isExpandedState, setIsExpandedState] = useState(false);
  const [isExpandedMunicipal, setIsExpandedMunicipal] = useState(false);
  const [isExpandedC, setIsExpandedC] = useState(false);
  const [hasCpfProduct, setHasCpfProduct] = useState(false);
  const [currentClient, setCurrentClient] = useState<IClientDataResponse>();
  const [certificates, setCertificates] = useState<ICertificate[]>([]);
  const [certificatesUse, setCertificatesUse] = useState<ICertificate[]>([]);
  const [certificate, setCertificate] = useState<ICertificate | undefined>();
  const [certificateJurisdiction, setCertificateJurisdiction] = useState<'federal'|'state'|'municipal'|null>(null);
  const [cndType, setCndType] = useState<string>("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [confType, setconfType] = useState<string>("");
  const [hasCertificate, setHasCertificate] = useState<boolean>(false);
  const { register, watch, setValue } = useForm<ISelectClient>();
  const [collaborator, setCollaborator] = useState<IClientDataResponse | undefined>();
  const [hasChangeConfigPage, setHasChangeConfigPage] = useState<boolean>(false);
  const [redirectToLocation, setRedirectToLocation] = useState<string>('');
  const [openModalContinueEditing, setOpenModalContinueEditing] = useState<boolean>(false);

  const locationHandler: React.Dispatch<React.SetStateAction<string>> = (props) => {
    setLocation(props);
    setHasChangeConfigPage(false);
  }

  //Essa estrutura existe para checar se um usuário de tempos em tempos, esta logado
  useEffect(() => {
    //As default a company need to be set, and need to be the company that the user is owner
    const isOwner = props.validation.clients.find(_x => _x.permissions.includes('org.owner'));
    if (isOwner) {
      setCurrentClient(isOwner);
      setValue("clientGuid", isOwner.guid_client);
    } else {
      //If not the company that this user is administrator
      const isAdmin = props.validation.clients.find(_x => _x.permissions.includes('org.admin'));
      if (isAdmin) {
        setCurrentClient(isAdmin);
        setValue("clientGuid", isAdmin.guid_client);
      } else {
        //If nothing works, the first company of the list
        setCurrentClient(props.validation.clients[0]);
        setValue("clientGuid", props.validation.clients[0].guid_client);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.validation.companies.length > 0) {
      setCollaborator(props.validation.clients.find(_x => _x.guid_client === currentClient?.guid_client));
    }
  }, [currentClient, props.validation]);

  const getListCertificates = async (guid_client: string) => {
    const { status: status1, response: response1 } = await getCertificates();
    if (status1 === 200) {
      setCertificates(response1 as ICertificate[]);
    }

    const { status: status2, response: response2 } = await getClientCertificates(guid_client);
    if (status2 === 200) {
      setCertificatesUse(response2 as ICertificate[]);
    }
  };

  useEffect(() => {
    if (currentClient) {
      getListCertificates(currentClient.guid_client);
    }
    // eslint-disable-next-line
  }, [currentClient]) 

  useEffect(() => {
    if(currentClient && certificates) {
      const productsCpf = currentClient.products_cpf ? currentClient.products_cpf : {};
      setHasCpfProduct(false);
      certificates.map((certificate) => {
        if(
          productsCpf?.hasOwnProperty(certificate.slug as string)
          && productsCpf[certificate?.slug as string] === true
        ) {
          setHasCpfProduct(true);
        }
      });
    }
  }, [currentClient, certificates, watch('clientGuid')]);

  useEffect(() => {
    if (props.defaultComponent) {
      setLocation(props?.defaultComponent)
    } else {
      setLocation('dashboardPanel')
    }
  }, [props.defaultComponent])

  const renderStep = () => {
    if (location === "dashboardPanel") {
      return (
        <DashboardPanel
          guid_client={currentClient?.guid_client as string}
          clientInfo={currentClient as IClientDataResponse}
          certificates={certificates}
        />
      );
    } else if (location === "userPanel") {
      return (
        <UserPanel 
          guid_client={currentClient?.guid_client as string}
          validation={props.validation} />
      );
    } else if (location === "company") {
      return <Company 
          guid_client={currentClient?.guid_client as string}
          socket={props.socket}
        />
    } else if (location === "CertificateManagerPanel") {
      if(certificate !== undefined) {
        toast.closeAll();

        return (
          <CertificateManagerPanel
            guid_client={currentClient?.guid_client as string}
            socket={props.socket}
            certificate={certificate as ICertificate}
            certificates={certificatesUse as ICertificate[]}
            validation={props.validation}
            jurisdiction={certificateJurisdiction}
            location={location}
          />
        )
      } else {
        toast.closeAll();

        return (
          <CertificateManagerPanel
            guid_client={currentClient?.guid_client as string}
            socket={props.socket}
            certificates={certificatesUse as ICertificate[]}
            allCertificates={true}
            validation={props.validation}
            jurisdiction={certificateJurisdiction}
            location={location}
          />
        )
      }
    } else if (location === "taxKanban") {
      return (
        <TaxKanban
          guid_client={currentClient?.guid_client as string}
          validation={props.validation}
        />
      )
    } else if (location === "personaPanel") {
      return (
        <PersonaPanel
          guid_client={currentClient?.guid_client as string}
          onUpdate={props.onUpdate}
        />
      )
    } else if (location === "configPanel") {
      return (
        <ConfigPanel
          guid_client={currentClient?.guid_client as string}
          certificates={certificates}
          hasChangeHook={setHasChangeConfigPage}
        />
      )
    }
    return (
      <DashboardPanel
        guid_client={currentClient?.guid_client as string}
        clientInfo={currentClient as IClientDataResponse}
        certificates={certificates}
      />
    );
  }
  useEffect(() => {
    watch((value) => {
      if (value.clientGuid) {
        localStorage.clear();
        setCurrentClient(props.validation.clients.find((client) => client.guid_client === value.clientGuid));
      }
    });
  }, [props.validation.clients, watch]);

  useEffect(() => {
    setHasCertificate(false);
    certificates.forEach((certificate, _key) => {
      if (currentClient?.products[certificate.slug as keyof typeof currentClient.products]) {
        setHasCertificate(true);
      }
    });
    // eslint-disable-next-line
  }, [certificates]);

  useEffect(() => {
    toast.closeAll();
  }, [location]);

  const handlePages = (nav: string) => {
    if(hasChangeConfigPage) {
      setRedirectToLocation(nav);
      setOpenModalContinueEditing(true);
      return;
    }
    locationHandler(nav);
  };

  const certificatesMenu = () => {
    if (certificates.length > 0 && checkClientPermission(props.validation, (currentClient?.guid_client as string), "cnd.read")) {
      const menuItems: JSX.Element[] = [];

      certificates.forEach((certificate, _key) => {
        if (
            (
                currentClient?.products[certificate.slug as keyof typeof currentClient.products]
                || (currentClient?.products_cpf && currentClient?.products_cpf[certificate.slug as keyof typeof currentClient.products])
            )
          && (
              (collaborator?.userProducts && collaborator?.userProducts.includes(certificate.slug as string))
              || (collaborator?.permissions.includes('org.admin') || collaborator?.permissions.includes('org.owner'))
          )
        ) {
          menuItems.push(
            <MenuItem
                style={{w: "100%"}}
                isOpen={isOpen}
                selected={location === "CertificateManagerPanel" && cndType === certificate.slug}
                small={false} title={certificate.name as string}
                label={certificate.name as string} img="/icons/flow.svg"
                onClick={() => { handlePages('CertificateManagerPanel'); setCndType(certificate.slug as string); setCertificate(certificate) }}
                depth={true}
            />
          );
        }
      });

      if ((collaborator?.userProducts === undefined || collaborator?.userProducts?.length === 0 || collaborator?.userProducts === null)
          && (!collaborator?.permissions.includes('org.admin') && !collaborator?.permissions.includes('org.owner')))
      {
        return <></>
      }

      return <>
        <Flex gap="8px" p="10px 30px" alignItems="center" _hover={{ cursor: "pointer", backgroundColor: 'rgb(255,255,255,0.1)', transition: '300ms' }}
          background={location === "CNDMagerPanel" ? 'rgb(255,255,255,0.1)' : 'rgb(255,255,255,0)'}
          onClick={() => setIsExpanded(!isExpanded)}>
          <Image w="22px" h="22px" src="/icons/gerenciador_cnds_white.png" />
          <Text fontSize="12px">
            <>
              Gerenciador de Certidões
              <ChevronDownIcon className={isExpanded ? "rotate" : "rotate-reverse"} w={4} h={4} />
            </>
          </Text>
        </Flex>


        {isExpanded ? <Flex flexDirection="column">{menuItems}</Flex> : null}
      </>;
    }
  };

  const certificateHasJurisdiction = (certificate: ICertificate, jurisdiction: 'federal'|'state'|'municipal'): boolean => {
    if (certificate.jurisdiction === jurisdiction) {
      return true;
    }

    if (certificate.children) {
      for (const child of certificate.children) {
        if (certificateHasJurisdiction(child, jurisdiction)) {
          return true;
        }
      }
    }

    return false;
  }

  const certificatesByJurisdictionMenu = () => {
    if (certificates.length > 0 && checkClientPermission(props.validation, (currentClient?.guid_client as string), "cnd.read")) {
      const menuItemsFederal: JSX.Element[] = [];
      const menuItemsState: JSX.Element[] = [];
      const menuItemsMunicipal: JSX.Element[] = [];
      let menuItemOutros: JSX.Element = <></>;

      certificates.forEach((certificate, _key) => {
        if (
          (
            currentClient?.products[certificate.slug as keyof typeof currentClient.products]
            || (currentClient?.products_cpf && currentClient?.products_cpf[certificate.slug as keyof typeof currentClient.products])
          )
          && (
            (collaborator?.userProducts && collaborator?.userProducts.includes(certificate.slug as string))
            || (collaborator?.permissions.includes('org.admin') || collaborator?.permissions.includes('org.owner'))
          )
        ) {

          if(certificate.slug === 'outros') {
            menuItemOutros = <MenuItem 
              style={{w: "100%"}} 
              isOpen={isOpen} 
              selected={location === "CertificateManagerPanel" && cndType === certificate.slug} 
              small={false} 
              title="Outros" 
              label="Outros" 
              img="/icons/gerenciador_cnds_white.png" 
              onClick={() => {handlePages('CertificateManagerPanel'); setCndType(certificate.slug as string); setCertificate(certificate); setCertificateJurisdiction(null);}}
              depth={true}
              ml={currentClient?.certificate_menu === 'jurisdiction' ? '25px' : '50px'}
            />;
          }

          if(certificateHasJurisdiction(certificate, 'federal') && certificate.slug !== 'outros') {
            menuItemsFederal.push(<MenuItem
              style={{w: "100%"}}
              isOpen={isOpen}
              selected={location === "CertificateManagerPanel" && cndType === certificate.slug && certificateJurisdiction === 'federal'}
              small={false} title={certificate.name as string}
              label={certificate.name as string} img="/icons/flow.svg"
              onClick={() => { handlePages('CertificateManagerPanel'); setCndType(certificate.slug as string); setCertificate(certificate); setCertificateJurisdiction('federal'); }}
              depth={true}
            />);
          }

          if(certificateHasJurisdiction(certificate, 'state') && certificate.slug !== 'outros') {
            menuItemsState.push(<MenuItem
              style={{w: "100%"}}
              isOpen={isOpen}
              selected={location === "CertificateManagerPanel" && cndType === certificate.slug && certificateJurisdiction === 'state'}
              small={false} title={certificate.name as string}
              label={certificate.name as string} img="/icons/flow.svg"
              onClick={() => { handlePages('CertificateManagerPanel'); setCndType(certificate.slug as string); setCertificate(certificate); setCertificateJurisdiction('state'); }}
              depth={true}
            />);
          }

          if(certificateHasJurisdiction(certificate, 'municipal') && certificate.slug !== 'outros') {
            menuItemsMunicipal.push(<MenuItem
              style={{w: "100%"}}
              isOpen={isOpen}
              selected={location === "CertificateManagerPanel" && cndType === certificate.slug && certificateJurisdiction === 'municipal'}
              small={false} title={certificate.name as string}
              label={certificate.name as string} img="/icons/flow.svg"
              onClick={() => { handlePages('CertificateManagerPanel'); setCndType(certificate.slug as string); setCertificate(certificate); setCertificateJurisdiction('municipal'); }}
              depth={true}
            />);
          }
        }
      });

      if ((collaborator?.userProducts === undefined || collaborator?.userProducts?.length === 0 || collaborator?.userProducts === null)
          && (!collaborator?.permissions.includes('org.admin') && !collaborator?.permissions.includes('org.owner')))
      {
        return <></>
      }

      return <>
        <Flex gap="8px" p="10px 30px" alignItems="center" _hover={{ cursor: "pointer", backgroundColor: 'rgb(255,255,255,0.1)', transition: '300ms' }}
          background={location === "CertificateManagerPanel" ? 'rgb(255,255,255,0.1)' : 'rgb(255,255,255,0)'}
          onClick={() => setIsExpanded(!isExpanded)}>
          <Image w="22px" h="22px" src="/icons/gerenciador_cnds_white.png" />
          <Text fontSize="12px">
            <>
              Gerenciador de Certidões
              <ChevronDownIcon className={isExpanded ? "rotate" : "rotate-reverse"} w={4} h={4} />
            </>
          </Text>
        </Flex>


        { isExpanded ? (<>
          {
            menuItemsFederal.length > 0 ? (<>
              <Flex
                gap="8px"
                p="10px 30px"
                ml="25px"
                alignItems="center"
                _hover={{ cursor: "pointer", backgroundColor: 'rgb(255,255,255,0.1)', transition: '300ms' }}
                cursor={cndType === '' && certificateJurisdiction === 'federal' ? 'pointer' : 'default'}
                background={cndType === '' && certificateJurisdiction === 'federal' && location === "CertificateManagerPanel" ? 'rgb(255,255,255,0.1)' : 'rgb(255,255,255,0)'}
                onClick={() => { setIsExpandedFederal(!isExpandedFederal); handlePages('CertificateManagerPanel'); setCndType(''); setCertificate(certificate); setCertificateJurisdiction('federal'); setCertificate(undefined); }}
              >
                <Text fontSize="12px">
                  <>
                    Federal
                    <ChevronDownIcon className={isExpandedFederal ? "rotate" : "rotate-reverse"} w={4} h={4} />
                  </>
                </Text>
              </Flex>
              
              {isExpandedFederal && <Flex flexDirection="column">{menuItemsFederal}</Flex>}
            </>):null
          }
          {
            menuItemsState.length > 0 ? (<>
              <Flex 
                gap="8px"
                p="10px 30px"
                ml="25px"
                alignItems="center"
                _hover={{ cursor: "pointer", backgroundColor: 'rgb(255,255,255,0.1)', transition: '300ms' }}
                cursor={cndType === '' && certificateJurisdiction === 'state' ? 'pointer' : 'default'}
                background={cndType === '' && certificateJurisdiction === 'state' && location === "CertificateManagerPanel" ? 'rgb(255,255,255,0.1)' : 'rgb(255,255,255,0)'}
                onClick={() => { setIsExpandedState(!isExpandedState); handlePages('CertificateManagerPanel'); setCndType(''); setCertificate(certificate); setCertificateJurisdiction('state'); setCertificate(undefined); }}
              >
                <Text fontSize="12px">
                  <>
                    Estadual
                    <ChevronDownIcon className={isExpandedState ? "rotate" : "rotate-reverse"} w={4} h={4} />
                  </>
                </Text>
              </Flex>
              {isExpandedState && <Flex flexDirection="column">{menuItemsState}</Flex>}
            </>):null
          }
          {
            menuItemsMunicipal.length > 0 ? (<>
              <Flex 
                gap="8px"
                p="10px 30px"
                ml="25px"
                alignItems="center"
                _hover={{ cursor: "pointer", backgroundColor: 'rgb(255,255,255,0.1)', transition: '300ms' }}
                cursor={cndType === '' && certificateJurisdiction === 'municipal' ? 'pointer' : 'default'}
                background={cndType === '' && certificateJurisdiction === 'municipal' && location === "CertificateManagerPanel" ? 'rgb(255,255,255,0.1)' : 'rgb(255,255,255,0)'}
                onClick={() => { setIsExpandedMunicipal(!isExpandedMunicipal); handlePages('CertificateManagerPanel'); setCndType(''); setCertificate(certificate); setCertificateJurisdiction('municipal'); setCertificate(undefined); }}
              >
                <Text fontSize="12px">
                  <>
                    Municipal
                    <ChevronDownIcon className={isExpandedMunicipal ? "rotate" : "rotate-reverse"} w={4} h={4} />
                  </>
                </Text>
              </Flex>
              {isExpandedMunicipal && <Flex flexDirection="column">{menuItemsMunicipal}</Flex>}
            </>):null
          }

            {menuItemOutros}
         </>): null}
      </>;
    }
  };

  return (
    <>
      <Flex w="100%">
        <Flex className="sidebar" w={isOpen ? "250px" : "100px"} position="relative" fontFamily="Comfortaa-Medium">
          {isOpen ? (
            <Flex  id="teste" w="250px" bg="#4B4EFF" position="relative" color="white" justifyContent="center" transition="300ms"  zIndex={99} left="0px" right="0px" top="0px" bottom="0px" >
              <Flex direction="column" >
                <Flex justifyContent="end" transition="600ms" marginTop="20px"  mt={2} onClick={() => setIsOpen(!isOpen)} _hover={{ cursor: "pointer" }}>
                  <Flex h={35} w={35}  borderRadius="100%" justifyContent="center" alignItems="center">
                    <Image src="/icons/arrow-close.svg" h={13} w={13} />
                  </Flex>
                </Flex>
                <Flex direction="column" gap={8}>
                  <Flex justifyContent="center">
                    <Text className="logo" color="white" fontSize="32px" cursor="pointer" onClick={() => handlePages('dashboardPanel')}>taxly</Text>
                  </Flex>
                  <Flex justifyContent="center">
                    <Select
                        variant="outline"
                        borderRadius="0"
                        borderBottom="1px solid white"
                        borderTop="none"
                        borderLeft="none"
                        borderRight="none"
                        w="200px"
                        fontSize="12px"
                        {...register("clientGuid")}
                    >
                      {props.validation.clients.map((_client, _key) => (
                          <option
                              key={_key}
                              style={{ backgroundColor: "#0263FF" }}
                              value={_client.guid_client}
                              disabled={_client.guid_client === currentClient?.guid_client}
                          >
                            {_client.name}
                          </option>
                      ))}
                    </Select>
                  </Flex>
                </Flex>
                <Flex direction="column" mt={8} className="scrollbarsidebar"  overflowX="auto"  flexGrow={1} width="100%"  gap={2} h={400}  paddingRight="2px" >
                  <MenuItem style={{w: "100%"}} isOpen={isOpen} selected={location === "dashboardPanel"} small={false} title="Dashboard" label="Dashboard" img="/icons/dashboard.svg" onClick={() => handlePages('dashboardPanel')} />
                  {currentClient?.products?.kanban ?
                    checkClientPermission(props.validation, currentClient.guid_client, "kanban.read") ? (
                      <MenuItem style={{w: "100%"}} isOpen={isOpen} selected={location === "taxKanban"} small={false} title="Tax Kanban" label="Tax Kanban" img="/icons/Tax_kanban_white.png" onClick={() => handlePages('taxKanban')} />
                    ) : null
                    : null
                  }
                        
                        {/* Menu de certificados */}
                        {hasCertificate && currentClient?.certificate_menu !== 'jurisdiction' ? certificatesMenu() : null}
                        {hasCertificate && currentClient?.certificate_menu === 'jurisdiction' ? certificatesByJurisdictionMenu() : null}

                        {isExpanded ? (
                          <MenuItem 
                            style={{w: "100%"}} 
                            isOpen={isOpen} 
                            selected={location === "CertificateManagerPanel" && cndType === '' && certificateJurisdiction === null} 
                            small={false} 
                            title="Visão Geral" 
                            label="Visão Geral" 
                            img="/icons/gerenciador_cnds_white.png" 
                            onClick={() => {handlePages('CertificateManagerPanel'); setCertificate(undefined); setCndType(''); setCertificateJurisdiction(null)}}
                            depth={true}
                            ml={currentClient?.certificate_menu === 'jurisdiction' ? '25px' : '50px'}
                          />
                        ) : null}

                        {
                          checkClientPermission(props.validation, (currentClient?.guid_client as string), "company.read")
                          || checkClientPermission(props.validation, (currentClient?.guid_client as string), "user.read")
                          || checkClientPermission(props.validation, (currentClient?.guid_client as string), "persona.read")
                          || checkClientPermission(props.validation, (currentClient?.guid_client as string), "org.admin")
                          
                          ? (
                            <Flex gap="8px" p="10px 30px" alignItems="center" _hover={{ cursor: "pointer", backgroundColor: 'rgb(255,255,255,0.1)', transition: '300ms' }}
                              background={["userPanel", "configPanel", "company", "personaPanel"].includes(location) ? 'rgb(255,255,255,0.1)' : 'rgb(255,255,255,0)'}
                              onClick={() => setIsExpandedC(!isExpandedC)}>
                              <Image w="22px" h="22px" src="/icons/engrenagem.svg" />
                              <Text fontSize="12px">
                                <> 
                                  Configurações
                                  <ChevronDownIcon className={isExpandedC ? "rotate" : "rotate-reverse"} w={4} h={4} />
                                </>
                              </Text>
                            </Flex>
                          ): null}
                          {isExpandedC &&
                            <>
                              {checkClientPermission(props.validation, (currentClient?.guid_client as string), "user.read") ? (
                                <MenuItem style={{w: "100%"}} isOpen={isOpen} selected={location === "userPanel"} small={false} title="Usuários" label="Usuários" img="/icons/users.svg" onClick={() => { handlePages('userPanel'); setconfType('userPanel') }} depth={true}/>
                              ) : null}
                              {checkClientPermission(props.validation, (currentClient?.guid_client as string), "org.admin") ? (
                                <MenuItem style={{w: "100%"}} isOpen={isOpen} selected={location === "configPanel"} small={false} title="Produtos" label="Produtos" img="/icons/engrenagem.svg" onClick={() => { handlePages('configPanel'); setconfType('configPanel') }} depth={true} />
                              ) : null}
                              {checkClientPermission(props.validation, (currentClient?.guid_client as string), "company.read") ? (
                                  <MenuItem style={{w: "100%"}} isOpen={isOpen} selected={location === "company"} small={false} title="Empresas" label="Empresas" img="/icons/building.svg" onClick={() => { handlePages('company'); setconfType('company') }} depth={true} />
                              ) : null}
                              {checkClientPermission(props.validation, (currentClient?.guid_client as string), "persona.read") && hasCpfProduct ? (
                                <MenuItem style={{w: "100%"}} isOpen={isOpen} selected={location === "personaPanel"} small={false} title="Pessoa Física" label="Pessoa Física" img="/icons/engrenagem.svg" onClick={() => { handlePages('personaPanel'); setconfType('personaPanel') }} depth={true} />
                              ) : null}
                            </>
                          }
                          
                  <Flex direction="column" gap={4} alignSelf="center" mt={4}>
                    <Divider w="200px" />
                    <UserModal st="complete" username={props.validation.name} guid_user={props.validation.guid_user} onUpdate={props.onUpdate} />
                    <LogoutModal st="complete" />
                </Flex>
                  <br/><br/>
                </Flex>

              </Flex>
            </Flex>) : (
            <Flex w="100px" bg="#4B4EFF" color="white" position="relative"  zIndex={99} left="0px" right="0px" top="0px" bottom="0px" >
              <Flex direction="column" w="100%">
                <Flex justifyContent="end" mt="10px"   transition="300ms" onClick={() => setIsOpen(!isOpen)} _hover={{ cursor: "pointer" }}>
                  <Flex h={35} w={32}  borderRadius="100%" justifyContent="center" alignItems="center">
                    <Image src="/icons/bars-solid.svg" h={13} w={15} />
                  </Flex>
                </Flex>
                <Flex direction="column" gap="22px" w="100%">
                  <Flex justifyContent="center">
                    <Text color="white" fontSize="32px" >t</Text>
                  </Flex>
                </Flex>
                <Flex direction="column" w="100%" mt={8}>
                  <MenuItem isOpen={isOpen} selected={location === "dashboardPanel"} small={true} title="Dashboard" label="Dashboard" img="/icons/dashboard.svg" onClick={() => handlePages('dashboardPanel')} />
                  {checkClientPermission(props.validation, (currentClient?.guid_client as string), "kanban.read") ? (
                    currentClient?.products.kanban ?
                      <MenuItem isOpen={isOpen} selected={location === "taxKanban"} small={true} title="Tax Kanban" label="Tax Kanban" img="/icons/Tax_kanban_white.png" onClick={() => handlePages('taxKanban')} />
                      : null
                  ) : null}
                  {/* Menu de certificados */}
                  {checkClientPermission(props.validation, (currentClient?.guid_client as string), 'cnd.read') ? (
                    <>
                      {hasCertificate ? <Tooltip label='Gerenciador de Certidões'>
                        <Flex justifyContent="center" gap="8px" p="10px 30px" alignItems="center" _hover={{ cursor: "pointer", backgroundColor: 'rgb(255,255,255,0.1)', transition: '300ms' }} onClick={() => { setIsOpen(true); setIsExpanded(true) }}>
                          <Image w="22px" h="22px" src="/icons/gerenciador_cnds_white.png" />
                        </Flex>
                      </Tooltip>
                        : null}
                    </>
                  ) : null}
                  
                    {checkClientPermission(props.validation, (currentClient?.guid_client as string), "company.read")
                      || checkClientPermission(props.validation, (currentClient?.guid_client as string), "user.read")
                      || checkClientPermission(props.validation, (currentClient?.guid_client as string), "persona.read")
                      || checkClientPermission(props.validation, (currentClient?.guid_client as string), "org.admin") ? (
                    <Tooltip label='Configurações'>
                        <Flex justifyContent="center" gap="8px" p="10px 30px" alignItems="center" _hover={{ cursor: "pointer", backgroundColor: 'rgb(255,255,255,0.1)', transition: '300ms' }} onClick={() => { setIsOpen(true); setIsExpandedC(true) }}>
                          <Image w="22px" h="22px" src="/icons/engrenagem.svg" />
                        </Flex>
                      </Tooltip>
                    ) : null}

                </Flex>
                <Flex direction="column" gap={4} w="100%" mt={8}>
                  <Divider w="100%" />
                  <UserModal st="simple" username={props.validation.name} guid_user={props.validation.guid_user} onUpdate={props.onUpdate} />
                  <LogoutModal st="simple" />
                </Flex>
              </Flex>
            </Flex>)}
        </Flex>

        {/* Pagina */}
        {
          isOpen ? (
          <Flex   style={{ backgroundColor: '#f8f7f7', borderTopLeftRadius:'20px',paddingLeft:'23px', height:'100%', overflow:'auto' }} className="mainPage" flexGrow="1" flexShrink="1" transition="300ms">
            {renderStep()}
          </Flex>) : (
          <Flex  style={{ backgroundColor: '#f8f7f7', borderTopLeftRadius:'20px',paddingLeft:'23px', height:'100%', overflow:'auto'}} className="mainPage" flexGrow="1" flexShrink="1" transition="300ms">
            {renderStep()}
          </Flex>)
        }
      </Flex>

      <ModalContinueEditing
        callback={locationHandler}
        open={openModalContinueEditing}
        openHook={setOpenModalContinueEditing}
        location={redirectToLocation}
      />
    </>
  );
}