import { Flex, Input, InputProps } from "@chakra-ui/react";
import InputMask from "react-input-mask";
import { forwardRef } from "react";

interface CustomStyleProps {
  [key: string]: any;
}
interface AppInputProps {
  errors?: { message?: string };
  label?: string;
  mask?: string;
  isRequired?: boolean;
  disabled?: boolean;
  labelCustomStyle?: CustomStyleProps;
}
  
export const TaxlyInput = forwardRef(({ 
  errors, 
  label,
  mask,
  isRequired = false,
  fontSize = '12px',
  fontFamily = 'Poppins-medium',
  borderRadius = '4px',
  border = '1px solid #CBD5E0',
  bgColor = 'transparent',
  color = '#3a3a3a',
  bg="#FFFFFF",
  type="text",
  disabled = false,
  labelCustomStyle,
  ...otherProps 
}: AppInputProps & InputProps, ref) => {
    return (
      <Flex flexDirection="column" width="100%">
        { 
          label ?
            <Flex
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              fontSize="12px"
              fontFamily='Poppins-Medium'
              {...labelCustomStyle}
            >
              <label htmlFor={label}>{label}</label>

              {isRequired
                ? <span style={{ marginLeft: '3px', color: 'red' }}>*</span> : null}
            </Flex>
          : null
        }

        <Input
          id={label}
          ref={ref as React.Ref<HTMLInputElement>}
          fontSize={fontSize}
          bg={bg}
          fontFamily={fontFamily}
          borderRadius={borderRadius}
          border={border}
          bgColor={bgColor}
          type={type}
          color={color}
          as={mask ? InputMask : undefined}
          mask={mask}
          disabled={disabled}
          _disabled={{ bg: '#E2E8F0', color: '#CBD5E0'}}
          _placeholder={{ opacity: 0.7 }}
          {...otherProps}
        />
      </Flex>
    );
});