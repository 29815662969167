import { ApiAuth, ApiCall } from './api';
import { TPremiseResponse } from './interface/IDefaults';
import { IFormData, ILoginResponse, ITwoFactorResponse, IFormRequestInput, IRequestResponse, IFormValidateOtpInput, IResetPasswordInput, IResetPasswordResponse, IWaitingValidation, IFormSSOInput, IChangePasswordResponse, IChangePasswordInput } from './interface/login.interface';

export const postLogin = async ({ email, password }: IFormData): Promise<TPremiseResponse<ILoginResponse | ITwoFactorResponse | IWaitingValidation>> => {
  return await new ApiCall('/auth/login').post<ILoginResponse | ITwoFactorResponse, IFormData>({ email, password });
}

export const postLoginSSO = async ({ email, name, clientSideToken }: IFormSSOInput): Promise<TPremiseResponse<ILoginResponse>> => {
  return await new ApiCall('/auth/login/sso').post<ILoginResponse, IFormSSOInput>({ email, name, clientSideToken });
}

export const postRecoveryPass = async ({ email }: IFormRequestInput): Promise<TPremiseResponse<IRequestResponse>> => {
  return await new ApiCall('/auth/recover').post<IRequestResponse, IFormRequestInput>({ email });
}

export const postCheckOtp = async (data: IFormValidateOtpInput): Promise<TPremiseResponse<boolean>> => {
  return await new ApiCall('/auth/otp').post<boolean, IFormValidateOtpInput>(data);
}

export const postResetPassword = async (data: IResetPasswordInput): Promise<TPremiseResponse<IResetPasswordResponse>> => {
  return await new ApiCall('/auth/reset-password').post<IResetPasswordResponse, IResetPasswordInput>(data);
}

export const putChangePassword = async (data: IChangePasswordInput): Promise<TPremiseResponse<IChangePasswordResponse>> => {
  return await new ApiCall('/auth/change-password', ApiAuth()).put<IChangePasswordResponse, IChangePasswordInput>(data);
}

export const getIsSSO = async (): Promise<TPremiseResponse<boolean>> => {
  return await new ApiCall('/auth/is-sso').get<boolean>()
}