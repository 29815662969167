import { Flex, Text, useToast, Button, Checkbox, Select, Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Tooltip, Image, RadioGroup, Radio } from "@chakra-ui/react"
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { getClientCompanies } from "../../../_services/company.service";
import { IClientCompanies } from "../../../_services/interface/company.interface";
import { getCompanyWeeklyParticipantsEmails, getCompanyPossibleEmails } from "../../../_services/company.service";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import 'react-multi-email/dist/style.css';
import { ICertificate } from "../../../_services/interface/certificate.interface";
import { SelectCompanies } from "../../../modal/SelectCompanies";
import { editConfig, getClient } from "../../../_services/client.service";
import { IClientResponse } from "../../../_services/interface/client.interface";
import SelectMultiEmail from "../../../components/SelectMultiEmail";
import useLoginFormStore from "../../../store/useLoginFormStore";
import { InfoIcon } from "@chakra-ui/icons";
import { IPersona } from "../../../_services/interface/persona.interface";
import { listWithoutPermissionPersonas } from "../../../_services/persona.service";
import { SelectPersonas } from "../../../modal/SelectPersonas";
import { TaxlyInput } from "../../../components/Input";

interface IConfigPanelProps {
    guid_client: string;
    certificates: ICertificate[];
    hasChangeHook: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IConfigPanelForm {
    isScheduled: boolean,
    kanban: boolean,
    weeklyUpdate: boolean,
    weeklyParticipants?: string
    field1?: string,
    field2?: string,
    field3?: string,
}

interface IOptionRecurrency {
    value: string;
    label: string;
}

interface ICertificateCheckbox {
    name: string;
    slug: string;
    selected: boolean;
    recurrency: string;
    optionsRecurrency: IOptionRecurrency[];
}

export const ConfigPanel = (props: IConfigPanelProps) => {
    const YupCompanyChangeForm = Yup.object().shape({
        recurrencyPeriod: Yup.string(),
        weeklyUpdate: Yup.bool(),
        kanban: Yup.bool(),
        field1: Yup.string(),
        field2: Yup.string(),
        field3: Yup.string(),
        ...props.certificates.reduce(
            (acc, certificate) => ({
              ...acc,
              [certificate.slug as string]: Yup.bool(),
            }),
            {}
          ),
        /* weeklyParticipants: Yup.string() */
    });

    const [openDetails, setOpenDetails] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [allCompanies, setAllCompanies] = useState<IClientCompanies[]>([]);
    const [personas, setPersonas] = useState<IPersona[]>([]);
    const [personasScheduled, setPersonasScheduled] = useState<string[]>([]);
    const [openSelectCompanies, setOpenSelectCompanies] = useState<boolean>(false);
    const [openSelectPersonas, setOpenSelectPersonas] = useState<boolean>(false);
    const [isScheduled, setIsScheduled] = useState<boolean>(false);
    const [isScheduledPersona, setIsScheduledPersona] = useState<boolean>(false);
    const [allCompaniesScheduled, setAllCompaniesScheduled] = useState<string[]>([]);
    const [showParticipants, setShowParticipants] = useState<boolean>(false);
    const [client, setClient] = useState<IClientResponse>();
    const [emails, setEmails] = useState<string[]>([]);
    const [isDisabled, setIsDisabled] = useState<boolean>(false);
    const [kanban, setKanban] = useState<boolean>(false);
    const [situation, setSituation] = useState<boolean>(false);
    const [situationRecurrency, setSituationRecurrency] = useState<string|undefined>();
    const [productsHasInitialized, setProductsHasInitialized] = useState<boolean>(false);
    const [products, setProducts] = useState<any>();
    const [productsCpf, setProductsCpf] = useState<any>();
    const [productsSettings, setProductsSettings] = useState<any>();
    const [productsSettingsCpf, setProductsSettingsCpf] = useState<any>();
    const [displayLastValidCertificate, setDisplayLastValidCertificate] = useState<boolean>(false);
    const [customStatusForCertificate, setCustomStatusForCertificate] = useState<boolean>(false);
    const [certificatesCheckbox, setCertificatesCheckbox] = useState<ICertificateCheckbox[]>([]);
    const [certificatesCpfCheckbox, setCertificatesCpfCheckbox] = useState<ICertificateCheckbox[]>([]);
    const [certificateMenu, setCertificateMenu] = useState<'default'|'jurisdiction'>('default');
    const [certificatesCpfCheckboxInitialValue, setCertificatesCpfCheckboxInitialValue] = useState<ICertificateCheckbox[]>([]);
    const { setValue, register, watch, handleSubmit, getValues } = useForm<IConfigPanelForm>({
        resolver: yupResolver(YupCompanyChangeForm)
    });
    const { validate } = useLoginFormStore();

    const toast = useToast();

    const toastError = () => {
        toast({
            title: 'Ocorreu um erro',
            description: 'Ocorreu um erro ao tentar puxar as informações de configuração',
            status: 'error',
            duration: 5000,
            isClosable: true
        });
    }

    const fetchListPersonaData = async () => {
        if (props.guid_client) {
            const filters = {
                page: 1,
                row_by_page: '-1'
            };

            const { status, response } = await listWithoutPermissionPersonas(props.guid_client, filters);

            if (
                status === 200
                && 'meta' in response 
                && 'data' in response
                && response.data !== undefined
            ) {

                const data: IPersona[] = response.data;

                setPersonas(data);
            } else {
                toastError();
            }
        }
    }

    const getData = async () => {
        setIsLoading(true);
        const { status, response } = await getClientCompanies(props.guid_client);
        if (status === 200) {
            if (Array.isArray(response)) {
                setAllCompanies(response);
            } else {
                toast({
                    title: 'Ocorreu um erro',
                    description: 'Ocorreu um erro ao tentar puxar as informações dos usuários',
                    status: 'error',
                    duration: 5000,
                    isClosable: true
                });
            }
        } else {
            toastError();
        }

        await fetchListPersonaData();

        const clientResponse = await getClient(props.guid_client);
        if (clientResponse.status === 200) {
            setClient(clientResponse.response as IClientResponse);
        } else {
            toastError();
        }
        setIsLoading(false);
    }

    const handleFormSubmit = async (data: IConfigPanelForm) => {
        setIsDisabled(true)
        let products: any = {
            kanban: false,
            situation_cnpj: false,
            ...props.certificates.reduce(
                (acc, certificate) => ({
                    ...acc,
                    [certificate.slug as string]: false,
                }),
                {}
            ),
        };

        let productsCpf: any = {
            ...props.certificates.filter((certificate) => !!certificate.search_for_individual_allowed).reduce(
                (acc, certificate) => ({
                    ...acc,
                    [certificate.slug as string]: false,
                }),
                {}
            ),
        };

        let productsSettings: any = {
            ...props.certificates.reduce(
                (acc, certificate) => ({
                    ...acc,
                    [certificate.slug as string]: {recurrency: ""},
                }),
                {}
            ),
            situation_cnpj: {recurrency: ""}
        };

        let productsSettingsCpf: any = {
            ...props.certificates.reduce(
                (acc, certificate) => ({
                    ...acc,
                    [certificate.slug as string]: {recurrency: ""},
                }),
                {}
            ),
        };

        kanban ? products["kanban"] = true : products["kanban"] = false;
        situation ? products["situation_cnpj"] = true : products["situation_cnpj"] = false;

        certificatesCheckbox.forEach((certificate) => {
            products[certificate.slug as string] = certificate.selected;
            productsSettings[certificate.slug as string].recurrency = certificate.recurrency;
        });

        productsSettings['situation_cnpj'].recurrency = situationRecurrency;

        certificatesCpfCheckbox.forEach((certificate) => {
            productsCpf[certificate.slug as string] = certificate.selected;
            productsSettingsCpf[certificate.slug as string].recurrency = certificate.recurrency;
        });

        const { status } = await editConfig(props.guid_client, {
            products: JSON.stringify(products),
            productsCpf: JSON.stringify(productsCpf),
            productsSettings: JSON.stringify(productsSettings),
            productsSettingsCpf: JSON.stringify(productsSettingsCpf),
            isScheduled: data.isScheduled,
            weeklyUpdate: data.weeklyUpdate,
            displayLastValidCertificate: displayLastValidCertificate,
            customStatusForCertificate: customStatusForCertificate,
            weeklyParticipants: JSON.stringify(emails),
            guids: allCompaniesScheduled,
            guids_personas: personasScheduled,
            field1: data.field1,
            field2: data.field2,
            field3: data.field3,
            certificate_menu: certificateMenu
        });

        if (status === 200) {
            toast({
                title: 'Configurações atualizadas',
                description: 'As configurações foram atualizadas.',
                status: 'success',
                duration: 5000,
                isClosable: true
            });
        } else {
            toast({
                title: 'Configurações não foram atualizadas',
                description: 'As configurações não foram atualizadas.',
                status: 'error',
                duration: 5000,
                isClosable: true
            });
        }
        setIsDisabled(false)
        props.hasChangeHook(false);
    }

    useEffect(() => {
        getData();
        props.hasChangeHook(false);

        // eslint-disable-next-line
    }, [props.guid_client])

    const checkHasChanges = () => {
        if(client && productsHasInitialized) {
            props.hasChangeHook(false);

            if(getValues('weeklyUpdate') !== client.weeklyUpdate) {
                props.hasChangeHook(true);
            }

            let products: any = {
                kanban: false,
                situation_cnpj: false,
                ...props.certificates.reduce(
                    (acc, certificate) => ({
                        ...acc,
                        [certificate.slug as string]: false,
                    }),
                    {}
                ),
            };

            let productsCpf: any = {
                ...props.certificates.filter((certificate) => !!certificate.search_for_individual_allowed).reduce(
                    (acc, certificate) => ({
                        ...acc,
                        [certificate.slug as string]: false,
                    }),
                    {}
                ),
            };

            kanban ? products["kanban"] = true : products["kanban"] = false;
            situation ? products["situation_cnpj"] = true : products["situation_cnpj"] = false;

            let productsSettings: any = {
                ...props.certificates.reduce(
                    (acc, certificate) => ({
                        ...acc,
                        [certificate.slug as string]: {recurrency: ""},
                    }),
                    {}
                ),
            };

            let productsSettingsCpf: any = {
                ...props.certificates.reduce(
                    (acc, certificate) => ({
                        ...acc,
                        [certificate.slug as string]: {recurrency: ""},
                    }),
                    {}
                ),
            };

            certificatesCheckbox.forEach((certificate) => {
                products[certificate.slug as string] = certificate.selected;
                productsSettings[certificate.slug as string].recurrency = certificate.recurrency;
            });

            if(
                JSON.stringify(products) !== client.products
                || JSON.stringify(productsSettings) !== client.productsSettings
                || JSON.stringify(certificatesCpfCheckboxInitialValue) !== JSON.stringify(certificatesCpfCheckbox)
                || JSON.stringify(emails) !== client.weeklyParticipants
            ) {
                props.hasChangeHook(true);
            }

            const selectedCompanies = allCompanies.filter(company => company.isScheduled === true);
            const guids_companies = selectedCompanies.map(company => company.guid_company);

            if(allCompaniesScheduled.length !== guids_companies.length) {
                props.hasChangeHook(true);
            }
        }
    }

    useEffect(() => {
        checkHasChanges();
    }, [
        getValues('weeklyUpdate'),
        certificatesCheckbox,
        products,
        productsSettings, 
        allCompaniesScheduled,
        client,
        productsHasInitialized,
        emails,
        certificatesCpfCheckbox
    ])

    useEffect(() => {
        const certificatesCheckboxData: ICertificateCheckbox[] = [];
        const certificatesCpfCheckboxData: ICertificateCheckbox[] = [];

        props.certificates.forEach((certificate) => {
            const optionsRecurrency: IOptionRecurrency[] = []
            
            certificate.recurrency?.forEach((recurrency) => (
                optionsRecurrency.push({
                    value: recurrency.value as string,
                    label: recurrency.name as string,
                })
            ));

            certificatesCheckboxData.push({
                name: certificate.name as string,
                slug: certificate.slug as string,
                selected: false,
                recurrency: "",
                optionsRecurrency,
            });

            if(certificate.search_for_individual_allowed) {
                certificatesCpfCheckboxData.push({
                    name: certificate.name as string,
                    slug: certificate.slug as string,
                    selected: false,
                    recurrency: "",
                    optionsRecurrency,
                });
            }
        });

        setCertificatesCheckbox(certificatesCheckboxData);
        setCertificatesCpfCheckbox(certificatesCpfCheckboxData);
        setCertificatesCpfCheckboxInitialValue(certificatesCpfCheckboxData);
        // eslint-disable-next-line
    }, [props.certificates, props.guid_client]);

    useEffect(() => {
        const selectedCompanies = allCompanies.filter(company => company.isScheduled === true);
        const guids_companies = selectedCompanies.map(company => company.guid_company);
        setAllCompaniesScheduled(guids_companies);
    }, [allCompanies])

    useEffect(() => {
        const selectedPersonas = personas.filter(persona => persona.isScheduled === true);
        const guids_personas = selectedPersonas.map(persona => persona.guid_persona);
        setPersonasScheduled(guids_personas);
    }, [personas])

    useEffect(() => {
        if (client) {
            setSituationRecurrency(undefined);
            setSituation(false);

            const _products = JSON.parse(client.products!);
            const _products_cpf = JSON.parse(client.products_cpf!);
            const _productsSettings = JSON.parse(client.productsSettings || "{}");
            const _productsSettingsCpf = JSON.parse(client.productsSettingsCpf || "{}");
            setProductsSettings(_productsSettings);
            setProductsSettingsCpf(_productsSettingsCpf);
            setProducts(_products);
            setProductsCpf(_products_cpf);
            setProductsHasInitialized(true)

            if(_productsSettings && _productsSettings.situation_cnpj && _productsSettings.situation_cnpj.recurrency) {
                setSituationRecurrency(_productsSettings.situation_cnpj.recurrency);
            }

            if(client.certificate_menu === 'jurisdiction') {
              setCertificateMenu('jurisdiction');
            }

            if(client.certificate_menu === 'default') {
              setCertificateMenu('default');
            }

            setDisplayLastValidCertificate(client.displayLastValidCertificate);
            setCustomStatusForCertificate(client.customStatusForCertificate);
        }
    }, [client])

    useEffect(() => {
      if (client && products) {
        setKanban(products.kanban);
        setSituation(products.situation_cnpj);
        setValue("weeklyUpdate", client.weeklyUpdate!);

        if(client.clientCustomFields[0]) {
          setValue("field1", client.clientCustomFields[0].name);
        }
        if(client.clientCustomFields[1]) {
          setValue("field2", client.clientCustomFields[1].name);
        }
        if(client.clientCustomFields[2]) {
          setValue("field3", client.clientCustomFields[2].name);
        }
      }
    }, [client, products, setValue])

    useEffect(() => {
        if (props.certificates && productsSettings) {
            props.certificates.forEach(certificate => {
                const currentCertificatesCheckbox: ICertificateCheckbox[] = [];

                certificatesCheckbox.forEach(certificateCheckbox => {
                    if (certificate.slug === certificateCheckbox.slug) {
                        certificateCheckbox.selected = products[certificate.slug as string];
                        certificateCheckbox.recurrency = productsSettings[certificate.slug as string]?.recurrency ?? '';
                    }

                    currentCertificatesCheckbox.push(certificateCheckbox);
                })

                setCertificatesCheckbox(currentCertificatesCheckbox);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productsSettings])

    useEffect(() => {
        if (props.certificates && productsSettingsCpf) {
            props.certificates.filter((certificate) => !!certificate.search_for_individual_allowed).forEach(certificate => {
                const currentCertificatesCheckbox: ICertificateCheckbox[] = [];

                certificatesCpfCheckbox.forEach(certificateCheckbox => {
                    if (certificate.slug === certificateCheckbox.slug && productsCpf && productsCpf.hasOwnProperty(certificate.slug)) {
                        certificateCheckbox.selected = productsCpf[certificate.slug as string];
                        certificateCheckbox.recurrency = productsSettingsCpf[certificate.slug as string]?.recurrency ?? '';
                    }

                    currentCertificatesCheckbox.push(certificateCheckbox);
                })

                setCertificatesCpfCheckbox(currentCertificatesCheckbox);
                setCertificatesCpfCheckboxInitialValue(currentCertificatesCheckbox);
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productsSettingsCpf])

    useEffect(() => {
        watch((value) => {
            value.weeklyUpdate ? setShowParticipants(true) : setShowParticipants(false);
        })
    }, [watch])

    const onInvalid = (errors: any) => { console.error(errors) }

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, slug: string) => {
        const updatedCertificates = certificatesCheckbox.map((certificate) =>
          certificate.slug === slug ? { ...certificate, selected: event.target.checked } : certificate
        );
      
        setCertificatesCheckbox(updatedCertificates);
    };

    const handleCheckboxCpfChange = (event: React.ChangeEvent<HTMLInputElement>, slug: string) => {
        const updatedCertificates = certificatesCpfCheckbox.map((certificate) =>
          certificate.slug === slug ? { ...certificate, selected: event.target.checked } : certificate
        );
      
        setCertificatesCpfCheckbox(updatedCertificates);
    };

    const handleCheckboxKanbanChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setKanban(event.target.checked);
    };

    const handleCheckboxSituationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSituation(event.target.checked);
    };

    const handleRecurrencyChange = (event: React.ChangeEvent<HTMLSelectElement>, slug: string) => {
        const updatedCertificates: ICertificateCheckbox[] = certificatesCheckbox.map((certificate) =>
          certificate.slug === slug ? { ...certificate, recurrency: event.target.value } : certificate
        );
      
        setCertificatesCheckbox(updatedCertificates);
    };

    const handleCertificateMenuChange = (value: string) => {
      if(value === 'jurisdiction') {
        setCertificateMenu('jurisdiction');
      }

      if(value === 'default') {
        setCertificateMenu('default');
      }
    };

    const handleRecurrencyCpfChange = (event: React.ChangeEvent<HTMLSelectElement>, slug: string) => {
        const updatedCertificatesCpf: ICertificateCheckbox[] = certificatesCpfCheckbox.map((certificate) =>
          certificate.slug === slug ? { ...certificate, recurrency: event.target.value } : certificate
        );
      
        setCertificatesCpfCheckbox(updatedCertificatesCpf);
    };

    const handleCheckboxIsScheduledChange = (e: { target: { value: string, checked: boolean } }) => {
        if (e.target.checked) {
            setAllCompaniesScheduled(allCompanies.map((company) => company.guid_company));
        } else {
            setAllCompaniesScheduled([]);
        }
    }

    const handleCheckboxPersonaIsScheduledChange = (e: { target: { value: string, checked: boolean } }) => {
        if (e.target.checked) {
            setPersonasScheduled(personas.map((persona) => persona.guid_persona));
        } else {
            setPersonasScheduled([]);
        }
    }

    const checkIsScheduled = () => {
        if(allCompaniesScheduled.length === allCompanies.length) {
            setIsScheduled(true);
        }
        
        if(allCompaniesScheduled.length < allCompanies.length) {
            setIsScheduled(false);
        }
    };

    const checkIsScheduledPersona = () => {
        if(personasScheduled.length === personas.length) {
            setIsScheduledPersona(true);
        }
        
        if(personasScheduled.length < personas.length) {
            setIsScheduledPersona(false);
        }
    };

    useEffect(() => {
        checkIsScheduled();
    }, [allCompaniesScheduled, allCompanies])

    useEffect(() => {
        checkIsScheduledPersona();
    }, [personasScheduled, personas])

    const getSelectedEmails = async (): Promise<string[]> => {
        let localEmails: string[] = [];
        const { status, response } = await getCompanyWeeklyParticipantsEmails(props.guid_client);
        if (status === 200 && Array.isArray(response)) {
            localEmails = response as string[];
        }

        if(localEmails.length === 0 && validate) {
            return [validate.email];
        }

        return localEmails;
    }

    const getEmailsOptions = async (): Promise<string[]> => {
        let localEmails: string[] = [];
        const { status, response } = await getCompanyPossibleEmails(props.guid_client);
        if (status === 200 && Array.isArray(response)) {
            localEmails = response as string[];
        }

        return localEmails;
    }

    return (<>
        <SelectCompanies
            guid_client={props.guid_client}
            companies={allCompanies}
            open={openSelectCompanies}
            setOpen={setOpenSelectCompanies}
            selectedCompanies={allCompaniesScheduled}
            setSelectedCompanies={setAllCompaniesScheduled}
            header="Selecione as empresas para ativar a recorrência de certidões e consultas"
            description="Selecione as empresas que você deseja ativar a recorrência de certidões e consultas"
            buttonText="Salvar alteração"
            isLoading={isLoading}
        />

        <SelectPersonas
            guid_client={props.guid_client}
            personas={personas}
            open={openSelectPersonas}
            setOpen={setOpenSelectPersonas}
            selectedPersonas={personasScheduled}
            setSelectedPersonas={setPersonasScheduled}
            header="Selecione as pessoas para ativar a recorrência de certidões e consultas"
            description="Selecione as pessoas que você deseja ativar a recorrência de certidões e consultas"
            buttonText="Ativar recorrência"
            isLoading={isLoading}
        />

        <Flex w="100%" direction="column" p="0" flexGrow="1" flexShrink="1" mr="30px" pt="10px" height="100%" gap={4} alignItems="stretch">
            <Flex flexDirection="column" height="100%">
                <Flex flexDirection="row" justifyContent="space-between" borderBottom="1px" borderBottomColor="gray.300" pb={4} mb={4}>
                    <Text fontSize="18px" mt={8} fontFamily="Poppins-Medium">Configurações de produtos</Text>
                </Flex> 
                <Flex w="100%" bg="white" p={4} direction="column" justifyContent="stretch">
                    {!isLoading && client ? (
                        <> 
                            {/* TODO: Review this method
                            <Text display="none" fontSize="14px" mt={8} fontFamily="Poppins-Medium" borderBottom="1px" borderBottomColor="gray.300">Selecione a empresa</Text>
                            <Flex display="none" alignItems="center" gap={2} direction="row" justifyContent="stretch" flexGrow={1} backgroundColor="gray.100">
                                <SelectFilter value={currentCompany} onChange={(e: { target: { value: string } }) => setCurrentCompany(e.target.value)} placeholder="Selecione a empresa">
                                    {allCompanies.map((_el, _i) => (
                                        <option key={_i} value={_el.guid_company}>{formatCnpj(_el.cnpj)} {_el.name}</option>
                                    ))}
                                </SelectFilter>
                            </Flex> 
                            */}
                            <br/><br/>
                            <Flex direction="column">
                                {/* Configurações gerais */}
                                <Accordion allowToggle defaultIndex={0}>
                                    <AccordionItem>
                                        
                                        <AccordionButton backgroundColor="gray.100">
                                            <Box as="span" flex='1' textAlign='left'>
                                                <Flex direction="column">
                                                    <Text fontSize="14px" mt={2} fontFamily="Poppins-Medium" >Gerais</Text>
                                                </Flex>
                                            </Box>

                                            <AccordionIcon />
                                        </AccordionButton>

                                        <AccordionPanel pb={4}>
                                            {/* Produto */}
                                            <Flex direction="column" gap={2} mb={4} >
                                                <Text fontSize="14px" mt={8} fontFamily="Poppins-Medium" borderBottom="1px" borderBottomColor="gray.300">Produtos Pessoa Jurídica</Text>
                                                <Flex direction="row" gap={4} pb={2}> 
                                                    <Checkbox colorScheme="green" isChecked={kanban} onChange={(event) => handleCheckboxKanbanChange(event)}>Kanban</Checkbox>
                                                    { certificatesCheckbox.map((certificate, index) => (
                                                        <Checkbox key={index} colorScheme="green" isChecked={certificate.selected} onChange={(event) => handleCheckboxChange(event, certificate.slug as string)}>{ certificate.name }</Checkbox>
                                                    ))}
                                                    <Checkbox colorScheme="green" isChecked={situation} onChange={(event) => handleCheckboxSituationChange(event)}>Situação Cadastral</Checkbox>
                                                </Flex>
                                            </Flex>

                                            <Flex direction="column" gap={2} mb={4} >
                                                <Text fontSize="14px" mt={8} fontFamily="Poppins-Medium" borderBottom="1px" borderBottomColor="gray.300">Produtos Pessoa Física</Text>
                                                <Flex direction="row" gap={4} pb={2}> 
                                                    { certificatesCpfCheckbox.map((certificate, index) => (
                                                        <Checkbox key={index} colorScheme="green" isChecked={certificate.selected} onChange={(event) => handleCheckboxCpfChange(event, certificate.slug as string)}>{ certificate.name }</Checkbox>
                                                    ))}
                                                </Flex>
                                            </Flex>

                                            {/* Update semanal por e-mail */}
                                            <Flex direction="column" mb={4}>
                                                <Text fontSize="14px" mt={8} fontFamily="Poppins-Medium" borderBottom="1px" borderBottomColor="gray.300">Update semanal por email</Text>
                                                <Checkbox colorScheme="green" isChecked={getValues("weeklyUpdate")} {...register("weeklyUpdate")} p={2}>
                                                    <Text fontSize={12} fontFamily="Poppins-Medium">Agendar atualização semanal por email?</Text>
                                                    <Text fontSize="12px" opacity={0.7} fontFamily="Poppins-Medium">
                                                        Com essa opção ativada, o sistema irá enviar semanalmente um email com informações sobre as empresas cadastradas.
                                                    </Text>
                                                </Checkbox><br/>
                                                {showParticipants ?
                                                    <Flex direction="column">
                                                        <Text fontSize="16px" opacity={0.9} mb={2} mt={2}>
                                                            Digite o(s) email(s) de quem irá receber a atualização semanal.
                                                        </Text>
                                                        <SelectMultiEmail
                                                            onChange={(_emails: string[]) => {
                                                                setEmails(_emails);
                                                            }}
                                                            dropdownParent='body'
                                                            getSelectedEmails={getSelectedEmails}
                                                            getEmailsOptions={getEmailsOptions}
                                                        />
                                                    </Flex>
                                                : null}
                                            </Flex>
                                        </AccordionPanel>
                                    </AccordionItem>
                                </Accordion>
                                <br/>
                                {/* Configurações de certidões */}
                                <Accordion allowToggle defaultIndex={0}>
                                    <AccordionItem>
                                        <AccordionButton backgroundColor="gray.100">
                                            <Box as="span" flex='1' textAlign='left'>
                                                <Flex direction="column">
                                                    <Text fontSize="14px" mt={2} fontFamily="Poppins-Medium" >Certidões - Configurações de Exibição</Text>
                                                </Flex>
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    
                                        <AccordionPanel pb={4}>
                                            <Flex direction="column" gap={2} mb={4}>
                                                <Text fontSize="14px" mt={2} fontFamily="Poppins-Medium">Exibição de certidões no menu</Text>
                                                <RadioGroup onChange={handleCertificateMenuChange} value={certificateMenu}>
                                                    <Flex direction="column" gap={2} pb={2}>
                                                        <Radio value="default">Agrupado por tipo de certidão (CND, FGTS, CNDT, etc.)</Radio>
                                                        <Radio value="jurisdiction">Agrupado por jurisdição de cada tipo de certidão (Federal, Estadual ou Municipal)</Radio>
                                                    </Flex>
                                                </RadioGroup>
                                            </Flex>


                                            <Flex direction="column" gap={2} mb={4}>
                                                <Text fontSize="14px" mt={2} fontFamily="Poppins-Medium">Exibição das certidões</Text>
                                                <RadioGroup onChange={(value) => setDisplayLastValidCertificate(value === 'true')} value={displayLastValidCertificate ? 'true' : 'false'}>
                                                    <Flex direction="column" gap={2} pb={2}>
                                                        <Radio value="true">Exibir última certidão válida</Radio>
                                                        <Radio value="false">Exibir última certidão encontrada</Radio>
                                                    </Flex>
                                                </RadioGroup>
                                            </Flex>


                                            <Flex direction="column" gap={2} mb={4}>
                                                <Text fontSize="14px" mt={2} fontFamily="Poppins-Medium">Exibição dos status</Text>
                                                <RadioGroup onChange={(value) => setCustomStatusForCertificate(value === 'true')} value={customStatusForCertificate ? 'true' : 'false'}>
                                                    <Flex direction="column" gap={2} pb={2}>
                                                        <Radio value="true">Exibir status individualizados</Radio>
                                                        <Radio value="false">Exibir status válido / inválido</Radio>
                                                    </Flex>
                                                </RadioGroup>
                                            </Flex>
                                        </AccordionPanel>
                                    </AccordionItem>
                                </Accordion>

                                {/* Configurações de certidões para empresas */}
                                <Accordion allowToggle defaultIndex={0}>
                                    <AccordionItem>
                                        <AccordionButton backgroundColor="gray.100">
                                            <Box as="span" flex='1' textAlign='left'>
                                                <Flex direction="column">
                                                    <Text fontSize="14px" mt={2} fontFamily="Poppins-Medium" >Gerenciador de certidões e consultas para empresas</Text>
                                                </Flex>
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    
                                        <AccordionPanel pb={4}>
                                            <Flex direction="column" mb={4}>
                                                <Text fontSize="14px" mt={8} fontFamily="Poppins-Medium" borderBottom="1px" borderBottomColor="gray.300">Recorrência de pesquisa e atualização</Text>
                                                <Checkbox size='sm'  colorScheme='green' isChecked={isScheduled} onChange={handleCheckboxIsScheduledChange}>
                                                    <Text fontSize={12} fontFamily="Poppins-Medium">Agendar a pesquisa recorrente de certidões e consultas para as empresas cadastradas no sistema?</Text>
                                                    <Text fontSize="12px" opacity={0.7} fontFamily="Poppins-Medium">
                                                        Selecionando esta opção, a taxly buscará de forma recorrente as certidões contratadas na plataforma para as empresas cadastradas nas datas de recorrência definidas abaixo.
                                                    </Text>
                                                </Checkbox>
                                                <a href="javascript:;" onClick={() => setOpenSelectCompanies(true)} style={{color: "#4B4EFF", fontFamily:"Poppins-Medium", textDecoration: "underline", margin:"5px 0 5px 20px", fontSize:"12px"}}>Selecione as empresas para ativar a recorrência de certidões e consultas</a>
                                                <Text fontSize="11px" marginLeft="20px" opacity={0.8}>A Taxly buscará de forma automática recorrente e automática as certidões contratadas na plataforma para as empresas selecionadas nos períodos de recorrência definidos abaixo</Text>
                                                <Text fontSize="11px" marginLeft="20px" opacity={0.8}>Empresas <strong style={{color: "green"}}>com</strong> recorrência ativada: {allCompaniesScheduled.length}</Text>
                                                <Text fontSize="11px" marginLeft="20px" opacity={0.8}>Empresas <strong style={{color: "orange"}}>com</strong> recorrência desativada: {allCompanies.length - allCompaniesScheduled.length}</Text>
                                            </Flex>  

                                            <Flex direction="column">
                                                <Text fontSize="14px" mt={8} fontFamily="Poppins-Medium" borderBottom="1px" borderBottomColor="gray.300">Período de recorrência das certidões</Text>
                                                <Flex alignItems="center">
                                                    <Text fontSize="12px"  p={2} fontFamily="Poppins-Medium">Defina abaixo o período das pesquisas recorrentes das certidões e consultas das empresas cadastradas no sistema.</Text>
                                                    <Tooltip label="Clique para saber mais detalhes sobre as regras de atualização">
                                                        <Image 
                                                            cursor="pointer"
                                                            onClick={() => setOpenDetails(true)}
                                                            height={5}
                                                            width={5}
                                                            src="/icons/information-circle-blue.svg"
                                                            resize="none"
                                                            marginLeft="2px"
                                                        />
                                                    </Tooltip>
                                                </Flex>

                                                <Text fontSize="12px" marginBottom="2px" width="90%" p={2} fontFamily="Poppins-Medium">Caso alguma certidão ou consulta tenha um período específico de renovação determinado pelo órgão consultado, o sistema irá renovar essas certidões e consultas na menor periodicidade possível.</Text>

                                                <Flex direction="row" display="flex" width="100%">
                                                    {certificatesCheckbox.map((certificateCheckbox, index) => (
                                                        <Flex key={index} width="50%" direction="column" display={certificateCheckbox.selected ? "block" : "none"}>
                                                            <Text fontSize="14px" mt={0} fontFamily="Poppins-Medium" borderBottomColor="gray.300">{certificateCheckbox.name}:</Text>
                                                            <Select 
                                                                variant="outline"  
                                                                bg="gray.100" 
                                                                width="100%"
                                                                borderRadius={6} 
                                                                p="6px"
                                                                border="1px" 
                                                                borderColor="gray.300"
                                                                fontSize="12px"
                                                                placeholder="Selecione o período de atualização"
                                                                onChange={(event) => handleRecurrencyChange(event, certificateCheckbox.slug as string)}
                                                            > 
                                                                {certificateCheckbox.optionsRecurrency.map(option => (
                                                                    <option key={option.value} selected={option.value === certificateCheckbox.recurrency} value={option.value}>{option.label}</option>
                                                                ))}
                                                            </Select> 
                                                        </Flex>
                                                    ))}

                                                    {
                                                        situation ? (
                                                            <Flex width="50%" direction="column">
                                                                <Text fontSize="14px" mt={0} fontFamily="Poppins-Medium" borderBottomColor="gray.300">Situação Cadastral:</Text>
                                                                <Select
                                                                    variant="outline"
                                                                    bg="gray.100"
                                                                    width="100%"
                                                                    borderRadius={6}
                                                                    p="6px"
                                                                    border="1px"
                                                                    borderColor="gray.300"
                                                                    fontSize="12px"
                                                                    placeholder="Selecione o período de atualização"
                                                                    onChange={(event) => setSituationRecurrency(event.target.value)}
                                                                >
                                                                    <option selected={situationRecurrency === "monthly"} value="monthly">Mensal</option>
                                                                    <option selected={situationRecurrency === "weekly"} value="weekly">Semanal</option>
                                                                    <option selected={situationRecurrency === "biweekly"} value="biweekly">Quinzenal</option>
                                                                    <option selected={situationRecurrency === "manual"} value="manual">Sob Demanda</option>
                                                                </Select>
                                                            </Flex>
                                                        ) : null
                                                    }
                                                </Flex>
                                            </Flex>
                                        </AccordionPanel>
                                    </AccordionItem>
                                </Accordion>
                                
                                <br/>
                                <br/>

                                {/* Configurações de certidões de pessoa fisica */}
                                {
                                    certificatesCpfCheckbox.some((checkbox) => checkbox.selected)
                                    ? (<>
                                        <Accordion allowToggle defaultIndex={0}>
                                            <AccordionItem>
                                                <AccordionButton backgroundColor="gray.100">
                                                    <Box as="span" flex='1' textAlign='left'>
                                                        <Flex direction="column">
                                                            <Text fontSize="14px" mt={2} fontFamily="Poppins-Medium" >Gerenciador de certidões e consultas para pessoa física</Text>
                                                        </Flex>
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                            
                                                <AccordionPanel pb={4}>
                                                    <Flex direction="column" mb={4}>
                                                        <Text fontSize="14px" mt={8} fontFamily="Poppins-Medium" borderBottom="1px" borderBottomColor="gray.300">Recorrência de pesquisa e atualização</Text>
                                                        <Checkbox size='sm'  colorScheme='green' isChecked={isScheduledPersona} onChange={handleCheckboxPersonaIsScheduledChange}>
                                                            <Text fontSize={12} fontFamily="Poppins-Medium">Agendar a pesquisa recorrente de certidões e consultas para as pessoas cadastradas no sistema?</Text>
                                                            <Text fontSize="12px" opacity={0.7} fontFamily="Poppins-Medium">
                                                                Selecionando esta opção, a taxly buscará de forma recorrente as certidões contratadas na plataforma para as pessoas cadastradas nas datas de recorrência definidas abaixo.
                                                            </Text>
                                                        </Checkbox>
                                                        <a href="javascript:;" onClick={() => setOpenSelectPersonas(true)} style={{color: "#4B4EFF", fontFamily:"Poppins-Medium", textDecoration: "underline", margin:"5px 0 5px 20px", fontSize:"12px"}}>Selecione as pessoas para ativar a recorrência de certidões e consultas</a>
                                                        <Text fontSize="11px" marginLeft="20px" opacity={0.8}>A Taxly buscará de forma automática recorrente e automática as certidões contratadas na plataforma para as pessoas selecionadas nos períodos de recorrência definidos abaixo</Text>
                                                        <Text fontSize="11px" marginLeft="20px" opacity={0.8}>Pessoas <strong style={{color: "green"}}>com</strong> recorrência ativada: {personasScheduled.length}</Text>
                                                        <Text fontSize="11px" marginLeft="20px" opacity={0.8}>Pessoas <strong style={{color: "orange"}}>com</strong> recorrência desativada: {personas.length - personasScheduled.length}</Text>
                                                    </Flex>  

                                                    <Flex direction="column">
                                                        <Text fontSize="14px" mt={8} fontFamily="Poppins-Medium" borderBottom="1px" borderBottomColor="gray.300">Período de recorrência das certidões</Text>
                                                        <Flex alignItems="center">
                                                            <Text fontSize="12px"  p={2} fontFamily="Poppins-Medium">Defina abaixo o período das pesquisas recorrentes das certidões e consultas das pessoas cadastradas no sistema.</Text>
                                                            <Tooltip label="Clique para saber mais detalhes sobre as regras de atualização">
                                                                <Image 
                                                                    cursor="pointer"
                                                                    onClick={() => setOpenDetails(true)}
                                                                    height={5}
                                                                    width={5}
                                                                    src="/icons/information-circle-blue.svg"
                                                                    resize="none"
                                                                    marginLeft="2px"
                                                                />
                                                            </Tooltip>
                                                        </Flex>

                                                        <Text fontSize="12px" marginBottom="2px" width="90%" p={2} fontFamily="Poppins-Medium">Caso alguma certidão ou consulta tenha um período específico de renovação determinado pelo órgão consultado, o sistema irá renovar essas certidões e consultas na menor periodicidade possível.</Text>

                                                        <Flex direction="row" display="flex" width="100%">
                                                            {certificatesCpfCheckbox.map((certificateCpfCheckbox, index) => (
                                                                <Flex key={index} width="50%" direction="column" display={certificateCpfCheckbox.selected ? "block" : "none"}>
                                                                    <Text fontSize="14px" mt={0} fontFamily="Poppins-Medium" borderBottomColor="gray.300">{certificateCpfCheckbox.name}:</Text>
                                                                    <Select 
                                                                        variant="outline"  
                                                                        bg="gray.100" 
                                                                        width="100%"
                                                                        borderRadius={6} 
                                                                        p="6px"
                                                                        border="1px" 
                                                                        borderColor="gray.300"
                                                                        fontSize="12px"
                                                                        placeholder="Selecione o período de atualização"
                                                                        onChange={(event) => handleRecurrencyCpfChange(event, certificateCpfCheckbox.slug as string)}
                                                                    > 
                                                                        {certificateCpfCheckbox.optionsRecurrency.map(option => (
                                                                            <option key={option.value} selected={option.value === certificateCpfCheckbox.recurrency} value={option.value}>{option.label}</option>
                                                                        ))}
                                                                    </Select> 
                                                                </Flex>
                                                            ))}
                                                        </Flex>
                                                    </Flex>
                                                </AccordionPanel>
                                            </AccordionItem>
                                        </Accordion>
                                    </>)
                                    : null
                                }

                                {/* Configurações de campos customizados */}
                                <Accordion allowToggle defaultIndex={0}>
                                  <AccordionItem>
                                    <AccordionButton backgroundColor="gray.100">
                                      <Box as="span" flex='1' textAlign='left'>
                                        <Flex direction="column">
                                          <Text fontSize="14px" mt={2} fontFamily="Poppins-Medium" >Gerenciador de campos customizados</Text>
                                        </Flex>
                                      </Box>
                                      <AccordionIcon />
                                    </AccordionButton>
                                
                                    <AccordionPanel pb={4}>
                                      <Flex direction="column" mb={4} fontFamily="Poppins-Medium">
                                        <Text fontSize="14px" mt={8} borderBottom="1px" borderBottomColor="gray.300">Campos customizados</Text>
                                        <Text fontSize="11px" opacity="0.8">Defina um nome para cada campo customizado.</Text>
                                        <Text fontSize="11px" opacity="0.8">O campo será ativo assim que o nome for definido.</Text>

                                        <Flex mt="15px" gap={3}>
                                          <TaxlyInput
                                            placeholder="Nome do campo 1" 
                                            label={'Campo 1'}
                                            {...register("field1")}
                                          />

                                          <TaxlyInput
                                            placeholder="Nome do campo 2" 
                                            label={'Campo 2'}
                                            {...register("field2")}
                                          />

                                          <TaxlyInput
                                            placeholder="Nome do campo 3" 
                                            label={'Campo 3'}
                                            {...register("field3")}
                                          />
                                        </Flex>
                                      </Flex>
                                    </AccordionPanel>
                                  </AccordionItem>
                                </Accordion>
                                
                                
                                <br/>
                                <br/>

                                <Flex justifyContent="center" mt={4}>
                                    <Button width="70%"bgColor="#4B4EFF" color="white" _hover={{ bg: '#282be0' }} fontSize="14px" onClick={
                                        handleSubmit(handleFormSubmit, onInvalid)
                                    } isDisabled={isDisabled}>
                                        Salvar configurações
                                    </Button>
                                </Flex><br/>
                            </Flex>
                        </>
                    ) : null}
                </Flex><br/>
            </Flex>
        </Flex>

        <Modal closeOnOverlayClick={true} isOpen={openDetails} onClose={() => setOpenDetails(false)} size='xl'>
            <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px)' alignItems="center" />
            <ModalContent flexGrow={1} flexShrink={1} borderLeft="12px solid #0263FF" borderRadius={8}>
                <ModalHeader fontFamily="Poppins-SemiBold">Detalhes de regra de atualização</ModalHeader>
                <ModalCloseButton />
                <ModalBody fontFamily="Poppins-medium" fontSize={12}>
                <Text fontSize="12px" width="75%" p={2} fontFamily="Poppins-Medium" marginBottom="2px">
                    <u>Regra de atualização:</u>
                    <p><b>- Semanal:</b> Segundas-feiras</p>
                    <p><b>- Quinzenal:</b> Primeiro dia útil de cada quinzena</p>
                    <p><b>- Mensal:</b> 1º dia útil de cada mês</p>
                    <p><b>- Sob Demanda:</b> Atualizações mediante pesquisa manual</p>
                </Text> 
                </ModalBody>
            </ModalContent>
        </Modal>
    </>)
}
