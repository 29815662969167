import { ITag } from "./table.interface";

export const stateRegionForTag = (initials: string): ITag => {
    const norte = ["AM", "RR", "AP", "PA", "TO", "RO", "AC"];
    const nordeste = ["MA", "PI", "CE", "RN", "PE", "PB", "SE", "AL", "BA"];
    const centroOeste = ["MT", "MS", "GO", "DF"];
    const sudeste = ["SP", "RJ", "ES", "MG"];
    const sul = ["PR", "RS", "SC"];
    let color = null;
    let textColor = null;

    if (norte.includes(initials)) {
        color = "#ECFCCB"
        textColor= "#365314"
    };

    if (nordeste.includes(initials)) {
        color = "#EDE9FE";
        textColor= "#4C1D95"
    }

    if (centroOeste.includes(initials)) {
        color = "#FAE8FF";
        textColor= "#701A75"
    }

    if (sudeste.includes(initials)) {
        color = "#DDE5FF";
        textColor= "#292689"
    }

    if (sul.includes(initials)) {
        color = "#E0F2FE";
        textColor= "#0C4A6E"
    }

    return {
        text: initials,
        color: color,
        textColor: textColor
    } as ITag
};

export const normalizeString = (str: string) => {
    return str
      .normalize("NFD") // Normaliza a string para decompor caracteres especiais
      .replace(/[\u0300-\u036f]/g, "") // Remove acentos
      .replace(/[^a-zA-Z0-9]/g, "") // Remove caracteres especiais
      .toUpperCase(); // Converte para maiúsculas
  };