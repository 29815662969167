import { Flex, Table, Text, TableContainer, Tbody, Td, Th, Thead, Tr, Image, Badge, Tooltip, useToast } from "@chakra-ui/react"
import { useEffect, useState } from "react";
import { getClient, getClientEmployees } from "../../../_services/client.service";
import { InviteUserModal } from "./modais/InviteUserModal";
import { AllowUserModal } from "./modais/AllowUserModal";
import { ICollaborators, IGetEmployeesResponse } from "../../../../src/_services/interface/company.interface"
import { EditUserModal } from "./modais/EditUserModal";
import { IFilterListUser, IResponse, IUser } from "../../../_services/interface/user.interface";
import { ReinviteUserModal } from "./modais/ReinviteUser";
import moment from "moment";
import LoadingComponent from "../../../components/loading";
import { PermissionsModal } from "./modais/PermissionsModal";
import { checkClientPermission } from "./utils/checkPermission";
import { HistoryModal } from "./modais/HistoryModal";
import { getIsSSO } from "../../../_services/auth.service";
import { FilterDataSelected, FilterRow, Filters, FilterTypeEnum } from "../../../components/Filters";
import { IAction, IColumn, IData, ITableMetaData } from "../../../components/Table/table.interface";
import { Datatables } from "../../../components/Table/Datatables";
import { listUsers } from "../../../_services/user.service";
import { IClientResponse } from "../../../_services/interface/client.interface";

interface userProp {
  name: string,
  email: string,
  active: boolean,
  status: string,
  envite: boolean,
  hasAccessed: boolean | null
}

export interface userProps extends Array<userProp> { }

interface IUserPanelProps {
  validation: IResponse;
  guid_client: string;
}

interface IUserTagProps {
  text: string;
  color: string;
  textColor?: string;
  label?: string;
}

export const UserTag = (props: IUserTagProps) => {
  if (props.label) {
    return (
      <Tooltip label={props.label}>
        <Badge p="3px 15px" borderRadius={5} bg={props.color} color={props.textColor ?? '#1a202c'} fontSize={10}>{props.text}</Badge>
      </Tooltip>
    );
  }
  return <Badge p="3px 15px" borderRadius={5} bg={props.color} color={props.textColor ?? '#1a202c'} fontSize={10}>{props.text}</Badge>;
}

export const UserPanel = (props: IUserPanelProps) => {
  const [refreshData, setRefreshData] = useState<boolean>(false);
  const [isLoading, setLoadingState] = useState<boolean>(false);
  const toast = useToast();
  const [isSSO, setIsSSO] = useState<any>(false)
  const [filterDataSelected, setFilterDataSelected] = useState<FilterDataSelected|null>(null);
  const [clearFilters, setClearFilters] = useState<boolean>(false);
  const [metaData, setMetaData] = useState<ITableMetaData|undefined>();
  const [userList, setUserList] = useState<IData<any>|null>(null);
  const [totalRows, setTotalRows] = useState<number|undefined>(undefined);

  const [client, setClient] = useState<IClientResponse|null>(null);

  const [currentUser, setCurrentUser] = useState<IUser>();
  const [openEditUserModal, setOpenEditUserModal] = useState<boolean>(false);
  const [openHistoryUserModal, setOpenHistoryUserModal] = useState<boolean>(false);
  const [openPermissionUserModal, setOpenPermissionUserModal] = useState<boolean>(false);
  const [openReenviteUserModal, setOpenReenviteUserModal] = useState<boolean>(false);

  const fetchClientData = async () => {
    const { status, response } = await getClient(props.guid_client);

    if (status === 200 && response) {
      const client = response as IClientResponse;
      setClient(client);
    }
  }

  const fetchData = async () => {
    if (props.guid_client) {
      setLoadingState(true);

      const filters = getFilter();
      const { status, response } = await listUsers(props.guid_client, filters);

      if (
          status === 200
          && response.meta.pageCount !== undefined
          && response.data !== undefined
      ) {
        setTotalRows(response.meta.totalCount);
        setUserList(response);
      } else {
        toast({
          title: 'Ocorreu um erro',
          description: 'Ocorreu um erro ao tentar puxar as informações dos usuários',
          status: 'error',
          duration: 5000,
          isClosable: true
        });
      }

      const _isSSO = (await getIsSSO()).response
      setIsSSO(_isSSO);

      setLoadingState(false);
    }
  }

  const getFilter = (): IFilterListUser => {
    const validFilterField = (field: string | string[] | undefined): boolean => {
      if (field) {
        if (typeof field === 'string') {
          if (field.trim() !== '') {
            return true;
          }
        } else if (field instanceof Array) {
          if (field.length > 0) {
            return true;
          }
        }
      }
      return false;
    }

    const names: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.userNameFilter] : [];
    const emails: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.userEmailFilter] : [];
    const status: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.userStatusFilter] : [];
    const guidUsers = [...new Set([...emails, ...names])];

    return {
      page: metaData?.currentPage ?? 1,
      row_by_page: metaData?.rowByPage ?? -1,
      order_field: metaData?.orderField ?? 'createdAt',
      order_direction: metaData?.orderDirection ?? 'desc',
        ...(validFilterField(guidUsers) ? {guids: guidUsers.join(',')} : {}),
        ...(validFilterField(status) ? {isActive: status.join(',')} : {}),
    };
  }

  useEffect(() => {
    setRefreshData(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    metaData,
    filterDataSelected,
    props.guid_client,
  ]);

  useEffect(() => {
    if (refreshData) {
      //Refresh the company
      fetchData();
      setRefreshData(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshData]);

  useEffect(() => {
    if(props.guid_client) {
      fetchClientData();
    }
  }, [props.guid_client]);

  const canSendInvitation = (data: IUser): 1|2|3 => {
    if (data.isInvited) {
      if (!data.isBlocked) {
        if (!data.isValidated) {
          if (moment.utc(data.codeTimeout).diff(moment.utc(), 'minutes') < 0) {
            // The user can be reinvited
            return 1;
          } else {
            // Still on cooldown, cannot send invitation
            return 3;
          }
        } else {
          // User is already validated, cannot send invitation
          return 2;
        }
      } else {
        // User is blocked, cannot send invitation
        return 2;
      }
    } else {
      // User is not invited, can send invitation
      if(!data.isValidated){
        return 1;
      }
      return 2
    }
  };

  const checkUserPermissionAndInvitation = (data: IUser): boolean => {
    const hasPermission = checkClientPermission(props.validation, props.guid_client, 'user.write');
  
    if (!hasPermission) {
      return false;
    }
  
    return true;
  };
  
  

  const isDisabledEditUser = (user: IUser): boolean => {
    if (user.guid_user === props.validation.guid_user) {
      return true;
    }

    const collaborator = user.organizations.find((x) => x.guid_client === props.guid_client);

    if (
      !collaborator 
      || JSON.parse(collaborator.permissions as string).includes('org.owner') 
      || user.email === 'suporte@taxly.com.br'
    ) {
      return true;
    }

    if (checkClientPermission(props.validation, props.guid_client, 'user.write')) {
      return false;
    }

    return true;
  }

  const isVisiblePermissionModal = (user: IUser) => {
    const collaborator = user.organizations.find((x) => x.guid_client === props.guid_client);

    if (
      !collaborator 
      || JSON.parse(collaborator.permissions as string).includes('org.owner')  
      || user.email === 'suporte@taxly.com.br'
    ) {
      return false;
    }

    if (user.guid_user === props.validation.guid_user) {
      return false
    }

    if (checkClientPermission(props.validation, props.guid_client, 'org.admin')) {
      return true;
    }

    return false;
  }

  const filterRowsCertificate = [
    {
      rowFields: [
        {
          name: FilterTypeEnum.userNameFilter,
          position: 1,
          size: 4,
        },
        {
          name: FilterTypeEnum.userEmailFilter,
          position: 2,
          size: 4,
        },
        {
          name: FilterTypeEnum.userStatusFilter,
          position: 3,
          size: 4,
        },
      ],
      position: 1
    },
  ] as FilterRow[];

  
  const columns = [
    {
      title: 'Nome',
      name: 'name',
      sortable: true,
      align: 'left',
      width: '35%',
    },
    {
      title: 'E-mail',
      name: 'email',
      sortable: true,
      align: 'left',
      width: '55%',
    },
    {
      title: 'Status',
      name: 'isActive',
      sortable: true,
      orderFieldName: 'isBlocked,isActive,isValidated',
      align: 'center',
      width: '5%',
      dynamicTag: {
        function: (row: IUser) => {
          if (row.isValidated) {
            if (row.isBlocked || !row.isActive) {
              return { 
                value: false, 
                text: 'Inativo',
                color: '#FEE2E2',
                textColor: '#7F1D1D'
              }
            } else {
              return { 
                text: 'Validado',
                color: '#DCFCE7',
                textColor: '#14532D'
              }
            }
          } else {
            if (row.isBlocked || !row.isActive) {
              return { 
                text: 'Inativo',
                color: '#FEE2E2',
                textColor: '#7F1D1D'
              }
            } else {
              return { 
                text: 'Aguardando',
                color: '#FEF9C3',
                textColor: '#713F12'
              }
            }
          }
        }
      }
    },
    {
      title: 'Convite',
      name: 'isInvited',
      sortable: false,
      align: 'center',
      width: '5%',
      actions: [
        { 
          validation: (row: any) => checkUserPermissionAndInvitation(row),
          getValue: (row: any) => canSendInvitation(row),
          value: 1,
          icon: <Tooltip label="Reenviar convite para este usuário" ><Image src="../icons/envelope.svg" /></Tooltip>,
          onClick: (row: any) => {
            setCurrentUser(row);
            setOpenReenviteUserModal(true);
          }
        },
        { 
          validation: (row: any) => checkUserPermissionAndInvitation(row),
          getValue: (row: any) => canSendInvitation(row),
          value: 2,
          icon:<Tooltip label="Usuário convidado"><Image src="../icons/envelope-gray.svg" /></Tooltip>,
        },
        { 
          validation: (row: any) => checkUserPermissionAndInvitation(row),
          getValue: (row: any) => canSendInvitation(row),
          value: 3,
          icon:<Tooltip label="Você poderá enviar um novo convite em breve"><Image src="../icons/envelope-waiting.svg" /></Tooltip>,
        }
      ]
    },
    
  ] as IColumn[];

  const actions = [
    {
      text: 'Histórico',
      icon: <Image src="/icons/table/refresh.svg" width={5} height="auto" />,
      isVisible: (user: IUser): boolean => {return true},
      isDisabled: (user: IUser): boolean => {return false},
      action: (user: IUser) => {
        setCurrentUser(user);
        setOpenHistoryUserModal(true);
      },
    },
    {
      text: 'Editar',
      icon: <Image src="/icons/table/edit.svg" width={5} height="auto" />,
      isVisible: (user: IUser): boolean => {return true},
      isDisabled: (user: IUser): boolean => isDisabledEditUser(user),
      action: (user: IUser) => {
        setCurrentUser(user);
        setOpenEditUserModal(true);
      },
    },
    {
      text: 'Permissões',
      icon: <Image src="/icons/table/key.svg" width={5} height="auto" />,
      isVisible: (user: IUser): boolean => isVisiblePermissionModal(user),
      isDisabled: (user: IUser): boolean => {return false},
      action: (user: IUser) => {
        setCurrentUser(user);
        setOpenPermissionUserModal(true);
      },
    }
  ] as IAction[];

  return (<>
      <Flex direction="column" w="100%" p="0" flexGrow="1" flexShrink="1" mr="30px" mt="10px">
        <Flex flexDirection="row" justifyContent="space-between" borderBottom="1px" borderBottomColor="gray.300" pb={4} mb="15px">
          <Text fontSize="18px" mt={8} fontFamily="Poppins-Medium">Usuários ({totalRows ?? 0})</Text>

          {!isLoading && client && checkClientPermission(props.validation, props.guid_client, 'user.write') ? (
          isSSO ?
            <AllowUserModal client={client} flushHook={setRefreshData} />
            :
            <InviteUserModal client={client} flushHook={setRefreshData} />
          ) : null}
        </Flex>

        <Filters
          guid_client={props.guid_client}
          filters={filterRowsCertificate}
          selectedValuesHook={setFilterDataSelected}
          clearFilters={clearFilters}
        />

        <Flex mt={12} direction="column">
          <Datatables
            name="Usuários"
            columns={columns}
            actions={actions}
            metaDataHook={setMetaData}
            data={userList}
            isLoading={isLoading}
          />
        </Flex>
      </Flex>

      <EditUserModal 
        user={currentUser} 
        guid_client={props.guid_client} 
        flushHook={setRefreshData} 
        validation={props.validation} 
        openModal={openEditUserModal}
        openModalHook={setOpenEditUserModal}
      />

      <HistoryModal 
        user={currentUser} 
        guid_client={props.guid_client}
        openModal={openHistoryUserModal}
        openModalHook={setOpenHistoryUserModal}
      />

      <PermissionsModal 
        user={currentUser}
        guid_client={props.guid_client}
        validation={props.validation} 
        openModal={openPermissionUserModal}
        openModalHook={setOpenPermissionUserModal}
      />

      {currentUser && (
        <ReinviteUserModal 
          guid_client={props.guid_client}
          guid_user={currentUser.guid_user} 
          email={currentUser.email} 
          flushHook={setRefreshData} 
          openModal={openReenviteUserModal}
          openModalHook={setOpenReenviteUserModal}
        />
      )}
  </>)
}
