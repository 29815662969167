import { ICardNameFilter } from "../components/Interfaces/Filter.interface";
import { ApiAuth, ApiCall } from "./api";
import { ICompanyFilter, IPersonaFilter } from "./interface/filter.interface";
import { TPremiseResponse } from "./interface/IDefaults";

export const getFilterCardName = async (guid_client: string): Promise<TPremiseResponse<any>> => {
  const url = `/filter/${guid_client}/card`;
  return await new ApiCall(url, ApiAuth()).get<ICardNameFilter[]>();
};

export const getCompanyFilterOptions = async (guid_client: string): Promise<TPremiseResponse<ICompanyFilter[]>> => {
  return await new ApiCall(`/filter/${guid_client}/company`, ApiAuth()).get<ICompanyFilter[]>();
};

export const getPersonaFilterOptions = async (guid_client: string): Promise<TPremiseResponse<IPersonaFilter[]>> => {
  return await new ApiCall(`/filter/${guid_client}/persona`, ApiAuth()).get<IPersonaFilter[]>();
};