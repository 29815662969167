import { ModalBody, ModalFooter, ModalHeader } from "@chakra-ui/modal";
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Checkbox, Flex, Image, Text } from "@chakra-ui/react";
import { Tag } from "../../../../../../components/Table/Tag";
import { ITag } from "../../../../../../components/Table/table.interface";
import './../../../../../../styles/components/AccordionItem.css';
import { useEffect, useState } from "react";
import { FormButton } from "../../../../../../components/login/button.component";
import { IBulkUpdateCompany, IBulkUpdateCompanyItem, ICompanyBulkCreate } from "../../../../../../_services/interface/company.interface";
import { postCancelBulkCreateCompany, postFinishBulkCreateCompany, postReprocessBulkCreateCompany } from "../../../../../../_services/company.service";
import { Step3ConfirmationTable } from "./Step3ConfirmationTable";
import { ICompanyBulk } from "./company.interface";
import { IBulkCompanyList } from "./Step1Form";
import { Step3ConfirmationCancel } from "./Step3ConfirmationCancel";
import { Step3ConfirmationContinueRegister } from "./Step3ConfirmationContinueRegister";
import { Socket } from "socket.io-client";
import { DefaultEventsMap } from "@socket.io/component-emitter";

interface IProps {
  socket: Socket<DefaultEventsMap, DefaultEventsMap> | undefined;
  guid_client: string;
  onClose: () => void;
  onCloseWithToast: () => void;
  items: IBulkUpdateCompanyItem[] | undefined;
  bulkUpdate: IBulkUpdateCompany | undefined;
}

export const Step3Confirmation = (props: IProps) => {
  const [openCancelModal, setOpenCancelModal] = useState<boolean>(false);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);

  const [searchCertificate, setSearchCertificate] = useState<boolean>(true);
  const [scheduleAppointment, setScheduleAppointment] = useState<boolean>(true);

  const [successItems, setSuccessItems] = useState<IBulkUpdateCompanyItem[]>([]);
  const [processingItems, setProcessingItems] = useState<IBulkUpdateCompanyItem[]>([]);
  const [invalidCnpjItems, setInvalidCnpjItems] = useState<IBulkUpdateCompanyItem[]>([]);
  const [alreadyRegisteredItems, setAlreadyRegisteredItems] = useState<IBulkUpdateCompanyItem[]>([]);
  const [errorItems, setErrorItems] = useState<IBulkUpdateCompanyItem[]>([]);

  const [invalidCnpjItemsComplete, setInvalidCnpjItemsComplete] = useState<ICompanyBulk[]>([]);
  const [errorItemsComplete, setErrorItemsComplete] = useState<ICompanyBulk[]>([]);
  const [canceling, setCanceling] = useState<boolean>(false);

  const finishCompanyCreation = async () => {
    if(props.bulkUpdate) {
      await postFinishBulkCreateCompany(props.guid_client, props.bulkUpdate.guid_bulk_update, searchCertificate ? '1' : '0', scheduleAppointment ? '1' : '0');
      props.onClose();
    }
  }

  const cancelCompanyCreation = async () => {
    if(props.bulkUpdate) {
      setCanceling(true);
      await postCancelBulkCreateCompany(props.guid_client, props.bulkUpdate.guid_bulk_update);
      props.onClose();
      setCanceling(false);
    }
  }

  const reprocessCompanies = async () => {
    const mapToIBulkCompanyList = (item: ICompanyBulk, errorMessage: string | null = null): IBulkCompanyList => {
      return {
        name: item.name || null,
        cnpj: item.cnpj,
        type: item.type?.toString() || null,
        companyCode: null,
        stateReg: item.stateReg ? item.stateReg.replace(/\D/g, '') : null,
        cep: item.cep ? item.cep.replace(/\D/g, '') : null,
        state: item.state || null,
        city: item.city || null,
        guid_bulk_item_company: item.guid_bulk_item_company || undefined,
        errorMessage
      };
    };
    
    const combinedList: IBulkCompanyList[] = [
      ...invalidCnpjItemsComplete.map(item => mapToIBulkCompanyList(item, "Invalid CNPJ")),
      ...errorItemsComplete.map(item => mapToIBulkCompanyList(item, "Error"))
    ];
    
    // Create the final ICompanyBulkCreate object
    const companyBulkCreate: ICompanyBulkCreate = {
      companies: combinedList
    };
    await postReprocessBulkCreateCompany(props.guid_client, props.bulkUpdate?.guid_bulk_update || '', companyBulkCreate);
    props.onClose();
  }

  const closeModal = () => {
    if(processingItems.length === 0) {
      setOpenCancelModal(true);
    } else {
      props.onCloseWithToast();
    }
  }

  useEffect(() => {
    if(props.items && props.items.length > 0) {
      const success = props.items.filter(item => item.status === "SUCCESS");
      const invalidCnpj = props.items.filter(item => item.status === "FAILED" && item.error === "INVALID_CNPJ");
      const alreadyRegistered = props.items.filter(item => item.status === "FAILED" && item.error === "CNPJ_ALREADY_REGISTERED");
      const error = props.items.filter(item => item.status === "FAILED" && (item.error === "INTERNAL_ERROR" || item.error === "INTERNAL_ERROR_AUTOFILL"));
      const processing = props.items.filter(item => item.status === "PENDING" || item.status === "PENDING_IE");

      setProcessingItems(processing);
      setSuccessItems(success);
      setInvalidCnpjItems(invalidCnpj);
      setAlreadyRegisteredItems(alreadyRegistered);
      setErrorItems(error);
    }
  }, [props.items]);

  return (
    <>
      <ModalHeader fontFamily="Poppins-SemiBold">
        <Text color="#171923" fontSize="21px">Encontramos os seguintes registros</Text>
        <Text color="#4A5568" fontSize="14px">Verifique abaixo o resultado da nossa busca</Text>
      </ModalHeader>

      <Flex
        position="absolute"
        top="0.5rem"
        right="0.75rem"
        cursor="pointer"
        onClick={closeModal}
      >
        <Image src="/icons/close-modal.svg" />
      </Flex>

      <ModalBody>
        <Flex w="100%">
          <Accordion w="100%" allowToggle>
          { processingItems.length > 0 && 
              <AccordionItem className="accordion-item">  
                <h2> 
                  <AccordionButton className="accordion-button" p={4}>
                    <Box as="span" flex="1" textAlign="left">
                      <Flex alignItems="center" position="relative">
                        <Flex pl={4}>
                          <Text
                            fontFamily="Poppins-Medium"
                            fontSize={12}
                            color="#171923"
                          >
                            CNPJ em processamento
                          </Text>
                        </Flex>
                      </Flex>

                    </Box>

                    <Flex alignItems="center">
                      <Tag 
                        tag={{
                          textColor: "#292689",
                          color: "#DDE5FF",
                          text: "Processando"
                        } as ITag}
                        text="Processando"
                      />
                      <Text fontFamily="Poppins-Medium" fontSize={12} ml={2} mr={2}>{processingItems.length} empresas</Text>
                    </Flex>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Step3ConfirmationTable items={processingItems} />
                </AccordionPanel>
              </AccordionItem>
            }

            { successItems.length > 0 && 
              <AccordionItem className="accordion-item">  
                <h2> 
                  <AccordionButton className="accordion-button" p={4}>
                    <Box as="span" flex="1" textAlign="left">
                      <Flex alignItems="center" position="relative">
                        <Flex pl={4}>
                          <Text
                            fontFamily="Poppins-Medium"
                            fontSize={12}
                            color="#171923"
                          >
                            CNPJ validado com sucesso
                          </Text>
                        </Flex>
                      </Flex>

                    </Box>

                    <Flex alignItems="center">
                      <Tag 
                        tag={{
                          textColor: "#14532D",
                          color: "#DCFCE7",
                          text: "Sucesso"
                        } as ITag}
                        text="Sucesso"
                      />
                      <Text fontFamily="Poppins-Medium" fontSize={12} ml={2} mr={2}>{successItems.length} empresas</Text>
                    </Flex>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Step3ConfirmationTable items={successItems} />
                </AccordionPanel>
              </AccordionItem>
            }

            { alreadyRegisteredItems.length > 0 && 
              <AccordionItem className="accordion-item">  
                <h2> 
                  <AccordionButton className="accordion-button" p={4}>
                    <Box as="span" flex="1" textAlign="left">
                      <Flex alignItems="center" position="relative">
                        <Flex pl={4}>
                          <Text
                            fontFamily="Poppins-Medium"
                            fontSize={12}
                            color="#171923"
                          >
                            CNPJ já cadastrado
                          </Text>
                        </Flex>
                      </Flex>

                    </Box>

                    <Flex alignItems="center">
                      <Tag 
                        tag={{
                          textColor: "#713F12",
                          color: "#FEF9C3",
                          text: "Erro"
                        } as ITag}
                        text="Erro"
                      />
                      <Text fontFamily="Poppins-Medium" fontSize={12} ml={2} mr={2}>{alreadyRegisteredItems.length} empresas</Text>
                    </Flex>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Step3ConfirmationTable items={alreadyRegisteredItems} />
                </AccordionPanel>
              </AccordionItem>
            }

            { errorItems.length > 0 && 
              <AccordionItem className="accordion-item">  
                <h2> 
                  <AccordionButton className="accordion-button" p={4}>
                    <Box as="span" flex="1" textAlign="left">
                      <Flex alignItems="center" position="relative">
                        <Flex pl={4}>
                          <Text
                            fontFamily="Poppins-Medium"
                            fontSize={12}
                            color="#171923"
                          >
                            Não conseguimos encontrar dados. <span style={{color:"#382AD8"}}>Clique aqui</span> para completar
                          </Text>
                        </Flex>
                      </Flex>

                    </Box>

                    <Flex alignItems="center">
                      <Tag 
                        tag={{
                          textColor: "#713F12",
                          color: "#FEF9C3",
                          text: "Erro"
                        } as ITag}
                        text="Erro"
                      />
                      <Text fontFamily="Poppins-Medium" fontSize={12} ml={2} mr={2}>{errorItems.length} empresas</Text>
                    </Flex>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Step3ConfirmationTable
                    items={errorItems}
                    cnpjEditable={false}
                    allFieldsEditable={true}
                    setCompanies={setErrorItemsComplete}
                  />
                </AccordionPanel>
              </AccordionItem>
            }

            { invalidCnpjItems.length > 0 && 
              <AccordionItem className="accordion-item">  
                <h2> 
                  <AccordionButton className="accordion-button" p={4}>
                    <Box as="span" flex="1" textAlign="left">
                      <Flex alignItems="center" position="relative">
                        <Flex pl={4}>
                          <Text
                            fontFamily="Poppins-Medium"
                            fontSize={12}
                            color="#171923"
                          >
                            CNPJ inválido. <span style={{color:"#382AD8"}}>Clique aqui</span> para corrigir
                          </Text>
                        </Flex>
                      </Flex>

                    </Box>

                    <Flex alignItems="center">
                      <Tag 
                        tag={{
                          textColor: "#713F12",
                          color: "#FEF9C3",
                          text: "Erro"
                        } as ITag}
                        text="Erro"
                      />
                      <Text fontFamily="Poppins-Medium" fontSize={12} ml={2} mr={2}>{invalidCnpjItems.length} empresas</Text>
                    </Flex>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Step3ConfirmationTable
                    items={invalidCnpjItems}
                    cnpjEditable={true}
                    setCompanies={setInvalidCnpjItemsComplete}
                  />
                </AccordionPanel>
              </AccordionItem>
            }

            <Flex flexDirection="column" mt="25px">
              <Checkbox
                colorScheme="green"
                isChecked={searchCertificate}
                onChange={(event) => setSearchCertificate(event.target.checked)}
              >
                <Text
                  color="#171923"
                  fontFamily="Poppins-Medium"
                  fontSize="14px"
                >
                  Pesquisar Certidões ao terminar o cadastro
                </Text>
              </Checkbox>
              <Text
                fontFamily="Poppins-Medium"
                fontSize="12px"
                color="#4A5568"
                ml="23px"
              >
                Ao selecionar esta opção, o sistema irá realizar a busca das Certidões destas empresas ao término deste cadastro.
              </Text>
            </Flex>

            <Flex flexDirection="column" mt="15px">
              <Checkbox
                colorScheme="green"
                isChecked={scheduleAppointment}
                onChange={(event) => setScheduleAppointment(event.target.checked)}
              >
                <Text
                  color="#171923"
                  fontFamily="Poppins-Medium"
                  fontSize="14px"
                >
                  Agendar pesquisa recorrente das Certidões
                </Text>
              </Checkbox>
              <Text
                fontFamily="Poppins-Medium"
                fontSize="12px"
                color="#4A5568"
                ml="23px"
              >
                Ao selecionar esta opção, o sistema irá programar a atualização recorrente das Certidões destas empresas.
              </Text>
            </Flex>
          </Accordion>
        </Flex>
      </ModalBody>

      {
        invalidCnpjItems.length > 0 || errorItems.length > 0 ? (
          <ModalFooter>
            <Flex
              width="100%"
              justifyContent="end"
              alignItems="center"
              display="flex"
              fontFamily="Poppins-Medium"
            >
              <Text
                color="#171923"
                fontSize="12px"
                mr="15px"
                cursor={processingItems.length > 0 ? 'default' : 'pointer'}
                onClick={() => {if (processingItems.length === 0) setOpenConfirmModal(true)}}
              >
                Prosseguir com o cadastro mesmo assim
              </Text>

              <FormButton
                disabled={processingItems.length > 0}
                onClick={reprocessCompanies}
                width="auto"
              >
                Reprocessar empresas
              </FormButton> 
            </Flex>
          </ModalFooter>
        ) : (
          <ModalFooter>
            <Flex
              width="100%"
              justifyContent="end"
              alignItems="end"
              display="flex"
              fontFamily="Poppins-Medium"
            >
              <FormButton
                disabled={processingItems.length > 0}
                onClick={finishCompanyCreation}
                width="auto"
              >
                Finalizar cadastros
              </FormButton> 
            </Flex>
          </ModalFooter>
        )
      }

      {
        props.items ? (<>
          <Step3ConfirmationCancel
            isOpen={openCancelModal}
            setIsOpen={setOpenCancelModal}
            countCompanies={props.items.length}
            cancelBulkCreate={cancelCompanyCreation}
            canceling={canceling}
          />

          <Step3ConfirmationContinueRegister
            isOpen={openConfirmModal}
            setIsOpen={setOpenConfirmModal}
            countCompanies={successItems.length}
            confirmRegister={finishCompanyCreation}
          />
        </>) : null
      }
    </>
  );
}