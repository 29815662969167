import moment from 'moment';
import * as XLSX from 'xlsx';
import { useState } from 'react';
import { Image } from '@chakra-ui/react';
import { getDividaAtiva } from '../../../../_services/cnd.service';
import { IAction } from '../../../../components/Table/table.interface';
import { ICNDResponse } from '../../../../_services/interface/cnd.interface';

interface IProps {
  cnd: ICNDResponse;
  guid_client?: string;
}

export const CustomActions = (props: IProps) => {
  const actions: IAction[] = [];

  if (props.cnd.custom_buttons) {
    const buttons = props.cnd.custom_buttons.split(',');
    buttons.forEach((button) => {
      switch (button) {
        case 'divida_ativa_dados':
          actions.push(DividaAtivaDados(props.cnd, props.guid_client));
          break;
      }
    });
  }

  return actions;
};


enum EDividaAtivaStatus {
  new = 'Novo Débito',
  recurrency = 'Recorrente',
  finish = 'Baixado'
}

const DividaAtivaDados = (cnd: ICNDResponse, guid_client?: string): IAction => {

  if(!guid_client) return {
    text: 'Detalhes Divida Ativa',
    icon: <Image src="/icons/table/download.svg" width={5} height="auto" />,
    isVisible: () => false,
    isDisabled: () => true,
    action: () => {},
  };

  let loading = false;

  const getalueFromType = (jsonString: string, type: string): string|false => {
      if(jsonString === null || jsonString === undefined) return false;

      const json = JSON.parse(jsonString);
      for (let i = 0; i < json.length; i++) {
          if (json[i].tipo_receita === type) {
              return json[i].valor as string;
          }
      }

      return false;
  }
  
  const action = async () => {
    loading = true;
    if(!cnd.guid_company) return;
      
    const { status, response } = await getDividaAtiva(guid_client, cnd.guid_company);
      
    if (status === 200) {
      const headerMessage = [`Data de atualização da listagem geral de CDAs (sem detalhamento): ${cnd.createdAt ? moment(cnd.createdAt).utc(true).format('DD/MM/YYYY') : 'Não identificado'}`];
      const subHeaderMessages = [
          '','','','','','','','','','','','','','','','','','','','','','','','','Receitas do débito','','','','','','Natureza da Dívida / Origem',
      ]

      const header = [
        'Devedor',
        'CNPJ',
        'CDA',
        'Tipo',
        'Taxly: Classificação Status',
        'Taxly: Data de Identificação do CDA',
        'Taxly: Data de detalhamento dos CDAs',
        'Origem',
        'Etiqueta',
        'IE',
        'Observação',
        'CPF',
        'Data da Inscrição',
        'Número do Processo',
        'Número do Processo Judicial',
        'Número do Processo Outros',
        'Situação',
        'Saldo',
        'Aiim',
        'Partes Autor',
        'Partes Réu',
        'Comarca Foro',
        'Vara',
        'Natureza da Dívida',

        'Principal',
        'Correção Monetária',
        'Juros de Mora do Principal',
        'Multa de Mora do Principal',
        'Honorários Advocatícios',
        'Juros de Mora da Multa de Mora',
        
        'Placa',
        'Renavam',
        'Chassi',
        'Marca/Modelo',
        'Ano',
        'Ano de exercício',
        'Parcelas não pagas',
        'Data',
        'Valor',
        'Data de início dos juros',
        'Data da correção'
      ];

      const completeData: any = [headerMessage, subHeaderMessages, header];
      const newData: any = [headerMessage, subHeaderMessages, header];
      const finishData: any = [headerMessage, subHeaderMessages, header];

      response.forEach((item: any) => {
        let updatedAt = '';
        if (item.natureza_divida) {
          updatedAt = item.updatedAt ? moment.utc(item.updatedAt).format('DD/MM/YYYY') : item.consulta_datahora;
        }
        item.natureza_divida_referencias = item.natureza_divida_referencias ? JSON.parse(item.natureza_divida_referencias)[0] : {};

        const row = [
          item.devedor,
          item.cnpj,
          item.cda,
          item.tipo,
          item.status,
          moment.utc(item.createdAt).format('DD/MM/YYYY'),
          updatedAt,
          item.origem,
          item.etiqueta,
          item.inscricao_estadual,
          item.observacao,
          item.cpf,
          item.data_inscricao,
          item.numero_processo,
          item.numero_processo_judicial,
          item.numero_processo_outros,
          item.situacao,
          item.saldo,
          item.aiim,
          item.partes_autor,
          item.partes_reu,
          item.comarca_foro,
          item.vara,
          item.natureza_divida,

          getalueFromType(item.receitas_debito, 'Principal') || '',
          getalueFromType(item.receitas_debito, 'Correção Monetária') || '',
          getalueFromType(item.receitas_debito, 'Juros de Mora do Principal') || '',
          getalueFromType(item.receitas_debito, 'Multa de Mora do Principal') || '',
          getalueFromType(item.receitas_debito, 'Honorários Advocatícios') || '',
          getalueFromType(item.receitas_debito, 'Juros de Mora da Multa de Mora') || '',


          item.natureza_divida_referencias?.placa || '',
          item.natureza_divida_referencias?.renavam || '',
          item.natureza_divida_referencias?.chassi || '',
          item.natureza_divida_referencias?.marca_modelo || '',
          item.natureza_divida_referencias?.ano || '',
          item.natureza_divida_referencias?.ano_exercicio || '',
          item.natureza_divida_referencias?.parcelas_nao_pagas || '',
          item.natureza_divida_referencias?.data ? item.natureza_divida_referencias.data : '',
          item.natureza_divida_referencias?.valor || '',
          item.natureza_divida_referencias?.data_inicio_juros || '',
          item.natureza_divida_referencias?.data_inicio_correcao || '',
        ];

        completeData.push(row);
        if (item.status === EDividaAtivaStatus.new) {
          newData.push(row);
        }

        if (item.status === EDividaAtivaStatus.finish) {
          finishData.push(row);
        }
      });


      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet(completeData), 'Dívidas');
      XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet(newData), 'Novas Dívidas');
      XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet(finishData), 'Dívidas Baixadas');
      XLSX.writeFile(wb, `${cnd.entity_name}_${cnd.entity_document}_divida_ativa_PGE-SP_${moment().format('DD/MM/YYYY')}.xlsx`);
    }

    loading = false;
  };

  const isVisible = (cnd: ICNDResponse): boolean => {
    return true;
  }

  const isDisabled = (cnd: ICNDResponse): boolean => {
    return loading || !cnd.custom_data;
  }

  return {
    text: 'Detalhes Divida Ativa',
    icon: <Image src="/icons/table/download.svg" width={5} height="auto" />,
    isVisible,
    isDisabled,
    action,
  };
}