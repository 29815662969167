import { Box, Button, Flex, Image, Text, useToast } from "@chakra-ui/react";
import { useCallback, useEffect, useRef, useState } from "react";
import { IResponse } from "../../../_services/interface/user.interface";
import { getMacroCndData, listCnds } from "../../../_services/cnd.service";
import { ICNDListData, ICNDMacroData, IFilterListCertificate } from "../../../_services/interface/cnd.interface";
import useLoginFormStore from "../../../store/useLoginFormStore";
import { checkClientPermission } from "../Users/utils/checkPermission";
import { MultiCndDownload } from "./modals/CNDMultiDownload";
import { CloseIcon } from "@chakra-ui/icons";
import { ICertificate } from "../../../_services/interface/certificate.interface";
import { Socket } from 'socket.io-client';
import { DefaultEventsMap } from "@socket.io/component-emitter";
import { AddNewCNDModal } from "./modals/AddNewCNDModal";
import { RetrieveCertificates } from "./modals/RetrieveCertificate/RetrieveCertificates";
import { CNDManagerDashboard } from "./CNDManagerDashboard";
import { Filters, FilterDataSelected, FilterRow, FilterTypeEnum } from "../../../components/Filters";
import moment from "moment";
import {getClient} from "../../../_services/client.service";
import {IClientDataResponse, IClientResponse} from "../../../_services/interface/client.interface";
import { IAction, IColumn, IData, ITableMetaData } from "../../../components/Table/table.interface";
import { stateRegionForTag } from "../../../components/Table/utils";
import { Datatables } from "../../../components/Table/Datatables";
import { statusForTagList } from "./util/cndUtils";
import { ViewPdfModal } from "./modals/ViewPdfModal";
import { CertificateHistory } from "./modals/CertificateHistory";
import { SearchCndModal } from "./modals/SearchCNDModal";
import GroupRadius from "../../../components/GroupRadius";
import { CustomActions } from "./components/CustomButtons";
import debounce from 'lodash/debounce';
import { getCancelToken } from "../../../_services/cancelToken";
import BulkUpdateCertificates from "./components/BulkUpdate/BulkUpdateCertificates";

interface ICertificateManagerPanelProps {
  guid_client: string;
  certificates: ICertificate[];
  socket: Socket<DefaultEventsMap, DefaultEventsMap> | undefined;
  certificate?: ICertificate;
  allCertificates?: Boolean;
  validation: IResponse;
  jurisdiction?: 'federal'|'state'|'municipal'|null;
  location: string;
}

export const CertificateManagerPanel = (props: ICertificateManagerPanelProps) => {
  const { validate } = useLoginFormStore();
  const [currentClient, setCurrentClient] = useState<IClientDataResponse|null>(null);

  const toast = useToast();

  const ref = useRef<HTMLDivElement>(null);
  const toastRef = useRef<string | number | undefined>(undefined);
  
  const [isLoading, setLoadingState] = useState<boolean>(false);
  const [isChartLoading, setChartLoadingState] = useState<boolean>(false);

  const [certificateList, setCertificateList] = useState<IData<ICNDListData>|null>(null);
  const [certificateMacroData, setCertificateMacroData] = useState<ICNDMacroData|null>(null);
  const [metaData, setMetaData] = useState<ITableMetaData|undefined>();
  const [refreshData, setRefreshData] = useState<boolean>(false);


  const [selectedPersonTypeValues, setSelectedPersonTypeValues] = useState<string>('all');
  const [filterDataSelected, setFilterDataSelected] = useState<FilterDataSelected|null>(null);
  const [clearFilters, setClearFilters] = useState<boolean>(false);
  const [reinitFilter, setReinitFilter] = useState<boolean>(false);
  
  const [bulkUpdateStarted, setBulkUpdateStarted] = useState<boolean>(false);
  const [canShowRetrieve, setCanShowRetrieve] = useState<boolean>(false);

  const [hasProductCpf, setHasProductCpf] = useState<boolean>(false);

  const [currentFile, setCurrentFile] = useState<string>('');
  const [currentCertificate, setCurrentCertificate] = useState<ICNDListData | null>(null);

  const [openFileModal, setOpenFileModal] = useState<boolean>(false);
  const [openHistoryModal, setOpenHistoryModal] = useState<boolean>(false);
  const [openSearchCertificateModal, setOpenSearchCertificateModal] = useState<boolean>(false);

  useEffect(() => {
    validate?.clients.map((client: any) => {
      if (client.guid_client === props.guid_client) {
        setCurrentClient(client);
      }
    });
  }, [props.guid_client]);

  const activeWebsocket = () => {
    if(props.socket){
      props.socket.on('refreshCertificate', (message) => {
        if (message.type === 'refreshCertificate') {
          toast.closeAll();

          toast({
            isClosable: true,
            duration: 6000,
            status: 'success',
            render: ({ onClose }) => (
                <>
                  <Box color='white' pt={3} pb={3} pl={3} pr={1} bg='green.500' borderRadius={5} height="100%">
                    <Flex fontFamily="Poppins-Medium" justifyContent="space-between" mb={1} display="flex">
                      <Flex display="flex" flexDirection="column">
                        <Image src="/icons/check-white.svg" width="35px" />
                      </Flex>

                      <Flex display="flex" flexDirection="column">
                        <Text fontSize="14px" ml={2} fontWeight="bold">
                          A pesquisa foi concluída com sucesso. Você acaba de economizar 02 minutos de trabalho!
                        </Text>

                        <Text ml={2}>
                    <span
                        onClick={() => {
                          onClose();
                          fetchListCndData();
                          if (props.certificate) {
                            fetchMacroCndData();
                          }
                        }}
                        style={{cursor: 'pointer'}}
                    >
                      <u>Clique aqui</u>
                    </span> para atualizar a página e visualizar as novas certidões.
                        </Text>
                      </Flex>

                      <Button
                          margin="-15px -10px 0 0"
                          onClick={onClose}
                          variant="ghost"
                          _hover={{bg: 'none'}}
                      >
                        <CloseIcon w={2.5} h={2.5}/>
                      </Button>
                    </Flex>
                  </Box>
                </>
            )
          });
        }
      });
    }
  };

  useEffect(() => {
    if(props.socket || (props.socket && refreshData)) {
      props.socket.off('refreshCertificate');
      activeWebsocket();
    }
    // eslint-disable-next-line
  }, [props.socket, refreshData]);

  useEffect(() => {
    setClearFilters(true);
  }, [props.certificate]);

  useEffect(() => {
    if (clearFilters) {
      setClearFilters(false);
    }
  }, [clearFilters]);

  useEffect(() => {
    setReinitFilter(true);
  }, [selectedPersonTypeValues, hasProductCpf, props.jurisdiction, props.certificate]);

  useEffect(() => {
    if (reinitFilter) {
      setReinitFilter(false);
    }
  }, [reinitFilter]);

  const debouncedRefreshData = useCallback(debounce(() => setRefreshData(true), 400), []);
  useEffect(() => {
    debouncedRefreshData();
  }, [
    metaData,
    props.certificate,
    props.jurisdiction,
    props.guid_client,
    filterDataSelected,
    selectedPersonTypeValues,
  ]);

  useEffect(() => {
    if (refreshData) {
      //Refresh the company
      fetchListCndData();
      if (props.certificate) {
        fetchMacroCndData();
      }
      setRefreshData(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshData]);

  useEffect(() => {
    configClientProductsCpf();
  }, [props.certificate]);

  useEffect(() => {
    if (bulkUpdateStarted) {
      setBulkUpdateStarted(false);
    }
  }, [bulkUpdateStarted]);

  const getFilter = (): IFilterListCertificate => {

    const validFilterField = (field: string | string[] | undefined): boolean => {
      if (field) {
        if (typeof field === 'string') {
          if (field.trim() !== '') {
            return true;
          }
        } else if (field instanceof Array) {
          if (field.length > 0) {
            return true;
          }
        }
      }
      return false;
    }

    const getValidUntilDates = (validUntilRules: string[]) => {

      let startDueDate;
      let endDueDate;

      if (validUntilRules.length > 0) {
        switch (validUntilRules[0]) {
          case "not-valid":
            return { 
              startDueDate: startDueDate, 
              endDueDate: moment.utc().format('YYYY-MM-DD')
            };
          case "15-days":
            return { 
              startDueDate: moment.utc().format('YYYY-MM-DD'), 
              endDueDate: moment.utc().add(15, 'days').format('YYYY-MM-DD')
            };
          case "15-days-more":
            return { 
              startDueDate: moment.utc().add(15, 'days').format('YYYY-MM-DD'), 
              endDueDate: endDueDate 
            };
          case "30-days-more":
            return { 
              startDueDate: moment.utc().add(30, 'days').format('YYYY-MM-DD'), 
              endDueDate: endDueDate
            };
          case "45-days-more":
            return { 
              startDueDate: moment.utc().add(45, 'days').format('YYYY-MM-DD'), 
              endDueDate: endDueDate
            };
          case "60-days-more":
            return { 
              startDueDate: moment.utc().add(60, 'days').format('YYYY-MM-DD'), 
              endDueDate: endDueDate
            };
          case "90-days-more":
            return { 
              startDueDate: moment.utc().add(90, 'days').format('YYYY-MM-DD'), 
              endDueDate: endDueDate
            };
          case "120-days-more":
            return { 
              startDueDate: moment.utc().add(120, 'days').format('YYYY-MM-DD'), 
              endDueDate: endDueDate
            };
          default:
            return {startDueDate: startDueDate, endDueDate: endDueDate};
        }
      }
      return {startDueDate: startDueDate, endDueDate: endDueDate};
    }

    const companyValues: string[] = [];
    const personaValues: string[] = [];

    if(filterDataSelected) {
      if(selectedPersonTypeValues == 'all' && filterDataSelected[FilterTypeEnum.holderFilter]) {
        filterDataSelected[FilterTypeEnum.holderFilter].forEach((entity) => {
          if (entity.includes('company_')) {
            companyValues.push(entity.replace('company_', ''));
          } else if (entity.includes('persona_')) {
            personaValues.push(entity.replace('persona_', ''));
          }
        });
      } 

      if(selectedPersonTypeValues == 'persona' && filterDataSelected[FilterTypeEnum.personaNameFilter]) {
        filterDataSelected[FilterTypeEnum.personaNameFilter].forEach((entity) => {
          personaValues.push(entity);
        });
      } 

      if(selectedPersonTypeValues === 'company' && filterDataSelected[FilterTypeEnum.companyFilter]) {
        filterDataSelected[FilterTypeEnum.companyFilter].forEach((entity) => {
          companyValues.push(entity);
        });
      }
    }

    const companyGroups: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.companyGroupFilter] : [];
    let companyTypes: string[] = [];

    companyTypes = filterDataSelected ? filterDataSelected[FilterTypeEnum.companyTypeFilter] : [];
    if(companyTypes.length === 0) {
      companyTypes = filterDataSelected ? filterDataSelected[FilterTypeEnum.companyOrPersonaTypeFilter] : [];
    }
    const companyCodes: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.companyCodeFilter] : [];

    let statuses: string[] = [];
    if(currentClient && currentClient.customStatusForCertificate) {
      statuses = filterDataSelected ? filterDataSelected[FilterTypeEnum.certificateStatusFilter] : [];
    }

    let statusesValidInvalid: string[] = [];
    if(currentClient && !currentClient.customStatusForCertificate) {
      statusesValidInvalid = filterDataSelected ? filterDataSelected[FilterTypeEnum.certificateStatusValidInvalidFilter] : [];
    }


    let certificateTypes: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.certificateTypeFilter] : [];
    if(props.certificate) {
      certificateTypes = [props.certificate.slug];
    }
    const {startDueDate, endDueDate} = getValidUntilDates(filterDataSelected ? filterDataSelected[FilterTypeEnum.validUntilFilter] : []);

    let jurisdictions: string[] = [];
    if(props.jurisdiction !== null && props.jurisdiction !== undefined) {
      jurisdictions = [props.jurisdiction];
    } else {
      jurisdictions = filterDataSelected ? filterDataSelected[FilterTypeEnum.jurisdictionFilter] : [];
    }

    const states: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.certificateStateFilter] : [];
    const cities: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.certificateCityFilter] : [];
    const searchTypes: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.searchTypeFilter] : [];

    const filters: IFilterListCertificate = {
      page: Number(metaData?.currentPage) ?? 1,
      row_by_page: Number(metaData?.rowByPage) ?? -1,
      order_field: metaData?.orderField ?? 'createdAt',
      order_direction: metaData?.orderDirection ?? 'desc',
      ...(validFilterField(companyValues) && selectedPersonTypeValues !== 'persona' ? { companies: companyValues.join(',') } : {}),
      ...(validFilterField(personaValues) && selectedPersonTypeValues !== 'company' ? { personas: personaValues.join(',') } : {}),
      ...(validFilterField(companyGroups) && selectedPersonTypeValues !== 'persona' ? { company_group: companyGroups.join(',') } : {}),
      ...(validFilterField(companyTypes) ? { company_types: companyTypes.join(',') } : {}),
      ...(validFilterField(companyCodes) ? { company_code: companyCodes.join(',') } : {}),

      ...(validFilterField(certificateTypes) ? { slugs: certificateTypes.join(',') } : {}),
      ...(validFilterField(startDueDate) ? { initial_due_date: startDueDate } : {}),
      ...(validFilterField(endDueDate) ? { final_due_date: endDueDate } : {}),
      
      ...(validFilterField(jurisdictions) ? { jurisdiction: jurisdictions.join(',') } : {}),
      ...(validFilterField(states) ? { id_states: states.join(',') } : {}),
      ...(validFilterField(cities) ? { id_cities: cities.join(',') } : {}),
      ...(validFilterField(searchTypes)   ? { is_manual: searchTypes.join(',') } : {}),
      ...(validFilterField(selectedPersonTypeValues) ? { persona_type: selectedPersonTypeValues } : {}),
      ...(validFilterField(statusesValidInvalid) ? { statusesValidInvalid: statusesValidInvalid.join(',') } : {}),
    };

    if(validFilterField(statuses)) {
      const statusValues: string[] = []; 
      statuses.forEach((status) => {
        const statusSplited = status.split('_');
        const stat = statusSplited[statusSplited.length - 1];

        if (!statusValues.some((s) => s === stat)) {
          statusValues.push(stat);
        }
      });

      const certificateValues: string[] = [];
      statuses.forEach((status) => {
        const statusName = status.split('_')[0];

        let certs = props.certificates.map((c) => c.slug);
        if (certificateTypes.length > 0) {
          certs = certificateTypes;
        }

        certs.forEach((cert) => {
          const c = props.certificates.find((c) => c.slug === cert);

          if (c?.status?.some((s) => s.name === statusName) && !certificateValues.some((c) => c === cert)) {
            certificateValues.push(cert);
          }
        });
      });

      filters.status = statusValues.join(',');
      if (validFilterField(certificateValues)) {
        filters.slugs = certificateValues.join(',');
      }
    }

    return filters;
  }

  const toastError = () => {
    if (toastRef.current) {
      toast.close(toastRef.current);
    }

    const toastId = toast({
      title: 'Ocorreu um erro',
      description: 'Ocorreu um erro ao tentar puxar as informações das certidões',
      status: 'error',
      duration: 5000,
      isClosable: true
    });

    toastRef.current = toastId;
  }

  const getAllCNDs = async (guid_client: string): Promise<ICNDListData[]> => {
    let allCnds = [] as ICNDListData[];
    let filters = getFilter();
    filters.page = 1;
    filters.row_by_page = -1;
    
    const { status, response } = await listCnds(guid_client, filters);

    if (status === 200 && 'meta' in response && 'data' in response) {
      if (response.meta.pageCount !== undefined && response.data !== undefined) {
        allCnds = response.data;
      }
    } else if (status) {
      toastError();
    }

    return allCnds;
  }

  const fetchListCndData = async () => {
    if (props.guid_client) {
      setLoadingState(true);    
      const filters = getFilter();
      const { status, response } = await listCnds(props.guid_client, filters, getCancelToken('certificate.fetchListCndData'));

      if (status && status === 200 && 'meta' in response && 'data' in response) {
        if (response.meta.pageCount !== undefined && response.data !== undefined) {
          setCertificateList(response);
        }
      }
      setTimeout(() => {
        setLoadingState(false);
      }, 1000);
    }
  }

  const fetchMacroCndData = async () => {
    if (props.guid_client) {
      setChartLoadingState(true);

      const filters = getFilter();
      const { status, response } = await getMacroCndData(props.guid_client, filters, getCancelToken('certificate.fetchMacroCndData'));

      if (status && status === 200) {
        setCertificateMacroData(response);
      } else if (status)  {
        toastError();
      }
      setTimeout(() => {
        setChartLoadingState(false);
      }, 1000);
    }
  }

  let filterRowsCertificate: FilterRow[] = [];
  let filterRowsCertificates: FilterRow[] = [];

  let lineWithJurisdiction = {
    rowFields: [
      {
        name: FilterTypeEnum.jurisdictionFilter,
        position: 1,
        size: 6,
      },
      {
        name: FilterTypeEnum.certificateStateFilter,
        position: 2,
        size: 3,
      },
      {
        name: FilterTypeEnum.certificateCityFilter,
        position: 3,
        size: 3,
      },
    ],
    position: 3
  };

  if(props.jurisdiction !== undefined && props.jurisdiction !== null) {
    switch (props.jurisdiction) {
      case 'federal':
        lineWithJurisdiction = {rowFields: [], position: 3};
        break;

      case 'state':
        lineWithJurisdiction = {
          rowFields: [
            {
              name: FilterTypeEnum.certificateStateFilter,
              position: 1,
              size: 12,
            },
          ],
          position: 3
        };
        break;

      case 'municipal':
        lineWithJurisdiction = {
          rowFields: [
            {
              name: FilterTypeEnum.certificateStateFilter,
              position: 1,
              size: 6,
            },
            {
              name: FilterTypeEnum.certificateCityFilter,
              position: 2,
              size: 6,
            },
          ],
          position: 3
        };
        break;
    
      default:
        break;
    }
  }

  if(selectedPersonTypeValues === 'persona' && hasProductCpf) {
    filterRowsCertificate = [
      {
        rowFields: [
          {
            name: FilterTypeEnum.personaNameFilter,
            position: 1,
            size: 4,
          },
        ],
        position: 1
      },
      {
        rowFields: [
          {
            name: currentClient?.customStatusForCertificate ? FilterTypeEnum.certificateStatusFilter : FilterTypeEnum.certificateStatusValidInvalidFilter,
            position: 1,
            size: 6,
          },
          {
            name: FilterTypeEnum.validUntilFilter,
            position: 2,
            size: 4,
          },
          {
            name: FilterTypeEnum.searchTypeFilter,
            position: 3,
            size: 2,
          },
        ],
        position: 2
      }
    ] as FilterRow[];

    filterRowsCertificates = [
      {
        rowFields: [
          {
            name: FilterTypeEnum.personaNameFilter,
            position: 1,
            size: 4,
          },
        ],
        position: 1
      },
      {
        rowFields: [
          {
            name: FilterTypeEnum.certificateTypeFilter,
            position: 1,
            size: 2,
          },
          {
            name: currentClient?.customStatusForCertificate ? FilterTypeEnum.certificateStatusFilter : FilterTypeEnum.certificateStatusValidInvalidFilter,
            position: 2,
            size: 4,
          },
          {
            name: FilterTypeEnum.validUntilFilter,
            position: 3,
            size: 4,
          },
          {
            name: FilterTypeEnum.searchTypeFilter,
            position: 4,
            size: 2,
          },
        ],
        position: 2
      }
    ] as FilterRow[];

    if(lineWithJurisdiction.rowFields.length > 0) {
      filterRowsCertificate.push(lineWithJurisdiction);
      filterRowsCertificates.push(lineWithJurisdiction);
    }
  } else if(selectedPersonTypeValues === 'company' || !hasProductCpf) {
    filterRowsCertificate = [
      {
        rowFields: [
          {
            name: FilterTypeEnum.companyFilter,
            position: 1,
            size: 6,
          },
          {
            name: FilterTypeEnum.companyGroupFilter,
            position: 2,
            size: 2,
          },
          {
            name: FilterTypeEnum.companyTypeFilter,
            position: 3,
            size: 2,
          },
          {
            name: FilterTypeEnum.companyCodeFilter,
            position: 4,
            size: 2,
          },
        ],
        position: 1
      },
      {
        rowFields: [
          {
            name: currentClient?.customStatusForCertificate ? FilterTypeEnum.certificateStatusFilter : FilterTypeEnum.certificateStatusValidInvalidFilter,
            position: 1,
            size: 6,
          },
          {
            name: FilterTypeEnum.validUntilFilter,
            position: 2,
            size: 4,
          },
          {
            name: FilterTypeEnum.searchTypeFilter,
            position: 3,
            size: 2,
          },
        ],
        position: 2
      }
    ] as FilterRow[];

    filterRowsCertificates = [
      {
        rowFields: [
          {
            name: FilterTypeEnum.companyFilter,
            position: 1,
            size: 6,
          },
          {
            name: FilterTypeEnum.companyGroupFilter,
            position: 2,
            size: 2,
          },
          {
            name: FilterTypeEnum.companyTypeFilter,
            position: 3,
            size: 2,
          },
          {
            name: FilterTypeEnum.companyCodeFilter,
            position: 4,
            size: 2,
          },
        ],
        position: 1
      },
      {
        rowFields: [
          {
            name: FilterTypeEnum.certificateTypeFilter,
            position: 1,
            size: 2,
          },
          {
            name: currentClient?.customStatusForCertificate ? FilterTypeEnum.certificateStatusFilter : FilterTypeEnum.certificateStatusValidInvalidFilter,
            position: 2,
            size: 4,
          },
          {
            name: FilterTypeEnum.validUntilFilter,
            position: 3,
            size: 4,
          },
          {
            name: FilterTypeEnum.searchTypeFilter,
            position: 4,
            size: 2,
          },
        ],
        position: 2
      }
    ] as FilterRow[];

    if(lineWithJurisdiction.rowFields.length > 0) {
      filterRowsCertificate.push(lineWithJurisdiction);
      filterRowsCertificates.push(lineWithJurisdiction);
    }
  } else {

    filterRowsCertificate = [
      {
        rowFields: [
          {
            name: FilterTypeEnum.holderFilter,
            position: 1,
            size: 6,
          },
          {
            name: FilterTypeEnum.companyGroupFilter,
            position: 2,
            size: 2,
          },
          {
            name: FilterTypeEnum.companyOrPersonaTypeFilter,
            position: 3,
            size: 2,
          },
          {
            name: FilterTypeEnum.companyCodeFilter,
            position: 4,
            size: 2,
          },
        ],
        position: 1
      },
      {
        rowFields: [
          {
            name: currentClient?.customStatusForCertificate ? FilterTypeEnum.certificateStatusFilter : FilterTypeEnum.certificateStatusValidInvalidFilter,
            position: 1,
            size: 6,
          },
          {
            name: FilterTypeEnum.validUntilFilter,
            position: 2,
            size: 4,
          },
          {
            name: FilterTypeEnum.searchTypeFilter,
            position: 3,
            size: 2,
          },
        ],
        position: 2
      }
    ] as FilterRow[];

    filterRowsCertificates = [
      {
        rowFields: [
          {
            name: FilterTypeEnum.holderFilter,
            position: 1,
            size: 6,
          },
          {
            name: FilterTypeEnum.companyGroupFilter,
            position: 2,
            size: 2,
          },
          {
            name: FilterTypeEnum.companyOrPersonaTypeFilter,
            position: 3,
            size: 2,
          },
          {
            name: FilterTypeEnum.companyCodeFilter,
            position: 4,
            size: 2,
          },
        ],
        position: 1
      },
      {
        rowFields: [
          {
            name: FilterTypeEnum.certificateTypeFilter,
            position: 1,
            size: 2,
          },
          {
            name: currentClient?.customStatusForCertificate ? FilterTypeEnum.certificateStatusFilter : FilterTypeEnum.certificateStatusValidInvalidFilter,
            position: 2,
            size: 4,
          },
          {
            name: FilterTypeEnum.validUntilFilter,
            position: 3,
            size: 4,
          },
          {
            name: FilterTypeEnum.searchTypeFilter,
            position: 4,
            size: 2,
          },
        ],
        position: 2
      }
    ] as FilterRow[];

    if(lineWithJurisdiction.rowFields.length > 0) {
      filterRowsCertificate.push(lineWithJurisdiction);
      filterRowsCertificates.push(lineWithJurisdiction);
    }
  }

  const configClientProductsCpf = async () => {
    if (props.guid_client) {
      const { status, response } = await getClient(props.guid_client);

      if (status === 200 && response) {
        const client = response as IClientResponse;

        const getClientProductCpf = JSON.parse(client.products_cpf);

        if ((getClientProductCpf && Object.values(getClientProductCpf).includes(true) && !props.certificate)
            || (
                props.certificate?.slug
                && getClientProductCpf?.hasOwnProperty(props.certificate.slug as string)
                && getClientProductCpf[props.certificate?.slug as string] === true
            )
        ){
          setHasProductCpf(true);
        } else {
          setHasProductCpf(false);
          setSelectedPersonTypeValues('company');
        }
      }
    }
  }

  const haveFileAvailable = (cnd: ICNDListData): boolean => {
    const status = cnd.status;

    if(cnd.guid_file && status && status.show_file && cnd.slug_status !== 'error') {
      return true;
    }

    return false;
  }

  let orderNameColumnStatus = '';
  if(currentClient?.displayLastValidCertificate) {
    orderNameColumnStatus += 'cndLastValid.';
  }

  if(currentClient?.customStatusForCertificate) {
    orderNameColumnStatus += 'slug_status,slug_status';
  } else {
    orderNameColumnStatus += 'isIssued,isIssued';
  }

  let columns: IColumn[] = [];
  const colunsForTypeCertificate = {
    title: 'Certidão',
    name: 'certificate_name',
    sortable: false,
    align: 'center',
  };
  switch (selectedPersonTypeValues) {
    case 'all':
      columns = [
        ...(!props.certificate ? [colunsForTypeCertificate] : []),
        {
          title: 'Status',
          name: 'status.name',
          orderFieldName: orderNameColumnStatus,
          sortable: true,
          align: 'center',
          dynamicTag: {
            function: statusForTagList
          },
        },
        {
          title: 'Titular',
          name: 'entity.displayName',
          orderFieldName: 'company.name,persona.name',
          sortable: true,
          align: 'center',
          hyperlink: (row: ICNDListData) => {
            setCurrentCertificate(row);
            setOpenHistoryModal(true);
          }
        },
        {
          title: 'Tipo',
          name: 'entity.type',
          orderFieldName: 'company.type',
          sortable: true,
          align: 'center',
          tags: [
            {
              text: 'Matriz',
              value: 0,
              textColor: '#365314',
              color: '#ECFCCB',
            },
            {
              text: 'Filial',
              value: 1,
              textColor: '#065666',
              color: '#C4F1F9',
            },
            {
              text: 'Pessoa Física',
              value: 3,
              textColor: '#134E4A',
              color: '#CCFBF1',
            },
          ],
        },
        {
          title: 'CNPJ/CPF',
          name: 'entity.documentFormatted',
          orderFieldName: 'company.cnpj,persona.document',
          sortable: true,
          align: 'center',
          icon: (row: ICNDListData) => {
            if(!row.errorMessage) {
              return {
                tooltipText: '',
                iconUrl: '',
                show: false
              }
            }

            return {
              tooltipText: row.errorMessage,
              iconUrl: '/icons/alert.svg',
              show: true  
            }
          }
        },
        {
          title: 'Grupo',
          name: 'groups_names',
          sortable: false,
          align: 'center',
        },
        {
          title: 'Emissão',
          name: 'issuedAtFormatted',
          orderFieldName: currentClient?.displayLastValidCertificate ? 'cndLastValid.issuedAt,issuedAt' : 'issuedAt',
          sortable: true,
          align: 'center',
        },
        {
          title: 'Vencimento',
          name: 'validUntilFormatted',
          orderFieldName: currentClient?.displayLastValidCertificate ? 'cndLastValid.validUntil,validUntil' : 'validUntil',
          sortable: true,
          align: 'center',
        },
        {
          title: 'Prazo',
          name: 'displayDeadline',
          orderFieldName: currentClient?.displayLastValidCertificate ? 'cndLastValid.validUntil,validUntil' : 'validUntil',
          sortable: true,
          align: 'center',
          textColor: (row: ICNDListData) => {
            if(row.deadline && row.deadline < 30) {
              return '#fc1616';
            }
            return '#171923';
          }
        },
        {
          title: 'Jurisdição',
          name: 'jurisdiction',
          sortable: true,
          align: 'center',
          tags: [
            {
              text: 'Federal',
              value: 'federal',
              textColor: '#065666',
              color: '#C4F1F9',
            },
            {
              text: 'Municipal',
              value: 'municipal',
              textColor: '#134E4A',
              color: '#CCFBF1',
            },
            {
              text: 'Estadual',
              value: 'state',
              textColor: '#322659',
              color: '#E9D8FD',
            },
          ],
        },
        {
          title: 'Estado',
          name: 'state.initials',
          sortable: true,
          align: 'center',
          dynamicTag: {
            function: stateRegionForTag,
            field: 'state.initials'
          },
        },
        {
          title: 'Cidade',
          name: 'city.name',
          sortable: true,
          align: 'center',
          tags: [
            { 
              color: '#E2E8F0',
              textColor: '#1A202C'
            },
          ]
        },
        
      ] as IColumn[];
      break;

    case 'company':
      columns = [
        ...(!props.certificate ? [colunsForTypeCertificate] : []),
        {
          title: 'Status',
          name: 'status.name',
          orderFieldName: orderNameColumnStatus,
          sortable: true,
          align: 'center',
          dynamicTag: {
            function: statusForTagList
          },
        },
        {
          title: 'Empresa - cód',
          name: 'entity.displayName',
          orderFieldName: 'company.name',
          sortable: true,
          align: 'center',
          hyperlink: (row: ICNDListData) => {
            setCurrentCertificate(row);
            setOpenHistoryModal(true);
          }
        },
        {
          title: 'Tipo',
          name: 'entity.type',
          orderFieldName: 'company.type',
          sortable: true,
          align: 'center',
          tags: [
            {
              text: 'Matriz',
              value: 0,
              textColor: '#365314',
              color: '#ECFCCB',
            },
            {
              text: 'Filial',
              value: 1,
              textColor: '#065666',
              color: '#C4F1F9',
            },
            {
              text: 'Pessoa Física',
              value: 3,
              textColor: '#134E4A',
              color: '#CCFBF1',
            },
          ],
        },
        {
          title: 'CNPJ',
          name: 'entity.documentFormatted',
          orderFieldName: 'company.cnpj',
          sortable: true,
          align: 'center',
          icon: (row: ICNDListData) => {
            if(!row.errorMessage) {
              return {
                tooltipText: '',
                iconUrl: '',
                show: false
              }
            }

            return {
              tooltipText: row.errorMessage,
              iconUrl: '/icons/alert.svg',
              show: true  
            }
          }
        },
        {
          title: 'Grupo',
          name: 'groups_names',
          sortable: true,
          align: 'center',
        },
        {
          title: 'Emissão',
          name: 'issuedAtFormatted',
          orderFieldName: currentClient?.displayLastValidCertificate ? 'cndLastValid.issuedAt,issuedAt' : 'issuedAt',
          sortable: true,
          align: 'center',
        },
        {
          title: 'Vencimento',
          name: 'validUntilFormatted',
          orderFieldName: currentClient?.displayLastValidCertificate ? 'cndLastValid.validUntil,validUntil' : 'validUntil',
          sortable: true,
          align: 'center',
        },
        {
          title: 'Prazo',
          name: 'displayDeadline',
          orderFieldName: currentClient?.displayLastValidCertificate ? 'cndLastValid.validUntil,validUntil' : 'validUntil',
          sortable: true,
          align: 'center',
          textColor: (row: ICNDListData) => {
            if(row.deadline && row.deadline < 30) {
              return '#fc1616';
            }
            return '#171923';
          }
        },
        {
          title: 'Jurisdição',
          name: 'jurisdiction',
          sortable: true,
          align: 'center',
          tags: [
            {
              text: 'Federal',
              value: 'federal',
              textColor: '#065666',
              color: '#C4F1F9',
            },
            {
              text: 'Municipal',
              value: 'municipal',
              textColor: '#134E4A',
              color: '#CCFBF1',
            },
            {
              text: 'Estadual',
              value: 'state',
              textColor: '#322659',
              color: '#E9D8FD',
            },
          ],
        },
        {
          title: 'Estado',
          name: 'state.initials',
          sortable: true,
          align: 'center',
          dynamicTag: {
            function: stateRegionForTag,
            field: 'state.initials'
          },
        },
        {
          title: 'Cidade',
          name: 'city.name',
          sortable: true,
          align: 'center',
          tags: [
            { 
              color: '#E2E8F0',
              textColor: '#1A202C'
            },
          ]
        },
        
      ] as IColumn[];
      break;

    case 'persona':
      columns = [
        ...(!props.certificate ? [colunsForTypeCertificate] : []),
        {
          title: 'Status',
          name: 'status.displayName',
          orderFieldName: orderNameColumnStatus,
          sortable: true,
          align: 'center',
          dynamicTag: {
            function: statusForTagList
          },
        },
        {
          title: 'Nome',
          name: 'entity.name',
          orderFieldName: 'persona.name',
          sortable: true,
          align: 'center',
          hyperlink: (row: ICNDListData) => {
            setCurrentCertificate(row);
            setOpenHistoryModal(true);
          }
        },
        {
          title: 'Tipo',
          name: 'entity.type',
          orderFieldName: 'company.type',
          sortable: true,
          align: 'center',
          tags: [
            {
              text: 'Matriz',
              value: 0,
              textColor: '#365314',
              color: '#ECFCCB',
            },
            {
              text: 'Filial',
              value: 1,
              textColor: '#065666',
              color: '#C4F1F9',
            },
            {
              text: 'Pessoa Física',
              value: 3,
              textColor: '#134E4A',
              color: '#CCFBF1',
            },
          ],
        },
        {
          title: 'CPF',
          name: 'entity.documentFormatted',
          orderFieldName: 'persona.document',
          sortable: true,
          align: 'center',
          icon: (row: ICNDListData) => {
            if(!row.errorMessage) {
              return {
                tooltipText: '',
                iconUrl: '',
                show: false
              }
            }

            return {
              tooltipText: row.errorMessage,
              iconUrl: '/icons/alert.svg',
              show: true  
            }
          }
        },
        {
          title: 'Emissão',
          name: 'issuedAtFormatted',
          orderFieldName: currentClient?.displayLastValidCertificate ? 'cndLastValid.issuedAt,issuedAt' : 'issuedAt',
          sortable: true,
          align: 'center',
        },
        {
          title: 'Vencimento',
          name: 'validUntilFormatted',
          orderFieldName: currentClient?.displayLastValidCertificate ? 'cndLastValid.validUntil,validUntil' : 'validUntil',
          sortable: true,
          align: 'center',
        },
        {
          title: 'Prazo',
          name: 'displayDeadline',
          orderFieldName: currentClient?.displayLastValidCertificate ? 'cndLastValid.validUntil,validUntil' : 'validUntil',
          sortable: true,
          align: 'center',
          textColor: (row: ICNDListData) => {
            if(row.deadline && row.deadline < 30) {
              return '#fc1616';
            }
            return '#171923';
          }
        },
        {
          title: 'Jurisdição',
          name: 'jurisdiction',
          sortable: true,
          align: 'center',
          tags: [
            {
              text: 'Federal',
              value: 'federal',
              textColor: '#065666',
              color: '#C4F1F9',
            },
            {
              text: 'Municipal',
              value: 'municipal',
              textColor: '#134E4A',
              color: '#CCFBF1',
            },
            {
              text: 'Estadual',
              value: 'state',
              textColor: '#322659',
              color: '#E9D8FD',
            },
          ],
        },
        {
          title: 'Estado',
          name: 'state.initials',
          sortable: true,
          align: 'center',
          dynamicTag: {
            function: stateRegionForTag,
            field: 'state.initials'
          },
        },
        {
          title: 'Cidade',
          name: 'city.name',
          sortable: true,
          align: 'center',
          tags: [
            { 
              color: '#E2E8F0',
              textColor: '#1A202C'
            },
          ]
        },
        
      ] as IColumn[];
      break;
  }

  const actions = [
    {
      text: 'Visualizar arquivo',
      icon: <Image src="/icons/table/eye.svg" width={5} height="auto" />,
      isVisible: (cnd: ICNDListData): boolean => {return true},
      isDisabled: (cnd: ICNDListData): boolean => { return !haveFileAvailable(cnd) },
      action: (cnd: ICNDListData) => {
        setCurrentFile(cnd.file||'');
        setOpenFileModal(true);
      },
    },
    {
      text: 'Histórico de pesquisa',
      icon: <Image src="/icons/table/clock.svg" width={5} height="auto" />,
      isVisible: (cnd: ICNDListData): boolean => {return true},
      isDisabled: (cnd: ICNDListData): boolean => { return false },
      action: (cnd: ICNDListData) => {
        setCurrentCertificate(cnd);
        setOpenHistoryModal(true);
      },
    },
    {
      text: 'Pesquisar certidão',
      icon: <Image src="/icons/table/refresh.svg" width={5} height="auto" />,
      isVisible: (cnd: ICNDListData): boolean => {return true},
      isDisabled: (cnd: ICNDListData): boolean => { return !checkClientPermission(validate as IResponse,props.guid_client,"cnd.write") || !cnd.canSearchCert },
      action: (cnd: ICNDListData) => {
        setCurrentCertificate(cnd);
        setOpenSearchCertificateModal(true);
      },
    },
  ] as IAction[];

  return (<>
    <Flex ref={ref} direction="column" w="100%" p="0" flexGrow="1" flexShrink="1" pr="30px" mt="10px">
      <Flex flexDirection="row" justifyContent="space-between" borderBottom="1px" borderBottomColor="gray.300" pb={4}>
        <Text fontSize="18px" mt={8} fontFamily="Poppins-Medium">
          {props.certificate ? `Gerenciador de Certidões - ${props.certificate.name}` : `Visão Geral`}
        </Text>
        <Flex gap={2}>
          {props.certificate && !isLoading && checkClientPermission(validate as IResponse, props.guid_client, 'cnd.write') ? (
              <AddNewCNDModal
                type={props.certificate.slug}
                certificate={props.certificate}
                guid_client={props.guid_client}
                flushHook={setRefreshData}
                entityType={selectedPersonTypeValues}
              />
          ) : null}
          {!isLoading && checkClientPermission(validate as IResponse, props.guid_client, 'cnd.read') ? (
            <MultiCndDownload certificateName={props.certificate ? props.certificate.name as string : 'Certidões'} cndData={() => getAllCNDs(props.guid_client)} guid_client={props.guid_client} />
          ) : null}

          {!isLoading && !props.certificate && checkClientPermission(validate as IResponse, props.guid_client, 'cnd.write') ? (
            <RetrieveCertificates 
              certificates={props.certificates}
              guid_client={props.guid_client}
              setBulkUpdatedStarted={() => setBulkUpdateStarted(true)}
              canShowRetrieve={canShowRetrieve}
            />
          ) : null}
        </Flex>
      </Flex>

      {!props.certificate ? 
        (<BulkUpdateCertificates
          guid_client={props.guid_client}
          location={props.location}
          socket={props.socket}
          bulkUpdateStarted={bulkUpdateStarted}
          setCanShowRetrieve={setCanShowRetrieve}
        />) 
        : null}

      {props.certificate && certificateMacroData ? (
        <CNDManagerDashboard 
          cndMacroData={certificateMacroData}
          certificate={props.certificate}
          timeSaving={'0'}
          visualizationType={selectedPersonTypeValues}
          isLoading={isChartLoading}
          guid_client={props.guid_client}
        />
      ) : null}

      <Flex mt={4}>
        {
          props.certificate ? (
            <Filters
              guid_client={props.guid_client}
              filters={filterRowsCertificate}
              certificates={[props.certificate]}
              selectedValuesHook={setFilterDataSelected}
              clearFilters={clearFilters}
              reinitFilter={reinitFilter}
            /> 
          ) : (
            <Filters
              guid_client={props.guid_client}
              filters={filterRowsCertificates}
              certificates={props.certificates}
              selectedValuesHook={setFilterDataSelected}
              reinitFilter={reinitFilter}
              /> 
          )
        }
      </Flex>

      <Flex mt={12} direction="column" position="relative">
        {!isLoading && (
          <Flex alignItems="flex-end" position="absolute" top="8px">
            {
              (hasProductCpf)
                ? (
                    <GroupRadius
                      onChange={setSelectedPersonTypeValues}
                      value={selectedPersonTypeValues}
                      options={[
                        {value: 'all', label:'Todos'},
                        {value: 'persona', label:'Pessoa Física'},
                        {value: 'company', label:'Pessoa Jurídica'}
                      ]}
                    />
                ): null
            }
          </Flex>
        )}
        <Datatables<ICNDListData>
          name="Certidões"
          columns={columns}
          actions={actions}
          customActions={CustomActions}
          metaDataHook={setMetaData}
          data={certificateList}
          isLoading={isLoading}
          guid_client={props.guid_client}
        />
      </Flex>
    </Flex>
    
    <ViewPdfModal 
      title="Visualizar Certidão" 
      file={currentFile} 
      openModal={openFileModal}
      openModalHook={setOpenFileModal}
    />

    {currentCertificate ?
      <>
        <CertificateHistory
          guid_client={props.guid_client}
          certificate={currentCertificate}
          openModal={openHistoryModal}
          openModalHook={setOpenHistoryModal}
        />

        <SearchCndModal
          guid_client={props.guid_client}
          certificate={currentCertificate}
          openModal={openSearchCertificateModal}
          openModalHook={setOpenSearchCertificateModal}
          flushHook={setRefreshData}
        />
      </>
      : null
    }
  </>);
};