import { Divider, Flex, Text } from "@chakra-ui/layout";
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/modal";
import { Image, Table, Tbody, Td, Th, Thead, Tooltip, Tr } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { ReactMultiEmail } from "react-multi-email";
import { DashFileInput } from "../../../../Users/components/DashInput";
import { cnpj as cnpjValidator } from 'cpf-cnpj-validator';
import { FormButton } from "../../../../../../components/login/button.component";
import readXlsxFile from "read-excel-file";
import Papa from 'papaparse';
import { formatCnpj } from "../../util/cnpj";
import * as XLSX from 'xlsx';

export interface IBulkCompanyList {
  name: string | null,
  cnpj: string,
  type: string | null,
  companyCode: string | null,
  stateReg: string | null,
  cep: string | null,
  state: number | string | null,
  city: number | string | null,
  errorMessage: string | null,
  guid_bulk_item_company?: string,
}

enum IndexRelation {
  NAME = 0,
  CNPJ = 1,
  TYPE = 2,
  COMPANYCODE = 3,
  STATEREG = 4,
  STATE = 5,
  CITY = 6,
  CEP = 7
}

interface IProps {
  onClose: () => void;
  nextStep: (companies: IBulkCompanyList[]) => void;
}

export const Step1Form = (props: IProps) => {
  const SUPPORTED_FORMATS = [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-excel",
    "text/csv",
    "text/plain"
  ];
  const fileUpload = useRef<HTMLInputElement>(null)
  const [companyList, setCompanyList] = useState<IBulkCompanyList[]>([])
  const [cnpjs, setCnpjs] = useState<string[]>([]);
  const [openDetails, setOpenDetails] = useState<boolean>(false)
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
  const [sendingData, setSendingData] = useState<boolean>(false);
  const [fileError, setFileError] = useState<boolean>(false);
  const [allInvalidCnpjs, setAllInvalidCnpjs] = useState<boolean>(false);

  const modelDownload = () => {
    const link = document.createElement('a');
    link.href = `https://taxly-examples.s3.us-east-2.amazonaws.com/planilha_modelo_cadastro_de_empresas_v3.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const validateFile = (file: FileList) => {
    if (!file || !file.item(0) || !SUPPORTED_FORMATS.includes(file.item(0)?.type as string)) {
      return false;
    }
    return true;
  }

  const processCsv = (data: string) => {
    const parsed = Papa.parse(data, { header: true });
    const companies: IBulkCompanyList[] = parsed.data.map((row: any) => ({
      name: row["Razão Social"] || null,
      cnpj: row["CNPJ"] ? row["CNPJ"].toString().replace(/[^\d]+/g, '').padStart(14, '0') : null,
      type: row["Tipo"] || null,
      companyCode: row["Código"] || null,
      stateReg: row["Inscrição Estadual"] || null,
      state: row["Estado"] || null,
      city: row["Cidade"] || null,
      cep: row["CEP"] || null,
      errorMessage: cnpjValidator.isValid(row["CNPJ"]) ? null : 'CNPJ_INVALIDO',
    }));
    return companies;
  }

  const processTxt = (data: string) => {
    const cnpjs = data.split(',').map(cnpj => cnpj.trim().replace(/[^\d]+/g, '').padStart(14, '0'));
    return cnpjs;
  }

  const handleFileRead = async (file: File) => {
    const reader = new FileReader();
    reader.onload = () => {
      let companies: IBulkCompanyList[] = [];
      if (file.type === 'text/csv') {
        companies = processCsv(reader.result as string);
      } else if (file.type === 'text/plain') {
        const cnpjs = processTxt(reader.result as string);
        companies = cnpjs.map(cnpj => ({
          name: null,
          cnpj,
          type: null,
          companyCode: null,
          stateReg: null,
          state: null,
          city: null,
          cep: null,
          errorMessage: cnpjValidator.isValid(cnpj) ? null : 'CNPJ_INVALIDO',
        } as IBulkCompanyList));
      }
      if (companies.length === 0) {
        setFileError(true);
      } else {
        setFileError(false);
        setCompanyList(companies);
      }
    };
    reader.readAsText(file);
  }

  const uploadFile = async (file: FileList) => {
    if (file && validateFile(file) && fileUpload.current) {
      const selectedFile = file.item(0) as File;
      if (
        selectedFile.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        || selectedFile.type === 'application/vnd.ms-excel'
      ) {
        try {
          const data = await selectedFile.arrayBuffer();
          const workbook = XLSX.read(data, { type: 'array' });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const rows = XLSX.utils.sheet_to_json(worksheet, { header: 1 }) as any[][];
  
          // Filter out empty rows
          const filledRows = rows.filter(row => row.some(cell => cell !== null && cell !== undefined && cell !== ''));
  
          if (filledRows.length === 0) {
            setFileError(true);
          } else {
            const companies: IBulkCompanyList[] = filledRows.slice(1).map((row) => {
              const cnpj = row[IndexRelation.CNPJ] ? row[IndexRelation.CNPJ].toString().replace(/[^\d]+/g, '').padStart(14, '0') : '';
              return {
                name: row[IndexRelation.NAME] ? row[IndexRelation.NAME].toString() : null,
                cnpj,
                type: row[IndexRelation.TYPE] ? row[IndexRelation.TYPE].toString() : null,
                companyCode: row[IndexRelation.COMPANYCODE] ? row[IndexRelation.COMPANYCODE].toString() : null,
                stateReg: row[IndexRelation.STATEREG] ? row[IndexRelation.STATEREG].toString() : null,
                state: row[IndexRelation.STATE] ? row[IndexRelation.STATE].toString() : null,
                city: row[IndexRelation.CITY] ? row[IndexRelation.CITY].toString() : null,
                cep: row[IndexRelation.CEP] ? row[IndexRelation.CEP].toString() : null,
              } as IBulkCompanyList;
            });
            if (companies.length === 0 || !validateCompanyColumns(filledRows[0])) {
              setFileError(true);
            } else {
              setFileError(false);
              setCompanyList(companies);
            }
          }
        } catch (error) {
          console.error("Error reading file:", error);
          setFileError(true);
        }
      } else {
        handleFileRead(selectedFile);
      }
    } else {
      setFileError(true);
    }
  };
  

  const normalizeHeader = (header: string) => {
    return header
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/\s+/g, ' ')
      .trim()
      .toLowerCase();
  };
  
  const validateCompanyColumns = (headerRow: any[]) => {
    const requiredColumns = [
      "Razao Social", 
      "CNPJ", 
      "Tipo (Matriz/Filial)", 
      "Codigo (opcional)", 
      "Inscricao Estadual (opcional)", 
      "Estado", 
      "Cidade", 
      "CEP"
    ];
  
    const normalizedHeaders = headerRow.map(header => normalizeHeader(header));
    const normalizedRequiredColumns = requiredColumns.map(header => normalizeHeader(header));
  
    return normalizedRequiredColumns.every((col, index) => normalizedHeaders[index] === col);
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      uploadFile(files);
    } else {
      setFileError(true);
      setCompanyList([]);
    }
  }

  const nextStep = async () => {
    setSendingData(true);
    const companies = cnpjs.map((cnpj) => ({
      name: null,
      cnpj: cnpj,
      type: null,
      companyCode: null,  
      stateReg: null,
      state: null,
      city: null,
      cep: null,
      errorMessage: cnpjValidator.isValid(cnpj) ? null : 'CNPJ_INVALIDO',
    } as IBulkCompanyList));

    props.nextStep([...companyList, ...companies])
    
    setTimeout(() => {
      setSendingData(false);
    }, 500);
  }

  const validarCNPJ = (cnpj: string): boolean => {
    const cnpjLimpo = cnpj.replace(/\D/g, '');

    if (cnpjLimpo.length !== 14) {
      return false;
    }
    
    return true;
  }

  const allCnpjsIsInvalid = () => {
    const areAllCnpjsInvalid = (cnpjsList: string[]) => {
      return cnpjsList.every(cnpj => !cnpjValidator.isValid(cnpj));
    }
  
    const companyCnpjs = companyList.map(company => company.cnpj);
    const allCnpjs = [...cnpjs, ...companyCnpjs];
    
    setAllInvalidCnpjs(areAllCnpjsInvalid(allCnpjs));
  }

  useEffect(() => {
    setIsButtonDisabled(cnpjs.length === 0 && companyList.length === 0);
    allCnpjsIsInvalid();
  }, [cnpjs, companyList]);

  useEffect(() => {
    setFileError(false);
    setCnpjs([]);
    setCompanyList([]);
    setAllInvalidCnpjs(false);

    if (fileUpload.current) {
      fileUpload.current.value = '';
    }
  }, []);

  return (
    <>
      <ModalHeader fontFamily="Poppins-Medium">
        <Text fontSize="21px">Cadastrar empresa em lote</Text>
        <Text fontSize="14px" fontWeight={400} color="#4A5568">
          A Taxly permite que você cadastre várias empresas de uma só vez, economizando tempo e esforço.
        </Text>
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody fontFamily="Poppins-medium" fontSize={12}>
        <Text fontSize="14px" color="#000000">
          Você pode fazer upload de um arquivo contendo os CNPJs das empresas que deseja cadastrar.
          <Tooltip label="Clique para saber mais detalhes sobre o arquivo modelo">
              <Image
                display="inline-block"
                _hover={{ cursor: 'pointer' }}
                onClick={() => setOpenDetails(true)}
                height={5}
                width={5}
                ml={2}
                src="/icons/information-circle-blue.svg"
                resize="none"
              />
          </Tooltip>
        </Text>

        <Flex flexDirection="row">
          <Text fontSize="12px" color="#4A5568" mr="10px" flexDirection="row">
            Por favor, selecione um arquivo no formato TXT, CSV ou XLS.
          </Text>

          <Flex onClick={() => modelDownload()} cursor={'pointer'} w="200px">
            <Image src="/icons/table/download-blue.svg" mr="5px"/>
            <Text 
              color="#4B4EFF"
              fontSize="12px"
              fontFamily={'Poppins-medium'}
            >
              Baixar arquivo modelo
            </Text>
          </Flex>
        </Flex>

        <Flex
          mt="15px"
          flexDirection="column"
        >
          <Flex display="flex" flexDirection="column" width="80%">
            <DashFileInput
              onChange={handleFileChange}
              ref={fileUpload}
              label="Escolher arquivo"
              accept=".xlsx,.xls,.csv,.txt"
              style={{
                fontFamily: "Poppins-medium",
                fontSize: 14,
                fontWeight: 400,
                paddingRight: 10,
              }}
            />
          </Flex>

          {
            fileError && (
              <Text color="red" pt="5px" fontSize={"12px"}>X Não foi possível validar o formato do arquivo</Text>
            )
          }

          {
            allInvalidCnpjs && companyList.length > 0 && (
              <Text color="red" pt="5px" fontSize={"12px"}>X Todos os CNPJs estão inválidos</Text>
            )
          }
        </Flex>

        <Divider m="25px 0" color="#CBD5E0" height="1px" />

        <Text fontSize="14px" color="#171923" fontWeight={500}>
          Ou você pode copiar esses CNPJs e colar no campo abaixo.
        </Text>

        <Flex flexDirection="column">
          <Flex justifyContent="flex-end">
            <Text
              color={
                cnpjs.length > 0
                  ? "var(--black-gray-gray-900)"
                  : "var(--black-gray-gray-300)"
              }
              fontFamily="Poppins-Medium"
              fontWeight={500}
              fontSize="12px"
              textAlign="end"
              _hover={{
                color: "#4b4eff",
                cursor: "pointer",
              }}
              onClick={() => setCnpjs([])}
            >
              Limpar tudo
            </Text>
          </Flex>
          
          <ReactMultiEmail
            validateEmail={(cnpj: string) => validarCNPJ(cnpj)}
            placeholder='Ex: 12.345.678/0001-90'
            emails={cnpjs}
            onChange={(_cnpjs: string[]) => {
              setCnpjs(_cnpjs);
            }}
            autoFocus={true}
            getLabel={(cnpj, index, removeCnpj) => {
              return (
                <div data-tag key={index}>
                  <div data-tag-item>{formatCnpj(cnpj)}</div>
                  <span data-tag-handle onClick={() => removeCnpj(index)}>
                    x
                  </span>
                </div>
              );
            }}
            style={{
              fontFamily: "Poppins-medium",
              fontSize: 12,
              fontWeight: 400,
              marginTop: 5,
              height: '150px',
              overflow: 'auto',
            }}
          />
        </Flex>

      {
        allInvalidCnpjs && cnpjs.length > 0 && (
          <Text color="red" pt="5px" fontSize={"12px"}>X Todos os CNPJs estão inválidos</Text>
        )
      }
      </ModalBody>

      <ModalFooter>
        <Flex marginRight="10px">
          <Text
            sx={{
              '&:hover': {
                textDecoration: 'underline'
              }
            }}
            cursor="pointer"
            color="#171923"
            marginRight={'10px'}
            onClick={props.onClose}
          >
            Fechar
          </Text>
        </Flex>
        <FormButton onClick={nextStep} disabled={isButtonDisabled || sendingData} isLoading={sendingData} color="#4B4EFF" width="150px">Validar CNPJs</FormButton>
      </ModalFooter>

      <Modal closeOnOverlayClick={true} isOpen={openDetails} onClose={() => setOpenDetails(false)} size='6xl'>
          <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px)' alignItems="center" />
          <ModalContent flexGrow={1} flexShrink={1} borderLeft="12px solid #0263FF" borderRadius={8}>
              <ModalHeader fontFamily="Poppins-SemiBold">Detalhes de preenchimento do arquivo modelo</ModalHeader>
              <ModalCloseButton />
              <ModalBody fontFamily="Poppins-medium" fontSize={12}>
                  Ao baixar o arquivo modelo, é <strong>necessário</strong> o preenchimento das colunas:
                  <Table mb={4}>
                    <Thead>
                      <Th><strong>Razão Social</strong></Th>
                      <Th><strong>CNPJ</strong></Th>
                      <Th><strong>Tipo</strong></Th>
                      <Th><strong>Código</strong></Th>
                      <Th><strong>Inscrição Estadual</strong></Th>
                      <Th><strong>Estado</strong></Th>
                      <Th><strong>Cidade</strong></Th>
                      <Th><strong>CEP</strong></Th>
                    </Thead>
                    <Tbody>
                      <Tr>
                        <Td>Empresa exemplo 1</Td>
                        <Td>11.111.111/1111-11</Td>
                        <Td>"Matriz" ou "Filial"</Td>
                        <Td></Td>
                        <Td></Td>
                        <Td>No formato UF, ex: "PR", "SP", "SC"</Td>
                        <Td>Ex: "São Paulo", "Curitiba"</Td>
                        <Td>Ex: "35450-154", "35.450-154", "35450154"</Td>
                      </Tr>
                    </Tbody>
                  </Table>
                  Como na imagem abaixo:
                  <Flex border="1px solid" borderColor="gray.400" borderRadius={5} overflow="hidden" mt={4}>
                    <Image src="images/bulk-companies.png" />
                  </Flex>
                  <Text mt={4}>Após o upload o sistema irá revisar os dados e solicitará para que seja selecionada quais empresas irão ter a busca por certidões de forma recorrente.</Text>
                  <Text color="gray.500" mt={4}>* O sistema sempre irá interpretar a primeira linha como "títulos" e portanto, será sempre ignorada.</Text>
                  <Text color="gray.500" mt={4}>* Caso a planilha tenha outras linhas preenchidas aleatoriamente, o sistema irá apontar erro.</Text>
              </ModalBody>
          </ModalContent>
      </Modal>
    </>
  );
};
