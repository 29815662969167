import { useToast } from "@chakra-ui/react";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useLocation, useNavigate } from "react-router-dom";

export const Logout = () => {
  const [cookies, removeCookie] = useCookies(["token"]);
  const navigate = useNavigate();
  const toast = useToast();
  const location = useLocation();

  const logout = () => {
    if (cookies.token) {
      removeCookie('token', null, { expires: new Date('Thu, 01 Jan 1970 00:00:01 GMT') });

      if (location.search.includes('expired=1')) {
        toast.closeAll();
        toast({
          title: 'Sessão Expirada',
          description: 'Sua sessão expirou no sistema, se deseja continuar utilizando, por favor, logue-se novamente',
          status: 'error',
          duration: 5000,
          isClosable: true
        });
      }
    }
  }

  useEffect(() => {
    logout();

    if(!cookies.token) {
      navigate('/');
    }
  }, [cookies.token, navigate, cookies])

  return (<></>);
}