import { Flex, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, useDisclosure, Text, ModalFooter } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FormButton } from "../components/login/button.component";
import { RetrieveCertificatesStyle } from "../styles/components/RetrieveCertificates";
import { IPersona } from "../_services/interface/persona.interface";
import { Datatables } from "../components/Table/Datatables";
import { FilterDataSelected, FilterRow, Filters, FilterTypeEnum } from "../components/Filters";
import { IColumn, IData, IDataMeta, ITableMetaData } from "../components/Table/table.interface";
import { stateRegionForTag } from "../components/Table/utils";

interface IProps {
  personas: IPersona[];
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
  setSelectedPersonas: React.Dispatch<React.SetStateAction<string[]>>;
  selectedPersonas: string[];
  header: string;
  description: string;
  buttonText: string;
  callback?: () => void;
  isLoading: boolean;
  guid_client: string;
}

interface IOptionsSearch {
  label: string; 
  value: string;
  search: string;
}

export const SelectPersonas = (props: IProps) => {
  const [filterDataSelected, setFilterDataSelected] = useState<FilterDataSelected|null>(null);
  
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [filteredPersonas, setFilteredPersonas] = useState<IData<IPersona>|null>(null);
  const [personaChecked, setPersonaChecked] = useState<string[]>([]);
  const [personaMetaData, setPersonaMetaData] = useState<ITableMetaData|undefined>();

  useEffect(() => {
    if (props.open) {
      updateSelecteds();
      onOpen();
    } else {
      onClose();
    }
  }, [props.open, props.selectedPersonas]);

  const updateSelecteds = () => {
    setPersonaChecked(props.selectedPersonas);
  }

  useEffect(() => {
    if (personaMetaData && props.personas && filterDataSelected) {
      const filteredPersonas = filterPersonas(props.personas, filterDataSelected);
      const orderedPersonas = sortCompanies(personaMetaData, filteredPersonas, personaChecked);

      const { currentPage, totalPages, rowByPage } = personaMetaData;
      
      const data = orderedPersonas;
      const start = (currentPage - 1) * rowByPage;
      const end = start + rowByPage;
      const paginatedData = data.slice(start, end);

      const meta: IDataMeta = {
        currentPage,
        isFirstPage: currentPage === 1,
        isLastPage: currentPage === totalPages,
        nextPage: currentPage === totalPages ? null : currentPage + 1,
        pageCount: Math.ceil(orderedPersonas.length / rowByPage),
        previousPage: currentPage === 1 ? null : currentPage - 1,
        totalCount: orderedPersonas.length
      }

      setFilteredPersonas({
        data: paginatedData,
        meta
      });
    }
  }, [personaMetaData, props.personas, filterDataSelected]);

  const selectAll = () => {
    if(filterDataSelected) {
      const filteredPersonas = filterPersonas(props.personas, filterDataSelected);

      if (personaChecked.length === filteredPersonas.filter((persona) => persona.isActive).length && personaChecked.length > 0) {
        setPersonaChecked([]);
      } else {
        setPersonaChecked(filteredPersonas.filter((persona) => persona.isActive).map((x): string => x.guid_persona));
      }
    }
  }

  const sortCompanies = (
    personaMetaData: ITableMetaData, 
    personas: IPersona[], 
    personaChecked: string[]
  ): IPersona[] => {  
    // Mover os registros dentro de personaChecked para o início do array
    const checkedPersonas = personas.filter(persona => personaChecked.includes(persona.guid_persona));
    const uncheckedPersonas = personas.filter(persona => !personaChecked.includes(persona.guid_persona));
  
    const orderField = personaMetaData.orderField as keyof IPersona;
    const orderDirection = personaMetaData.orderDirection;
  
    const compareFunction = (a: IPersona, b: IPersona): number => {
      const aValue = a[orderField];
      const bValue = b[orderField];
  
      if (aValue === undefined || bValue === undefined) {
        return 0;
      }

      if (typeof aValue === 'string' && typeof bValue === 'string') {
        const comparison = aValue.localeCompare(bValue);
        return orderDirection === 'asc' ? comparison : -comparison;
      }
  
      if (aValue < bValue) {
        return orderDirection === 'asc' ? -1 : 1;
      }
      if (aValue > bValue) {
        return orderDirection === 'asc' ? 1 : -1;
      }
      return 0;
    };
  
    // Ordenar os uncheckedPersonas de acordo com a orderField e orderDirection
    uncheckedPersonas.sort(compareFunction);
    checkedPersonas.sort(compareFunction);
  
    // Combinar as listas checkedPersonas e sorted uncheckedPersonas
    const sortedData = [...checkedPersonas, ...uncheckedPersonas];
    
    return sortedData;
  };

  const filterPersonas = (orderedPersonas: IPersona[], filterDataSelected: FilterDataSelected): IPersona[] => {
    let personas = orderedPersonas;

    if (orderedPersonas.length > 0) {
      if(filterDataSelected[FilterTypeEnum.personaNameFilter].length > 0) {
        personas = personas.filter((persona) => filterDataSelected[FilterTypeEnum.personaNameFilter].includes(persona.guid_persona));
      }

      if(filterDataSelected[FilterTypeEnum.personaDocumentFilter].length > 0) {
        personas = personas.filter((company) => filterDataSelected[FilterTypeEnum.personaDocumentFilter].includes(company.guid_persona));
      }

      // Criando uma lista de empresas filtradas
      let filteredPersonas = personas;

      // Adicionando empresas selecionadas à lista filtrada
      filteredPersonas = filteredPersonas.concat(props.personas.filter(company => personaChecked.includes(company.guid_persona)));

      // Removendo duplicatas (se houver)
      filteredPersonas = Array.from(new Set(filteredPersonas));

      return filteredPersonas;
    }

    return [];
  }

  const submitForm = () => {
    props.setSelectedPersonas(personaChecked);
    props.setOpen(false);

    if (props.callback) {
      props.callback();
    }
  }

  const filterRows = [
    {
      rowFields: [
        {
          name: FilterTypeEnum.personaNameFilter,
          position: 1,
          size: 6,
        },
        {
          name: FilterTypeEnum.personaDocumentFilter,
          position: 2,
          size: 6,
        },
      ],
      position: 1
    },
  ] as FilterRow[];

  const columns = [
    {
      title: 'Nome',
      name: 'name',
      sortable: true,
      align: 'left',
      elipse: {
        columnName: 'isScheduled',
        tooltipTextTrue: 'Agendada a pesquisa de certidões',
        tooltipTextFalse: 'Não agendada a pesquisa de certidões',
        validation: (isScheduled: boolean) => {
          return isScheduled;
        }
      }
    },
    {
      title: 'CPF',
      name: 'document_formated',
      sortable: true,
      align: 'center',
    },
    {
      title: 'CEP',
      name: 'cep_formated',
      orderFieldName: 'cep',
      sortable: true,
      align: 'center',
    },
    {
      title: 'Estado',
      name: 'state_initials',
      sortable: true,
      align: 'center',
      dynamicTag: {
        function: stateRegionForTag,
        field: 'state_initials'
      },
    },
    {
      title: 'Cidade',
      name: 'city_name',
      sortable: true,
      align: 'center',
      tags: [
        { 
          color: '#E2E8F0',
          textColor: '#1A202C'
        },
      ]
    },
    {
      title: 'Status',
      name: 'isActive',
      sortable: true,
      align: 'center',
      tags: [
        { 
          value: false, 
          text: 'Inativa',
          color: '#FEE2E2',
          textColor: '#7F1D1D'
        },
        { 
          value: true,
          text: 'Ativa',
          color: '#DCFCE7',
          textColor: '#171923'
        }
      ]
    },
    
  ] as IColumn[];

  return (
    <>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={() => props.setOpen(false)} size="7xl" >
        <RetrieveCertificatesStyle>
          <ModalOverlay className="modal-overlay" />
          <ModalContent width={1487} className="modal-content" >
            <ModalHeader className="header">
              <Text className="title">{props.header}</Text>
              <Text className="description">
                <div>
                  <Flex style={{marginTop: "16px"}} className="blue-text">{props.description}</Flex>
                </div>
              </Text>
              <Flex
                style={{marginTop: "12px"}}
              >
                <Filters
                  guid_client={props.guid_client} 
                  filters={filterRows}
                  selectedValuesHook={setFilterDataSelected}
                />
              </Flex>
            </ModalHeader>

            <ModalCloseButton /> 

            <ModalBody fontFamily="Poppins-Medium">
              <hr />
              <Flex alignItems="stretch" direction="column" gap={2} flexGrow={1} mt={8} mb={4} >
                <Datatables<IPersona>
                  name="Pessoas"
                  columns={columns}
                  metaDataHook={setPersonaMetaData}
                  data={filteredPersonas}
                  isLoading={props.isLoading}
                  showTotalRows={true}
                  heigth="400px"
                  checkBox={{
                    selecteds: personaChecked,
                    setSelecteds: setPersonaChecked,
                    checkAll: selectAll,
                    key: 'guid_persona'
                  }}
                />
              </Flex>
            </ModalBody> 
            <ModalFooter>
              <Flex width="100%" justifyContent="end" alignItems="flex-start" display="flex">
                <FormButton onClick={submitForm} width="auto">
                  {props.buttonText}
                </FormButton> 
              </Flex>
            </ModalFooter>
          </ModalContent>
        </RetrieveCertificatesStyle>
      </Modal>
    </>
  );
}