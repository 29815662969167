/**
 * Clears a CNPJ string removing dots, dashes and slashes
 * @param {string} cnpj The CNPJ
 * @returns 
 */
export const clearCnpj = (cnpj: string): string => {
  return cnpj.replace(/[-./]/g,'');
}

/**
 * Format a CNPJ string correctly with dots, dashes and slashes
 * @param {string} cnpj The CNPJ
 * @returns 
 */
export const formatCnpj = (cnpj: string): string => {
  const cleanedCnpj = cnpj.replace(/\D/g, '');
  const truncatedCnpj = cleanedCnpj.slice(0, 14);
  return truncatedCnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
}

export const formatCEP = (cep: string): string => {
  const cleanedCep = cep.replace(/\D/g, '');
  const truncatedCep = cleanedCep.slice(0, 8);
  return truncatedCep.replace(/(\d{5})(\d{3})/, '$1-$2');
}