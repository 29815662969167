import moment from "moment";
import { ICNDDataResponse, ICNDListData, ICNDResponse } from "../../../../_services/interface/cnd.interface";
import { CSSProperties } from "react";
import { IStatus } from "../../../../_services/interface/certificate.interface";
import { ITag } from "../../../../components/Table/table.interface";
import { BulkUpdateItemCertificateStatus, IBulkItemCertificate } from "../../../../_services/interface/bulk-update.interface";
import { stateRegionForTag } from "../../../../components/Table/utils";
import { FilterDataSelected, FilterTypeEnum } from "../../../../components/Filters";

export const confirmMonth = (month: string) => {
  if (month === "Janeiro") {
    return 1;
  } else if (month === "Fevereiro") {
    return 2;
  } else if (month === "Março") {
    return 3;
  } else if (month === "Abril") {
    return 4;
  } else if (month === "Maio") {
    return 5;
  } else if (month === "Junho") {
    return 6;
  } else if (month === "Julho") {
    return 7;
  } else if (month === "Agosto") {
    return 8;
  } else if (month === "Setembro") {
    return 9;
  } else if (month === "Outubro") {
    return 10;
  } else if (month === "Novembro") {
    return 11;
  } else if (month === "Dezembro") {
    return 12;
  }
}

export const jurisdictionLabel = (jurisdiction: string) => {
  if (jurisdiction === 'federal') {
    return "Federal";
  } else if (jurisdiction === 'state') {
    return 'Estadual';
  } else if (jurisdiction === 'municipal') {
    return 'Municipal';
  }
}

export const jurisdictionStyle = (jurisdiction: string): CSSProperties|undefined => {
  const baseStyle = {
    w: "100%", 
    alignItems: "center",
    justifyContent: "center",
    p: "2px",
    fontSize: "10px",
    textTransform: "uppercase",
    fontWeight: "bold"
  }

  if (jurisdiction === "federal") {
    return {
      ...baseStyle,
      backgroundColor: "#8FDCDC",
    } as CSSProperties;
  } else if (jurisdiction === 'state') {
    return {
       ...baseStyle,
        backgroundColor: "#EFAD2C",
    } as CSSProperties;
  } else if (jurisdiction === 'municipal') {
    return {
        ...baseStyle,
        backgroundColor: "#9b77ff",
    } as CSSProperties;
  }
}

export const statusForTagList = (cnd: ICNDListData) => {
  const status = cnd.status as IStatus;

  if(!status) {
    return {
      text: "-",
      color: "#000",
      textColor: "#000",
      minWidth: "118px",
    } as ITag
  }

  let tooltip = {};
  let tooltipIcon = {};
  if (cnd.hasLastIssued) {
    tooltipIcon = {
      tootltipIconText: `Empresa possui certidão ainda válida.`,
      tootltipIconUrl: "/icons/information-circle-blue.svg"
    }
  }

  if(status.tooltip_icon) {
    tooltipIcon = {
      tootltipIconText: status.tooltip_text,
      tootltipIconUrl: `../icons/${status.tooltip_icon}.svg`
    }
  }

  if(cnd.message) {
    tooltip = {
      tootltipText: `Mensagem de retorno obtida da fonte de consulta: ${cnd.message}`
    }
  }

  return {
      text: cnd.hasLastIssued ? status.name : status.name,
      color: status.color,
      textColor: status.textColor ?? "#000",
      minWidth: "118px",
      ...tooltip,
      ...tooltipIcon,
  } as ITag
}

export const statusForTagListUpdate = (bulkItem: IBulkItemCertificate) => {
  const cnd = bulkItem.cnd;
  let tooltip = {};

  if (!cnd) {
    return {
      text: "Erro",
      color: "#FEF9C3",
      textColor: "#000",
      minWidth: "118px",
    } as ITag;
  }

  const entity = cnd.type_company === 3 ? 'Pessoa Fisica' : "Empresa";

  if (bulkItem.status === BulkUpdateItemCertificateStatus.PENDING) {
    tooltip = {
      tootltipText: 'A pesquisa está sendo realizada pela taxly. Em breve traremos um retorno sobre essa consulta. Por favor aguarde.',
      iconUrl: `../icons/information-circle-blue.svg`
    }
    return {
      text: "Atualizando...",
      color: "#DDE5FF",
      textColor: "#000",
      minWidth: "118px",
      ...tooltip
    } as ITag
  }

  const status = cnd.status as IStatus;

  if (cnd.hasLastIssued && cnd.isNegative === false) {
    tooltip = {
      tootltipText: `${entity} possui certidão ainda válida.`,
      iconUrl: "/icons/information-circle-blue.svg"
    }
  }

  if(!status) {
    return {
      text: "Erro",
      color: "#FEF9C3",
      textColor: "#000",
      minWidth: "118px",
    } as ITag;
  }

  if(status.tooltip_icon) {
    tooltip = {
      tootltipText: status.tooltip_text,
      iconUrl: `../icons/${status.tooltip_icon}.svg`
    }
  }

  return {
      text: status.name,
      color: status.color,
      textColor: "#000",
      minWidth: "118px",
      ...tooltip
  } as ITag
}

export const typeCompanyForTagListUpdate = (cnd: ICNDDataResponse) => {
  if (!cnd) {
    return {
      text: "-",
      textColor: "#000",
      minWidth: "118px",
    } as ITag;
  }

  const typeCompany = cnd.type_company;

  if (typeCompany === 0) {
    return {
      text: 'Matriz',
      value: 0,
      textColor: '#365314',
      color: '#ECFCCB',
    } as ITag;
  } else if (typeCompany === 1) {
    return {
      text: 'Filial',
      value: 1,
      textColor: '#065666',
      color: '#C4F1F9',
    } as ITag;
  }

  return {
    text: "-",
    textColor: "#000",
    minWidth: "118px",
  } as ITag;
}

export const jurisdictionForTagListUpdate = (cnd: ICNDDataResponse) => {
  if (cnd) {
    if (cnd.jurisdiction === 'federal') {
      return       {
        text: 'Federal',
        value: 'federal',
        textColor: '#065666',
        color: '#C4F1F9',
      } as ITag;
    } else if (cnd.jurisdiction === 'municipal') {
      return       {
        text: 'Municipal',
        value: 'municipal',
        textColor: '#134E4A',
        color: '#CCFBF1',
      } as ITag;
    } else if (cnd.jurisdiction === 'state') {
      return       {
        text: 'Estadual',
        value: 'state',
        textColor: '#322659',
        color: '#E9D8FD',
      } as ITag;
    }
  }

  return {};
}

export const stateRegionForTagListUpdate = (cnd: ICNDDataResponse) => {
  if (cnd && cnd.state) {
    return stateRegionForTag(cnd.state);
  }
  return {};
}

export const cityForTagListUpdate = (cnd: ICNDDataResponse) => {
  if (cnd && cnd.city) {
    return { 
      text: cnd.city,
      color: '#E2E8F0',
      textColor: '#1A202C'
    } as ITag;
  }
  return {};
}
export const getValidUntilDates = (validUntilRules: string[]) => {

  let startDueDate;
  let endDueDate;

  if (validUntilRules.length > 0) {
    switch (validUntilRules[0]) {
      case "not-valid":
        return {
          startDueDate: startDueDate,
          endDueDate: moment.utc().format('YYYY-MM-DD')
        };
      case "15-days":
        return {
          startDueDate: moment.utc().format('YYYY-MM-DD'),
          endDueDate: moment.utc().add(15, 'days').format('YYYY-MM-DD')
        };
      case "15-days-more":
        return {
          startDueDate: moment.utc().add(15, 'days').format('YYYY-MM-DD'),
          endDueDate: endDueDate
        };
      case "30-days-more":
        return {
          startDueDate: moment.utc().add(30, 'days').format('YYYY-MM-DD'),
          endDueDate: endDueDate
        };
      case "45-days-more":
        return {
          startDueDate: moment.utc().add(45, 'days').format('YYYY-MM-DD'),
          endDueDate: endDueDate
        };
      case "60-days-more":
        return {
          startDueDate: moment.utc().add(60, 'days').format('YYYY-MM-DD'),
          endDueDate: endDueDate
        };
      case "90-days-more":
        return {
          startDueDate: moment.utc().add(90, 'days').format('YYYY-MM-DD'),
          endDueDate: endDueDate
        };
      case "120-days-more":
        return {
          startDueDate: moment.utc().add(120, 'days').format('YYYY-MM-DD'),
          endDueDate: endDueDate
        };
      default:
        return {startDueDate: startDueDate, endDueDate: endDueDate};
    }
  }
  return {startDueDate: startDueDate, endDueDate: endDueDate};
}

export const orderCertificate = (certificates: ICNDListData[], orderField: keyof ICNDListData, orderDirection: 'asc' | 'desc'): ICNDListData[] => {
  const compareFunction = (a: ICNDListData, b: ICNDListData): number => {
    const aValue = a[orderField];
    const bValue = b[orderField];

    if (aValue === undefined || bValue === undefined || aValue === null || bValue === null) {
      return 0;
    }

    if (typeof aValue === 'string' && typeof bValue === 'string') {
      const comparison = aValue.localeCompare(bValue);
      return orderDirection === 'asc' ? comparison : -comparison;
    }

    if (aValue < bValue) {
      return orderDirection === 'asc' ? -1 : 1;
    }
    if (aValue > bValue) {
      return orderDirection === 'asc' ? 1 : -1;
    }
    return 0;
  };

  return certificates.sort(compareFunction);
}

export const filterCertificate = (certificates: ICNDListData[], filterDataSelected: FilterDataSelected|null): ICNDListData[] => {
  let certificatesForFilter = certificates;

  if(!filterDataSelected) {
    return certificates;
  }

  if (certificatesForFilter.length > 0) {
    if(filterDataSelected[FilterTypeEnum.companyFilter] && filterDataSelected[FilterTypeEnum.companyFilter].length > 0) {
      const companyFilter = filterDataSelected[FilterTypeEnum.companyFilter];

      certificatesForFilter = certificatesForFilter.filter((certificate) => certificate.guid_company && companyFilter.includes(certificate.guid_company));
    }

    if(filterDataSelected[FilterTypeEnum.companyTypeFilter] && filterDataSelected[FilterTypeEnum.companyTypeFilter].length === 1) {
      certificatesForFilter = certificatesForFilter.filter((certificate) => { return certificate.entity.type === Number(filterDataSelected[FilterTypeEnum.companyTypeFilter][0]) });
    }

    if(filterDataSelected[FilterTypeEnum.certificateStateFilter] && filterDataSelected[FilterTypeEnum.certificateStateFilter].length > 0) {
      certificatesForFilter = certificatesForFilter.filter((certificate) => { return certificate.state && filterDataSelected[FilterTypeEnum.certificateStateFilter].includes(certificate.state.initials) });
    }

    if(filterDataSelected[FilterTypeEnum.certificateCityFilter] && filterDataSelected[FilterTypeEnum.certificateCityFilter].length > 0) {
      certificatesForFilter = certificatesForFilter.filter((certificate) => { return certificate.city && filterDataSelected[FilterTypeEnum.certificateCityFilter].includes(certificate.city.name) });
    }


    if(filterDataSelected[FilterTypeEnum.companyCodeFilter] && filterDataSelected[FilterTypeEnum.companyCodeFilter].length > 0) {
      certificatesForFilter = certificatesForFilter.filter((certificate) => { return certificate.entity.code && filterDataSelected[FilterTypeEnum.companyCodeFilter].includes(certificate.entity.code) });
    }

    if(filterDataSelected[FilterTypeEnum.certificateTypeFilter] && filterDataSelected[FilterTypeEnum.certificateTypeFilter].length > 0) {
      certificatesForFilter = certificatesForFilter.filter((certificate) => { return filterDataSelected[FilterTypeEnum.certificateTypeFilter].includes(certificate.slug.split('_')[0]) });
    }

    if(filterDataSelected[FilterTypeEnum.certificateStatusFilter] && filterDataSelected[FilterTypeEnum.certificateStatusFilter].length > 0) {
      const statuses = filterDataSelected[FilterTypeEnum.certificateStatusFilter];
      const statusValues: string[] = [];
      statuses.forEach((status) => {
        const statusSplited = status.split('_');
        const stat = statusSplited[statusSplited.length - 1];

        if (!statusValues.some((s) => s === stat)) {
          statusValues.push(stat);
        }
      });

      certificatesForFilter = certificatesForFilter.filter((certificate) => { return statusValues.includes(certificate.slug_status) });
    }

    if (filterDataSelected[FilterTypeEnum.validUntilFilter] && filterDataSelected[FilterTypeEnum.validUntilFilter].length > 0) {
      const {startDueDate, endDueDate} = getValidUntilDates(filterDataSelected[FilterTypeEnum.validUntilFilter]);

      certificatesForFilter = certificatesForFilter.filter((certificate) => {
        if(certificate.validUntil === null) {
          return false;
        }
        const validUntilDate = moment.utc(certificate.validUntil, 'YYYY-MM-DD');
        const isAfterStart = startDueDate ? validUntilDate.isSameOrAfter(startDueDate) : true;
        const isBeforeEnd = endDueDate ? validUntilDate.isSameOrBefore(endDueDate) : true;
        return isAfterStart && isBeforeEnd;
      });
    }

    if(filterDataSelected[FilterTypeEnum.searchTypeFilter] && filterDataSelected[FilterTypeEnum.searchTypeFilter].length === 1) {
      if(filterDataSelected[FilterTypeEnum.searchTypeFilter][0] === '1') {
        certificatesForFilter = certificatesForFilter.filter((certificate) => { return certificate.isManual });
      }

      if(filterDataSelected[FilterTypeEnum.searchTypeFilter][0] === '0') {
        certificatesForFilter = certificatesForFilter.filter((certificate) => { return !certificate.isManual });
      }
    }

    if(filterDataSelected[FilterTypeEnum.jurisdictionFilter] && filterDataSelected[FilterTypeEnum.jurisdictionFilter].length > 0) {
      certificatesForFilter = certificatesForFilter.filter((certificate) => { return filterDataSelected[FilterTypeEnum.jurisdictionFilter].includes(certificate.jurisdiction) });
    }

    return certificatesForFilter;
  }

  return [];
}
