import { Flex, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text, Tooltip, useDisclosure, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FormButton } from "../../../../../components/login/button.component";
import { postEditCompanyGroup } from "../../../../../_services/companyGroup.service";
import { checkClientPermission } from "../../../Users/utils/checkPermission";
import { IResponse } from "../../../../../_services/interface/user.interface";
import useLoginFormStore from "../../../../../store/useLoginFormStore";

interface IDesativarCompanyGroupModalProps {
  group: any;
  guid_client: string;
  flushHook: React.Dispatch<React.SetStateAction<boolean>>;
  openModal?: boolean;
  openModalHook?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DesativarCompanyGroupModal = (props: IDesativarCompanyGroupModalProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [ isButtonDisabled, setButtonDisabled ] = useState<boolean>(false);
  const toast = useToast();
  const { validate } = useLoginFormStore();

  const yesOption = async () => {
    setButtonDisabled(true);
    const isActive = !props.group.isActive;
    const guid_companies = props.group.companies.map((relation: any) => relation.guid_company);

    const { status, response } = await postEditCompanyGroup(props.guid_client, props.group.guid_company_group, {
      name: props.group.name,
      isActive: isActive,
      companies: guid_companies
    });

    const text = isActive ? 'Ativado' : 'Desativado';
  
    if (status === 200) {
      toast({
        title: `Grupo de empresas ${text} com sucesso`,
        description: `O Grupo ${props.group.name} foi ${text} com sucesso`,
        status: 'success',
        isClosable: true,
        duration: 5000,
      });
      props.flushHook(true);
      onClose();
    } else {
      toast({
        title: 'Falha ao atualizar o grupo de empresas',
        description: `Algum erro ocorreu ao tentar atualizar o grupo ${props.group.name}. Tente novamente mais tarde`,
        status: 'error',
        isClosable: true,
        duration: 5000,
      });
      props.flushHook(true);
      onClose();
    }
    setButtonDisabled(false);
  };

  useEffect(() => {
    if(props.openModalHook && props.openModal && props.group ) {
      onOpen();
      props.openModalHook(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.openModal, props.group]);

  return (
    <>

      <Modal closeOnOverlayClick={false} isCentered={true} isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px)' alignItems="center" />
        <ModalContent flexGrow={1} flexShrink={1} borderLeft="12px solid #0263FF" borderRadius={8}>
          {
            props.group.isActive ? 
              <ModalHeader fontFamily="Poppins-SemiBold">Deseja realmente desativar?</ModalHeader>
            :
              <ModalHeader fontFamily="Poppins-SemiBold">Ativar Grupo de Empresas</ModalHeader>
          }
          
          <ModalCloseButton />
          <ModalBody fontFamily="Poppins-medium" fontSize={12}>
            <Flex justifyContent="center" flexDirection="column" gap={5}>
              {
                props.group.isActive ? 
                  <Text>Tem certeza que deseja desativar o grupo <strong>{props.group.name}</strong>?</Text>
                :
                  <Text>Tem certeza que deseja ativar o grupo <strong>{props.group.name}</strong>?</Text>
              }
              <Text>Atualmente existem <b>{props.group.count} empresas</b> vinculadas a esse grupo.</Text>
              <Flex  justifyContent="end" alignItems={'center'}>
                <Flex marginRight="10px">
                  <Text
                    sx={{
                      '&:hover': {
                        textDecoration: 'underline'
                      }
                    }}
                    cursor="pointer"
                    color="#171923"
                    marginRight={'10px'}
                    onClick={onClose}
                  >
                    Cancelar e Fechar
                  </Text>
                </Flex>
                {
                  props.group.isActive ? 
                    <FormButton onClick={yesOption} disabled={isButtonDisabled} color="#4B4EFF" width="150px">Desativar Grupo</FormButton>
                  :
                    <FormButton onClick={yesOption} disabled={isButtonDisabled} width="150px">Ativar Grupo</FormButton>
                }
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}