import { 
    Flex, 
    Modal, 
    ModalBody, 
    ModalCloseButton, 
    ModalContent, 
    ModalHeader, 
    ModalOverlay, 
    useDisclosure, 
    useToast, 
    Text, 
    ButtonGroup, 
    Button, 
    Select,
    Checkbox,
    Accordion,
    AccordionItem,
    AccordionButton,
    Box,
    AccordionIcon,
    AccordionPanel,
    GridItem,
    Grid,
    Tooltip
} from "@chakra-ui/react";

import { useEffect, useState } from "react";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from "react-hook-form";
import { AddIcon } from "@chakra-ui/icons";
import useLoginFormStore from "../../../../store/useLoginFormStore";
import { DashInput, DashInputMask } from "../../Users/components/DashInput";
import { FormButton } from "../../../../components/login/button.component";
import MiniLoading from "../../../../components/miniLoading";
import { postAddPersona } from "../../../../_services/persona.service";
import { clearCpf } from "../utils/cpf";
import { getCityByState, getStates } from "../../../../_services/region.service";
import { IValueLabel } from "../../Companies/Company/modals/EditCompanyModal";
import { ICityResponse, IRegionResponse } from "../../../../_services/interface/region.interface";
import { checkClientPermission } from "../../Users/utils/checkPermission";
import { IResponse } from "../../../../_services/interface/user.interface";
import { cpf } from 'cpf-cnpj-validator';
import CustomAsyncSelect from "../../../../components/CustomAsyncSelect";

interface IPersonaCreateForm {
  name: string;
  document: string;
  state: number;
  city: number;
  cep: string;
  isScheduled: boolean;
  searchFederal: boolean;
  searchState: boolean;
  searchCnd: boolean;
}

interface ICreatePersonaModalProps {
  flushHook: React.Dispatch<React.SetStateAction<boolean>>;
  guid_client: string;
}

export const CreatePersonaModal = (props: ICreatePersonaModalProps) => {

  const YupPersonaChangeForm = Yup.object().shape({
    name: Yup.string().required('Nome'),
    document: Yup.string().required('CPF')
      .test('cpf-validation', 'CPF inválido', (value) => cpf.isValid(value as string)),
    state: Yup.number().required('Estado'),
    city: Yup.number().required('Municipio'),
    cep: Yup.string(),
    isScheduled: Yup.bool(),
    searchFederal: Yup.bool(),
    searchState: Yup.bool(),
    searchCnd: Yup.bool(),
  });

  const resolverForm = { resolver: yupResolver(YupPersonaChangeForm) };

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [allStates, setAllStates] = useState<IValueLabel[]>([]);
  const [allCities, setAllCities] = useState<IValueLabel[]>([]);

  const [selectedState, setSelectedState] = useState<number>();
  const [isStateChanged, setStateChanged] = useState<number>();

  const [selectedStates, setSelectedStates] = useState<number[]>([]);
  const [showMultiState, setShowMultiState] = useState<boolean>(false)

  const [accErrorMessage, setAccErrorMessage] = useState<string[]>([]);
  const [simpleErrorMessage, setSimpleErrorMessage] = useState<string>();
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { validate } = useLoginFormStore();
  const { formState: { errors }, setValue, getValues, register, watch, handleSubmit, reset } = useForm<IPersonaCreateForm>(resolverForm);
  const toast = useToast();

  //Check Form Errors
  useEffect(() => {
    if (errors) {
      setAccErrorMessage(Object.values(errors).map((_x) => _x.message) as string[]);
    }
  }, [errors]);

  useEffect(() => {
    if (isOpen) {
      stateList();

      //Reset all the fields
      reset();

      setValue('searchFederal', true);
      setValue('searchState', true);

      //Reset any error message
      setAccErrorMessage([]);
      setSimpleErrorMessage("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    watch((value) => {
      if (value.state !== selectedState) {
        setStateChanged(value.state);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  
  useEffect(() => {
    if (isStateChanged) {
      cityList(isStateChanged)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStateChanged]);

  useEffect(() => {
    setShowMultiState(getValues('searchState'));
  }, [watch('searchState')]);

  const stateList = async () => {
    const { status, response } = await getStates();
    if (status === 200) {
      const organizeStates: IValueLabel[] = [];
      (response as IRegionResponse[]).forEach((_e) => {
        organizeStates.push({
          value: _e.id_state,
          label: _e.name,
          initials: ""
        })
      });

      //Load all the states into a state
      setAllStates(organizeStates);
      //Set the state
      setValue("state", 1);

      //Set the state to indicate if its changed or not
      setSelectedState(1);
      //Load the cities of the state 1 (Acre)
      cityList(1);
    } else {
      toast({
        title: 'Falha ao listar os estados',
        description: `Ocorreu um erro ao tentar listar os estados, por favor, tente novamente.`,
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }
  }

  const cityList = async (id_state: number) => {
    const { status, response } = await getCityByState(id_state);
    if (status === 200) {
      const orgnizeCities: IValueLabel[] = [];
      (response as ICityResponse).cities.forEach((_x) => {
        orgnizeCities.push({
          value: _x.id_city,
          label: _x.name,
          initials: ""
        });
      });

      setAllCities(orgnizeCities);
      setValue('city', orgnizeCities[0].value)
    } else {
      toast({
        title: 'Falha ao listar os estados',
        description: `Ocorreu um erro ao tentar listar as cidades, por favor, tente novamente.`,
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }
  }

  const handleFormInfo = async (data: IPersonaCreateForm) => {
    setSubmitDisabled(true);
    setIsLoading(true);

    const { status } = await postAddPersona(props.guid_client, {
        name: data.name,
        document: clearCpf(data.document),
        id_city: data.city,
        id_state: data.state,
        states: selectedStates.join(','),
        cep: data.cep,
        isScheduled: data.isScheduled,
        searchCnd: data.searchCnd,
        searchFederal: !!data.searchFederal,
        searchState: !!data.searchState,
        isActive: true
    });

    if (status === 200) {
        toast({
            title: 'Pessoa adicionada com sucesso',
            description: `A pessoa ${data.name} foi criada com sucesso`,
            status: 'success',
            duration: 5000,
            isClosable: true
        });

        //Reset the entire form
        reset({
          name: '',
          document: ''
        });
        //Refresh the list
        props.flushHook(true);
        //Close the modal
        onClose();
    } else {
        toast({
            title: 'Falha ao criar a pessoa',
            description: `Houve um problema desconhecido ao realizar o cadastro da pessoa ${data.name}.`,
            status: 'error',
            duration: 5000,
            isClosable: true
        });

        props.flushHook(true);

        onClose();
    }
    setIsLoading(false)
    setSubmitDisabled(false);
  };

  const handleMultiState = (stateId: number) => {
    setSelectedStates((prevSelectedStates) => {
      const states = [...prevSelectedStates];
      const stateIndex = states.findIndex((state) => state === stateId);
      if (stateIndex !== -1) {
        states.splice(stateIndex, 1);
      } else {
        states.push(stateId);
      }
      return states;
    });
  }
  
  const checkAllStates = () => {
    setSelectedStates((prevSelectedStates) => {
      if (prevSelectedStates.length !== allStates.length) {
        return allStates.map((state) => state.value);
      } else {
        return [];
      }
    });
  }

  return (
    <>
      <ButtonGroup onClick={onOpen} size="sm" mt="24px" isAttached color="white" >
        <Button leftIcon={<AddIcon />} bgColor="#4B4EFF" color="white" _hover={{ bg: '#282be0' }}>
          Adicionar Nova Pessoa
        </Button>
      </ButtonGroup>

      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px) ' alignItems="center" />
        <ModalContent flexGrow={1} flexShrink={1} borderRadius={8} borderLeft="12px solid #0263FF">

          <ModalHeader fontFamily="Poppins-Medium">Cadastro de Pessoa</ModalHeader>
          <ModalCloseButton />
          <ModalBody fontFamily="Poppins-Medium">
            {accErrorMessage.length > 0 ? (
              <Flex bg="#FFCFCF" color="#A93333" alignItems="flex-start" flexDirection="column" fontSize="12px" p={2} borderRadius={5}>
                <Text>Por favor, informe os campos obrigatórios:</Text>
                {accErrorMessage.map((_x, _i) => (
                  <Flex key={_i}>{_x}</Flex>
                ))}
              </Flex>
            ) : null}
            {simpleErrorMessage ? (
              <Flex bg="#FFCFCF" color="#A93333" alignItems="flex-start" flexDirection="column" fontSize="12px" p={2} borderRadius={5}>
                <Flex>{simpleErrorMessage}</Flex>
              </Flex>
            ) : null}
            <Flex alignItems="stretch" direction="column" gap={2} flexGrow={1} pb={4}>

              <DashInput placeholder="Nome Completo" {...register("name", { required: true })} />

              <Flex direction="row" gap={2} flexGrow={1}>
                <DashInputMask mask="999.999.999-99" placeholder="CPF" {...register("document", { required: true })} />
              </Flex>

              <Flex direction="row" gap={2} flexGrow={1}>
                <Flex w="50%" direction="column">
                  <CustomAsyncSelect
                    placeholder="Selecione o estado"
                    options={allStates}
                    setValue={(value: string | number) => {
                      setValue('state', Number(value));
                    }}
                    value={getValues('state')}
                  />
                </Flex>
                <Flex w="50%" direction="column">
                  <CustomAsyncSelect
                    placeholder="Selecione a cidade"
                    options={allCities}
                    setValue={(value: string | number) => {
                      setValue('city', Number(value));
                    }}
                    value={getValues('city')}
                  />
                </Flex>
              </Flex>

              <Flex direction="row" gap={2} flexGrow={1}>
                <DashInputMask mask="99.999-999" type="text" placeholder="CEP" {...register("cep")} />
              </Flex>

              {checkClientPermission(validate as IResponse, props.guid_client, 'cnd.write') ? (
                <Flex direction="column" gap={2} flexGrow={1} mt={4}>
                  <Checkbox defaultChecked size='sm' colorScheme='green' {...register("searchCnd")}>
                    <Text fontSize={14}>Pesquisar Certidões ao terminar o cadastro</Text>
                    <Text fontSize="11px" opacity={0.5}>
                      Ao selecionar esta opção, o sistema irá realizar a busca das Certidões desta pessoa ao término deste cadastro.
                    </Text>
                  </Checkbox>

                  <Checkbox defaultChecked size='sm' colorScheme='green' {...register("isScheduled")}>
                    <Text fontSize={14}>Agendar pesquisa recorrente das Certidões</Text>
                    <Text fontSize="11px" opacity={0.5}>
                      Ao selecionar esta opção, o sistema irá programar a atualização recorrente das Certidões desta pessoa.
                    </Text>
                  </Checkbox>
                </Flex>
              ) : null}

              <Flex direction="column">
                <Accordion allowToggle>
                  <AccordionItem>
                    <h2>
                      <AccordionButton>
                        <Box as="span" flex='1' textAlign='left'>
                          <Flex direction="column">
                            <Text fontSize="14px">Selecionar jurisdições para buscar certidões e consultas</Text>
                            <Text fontSize="11px" opacity={0.5}>
                              Por padrão, o sistema irá pesquisar por certidões de origem Federal e Estadual (quando disponíveis). Caso deseje que o sistema busque por somente um tipo, por favor selecione abaixo:
                            </Text>
                          </Flex>
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <Flex direction="row" gap={4}>
                        <Checkbox size="sm" colorScheme="green" {...register("searchFederal")}>Federal</Checkbox>
                        <Checkbox size="sm" colorScheme="green" {...register("searchState")}>Estadual</Checkbox>
                      </Flex>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </Flex>

              <Flex direction="column">
                <Accordion allowToggle index={showMultiState ? undefined : -1 }>
                  <AccordionItem borderTop="none" isDisabled={!showMultiState}>
                    <h2>
                      <AccordionButton>
                        <Box as="span" flex='1' textAlign='left'>
                          <Flex direction="column">
                            <Text fontSize="14px">Selecionar outros estados para emitir certidões para esta pessoa</Text>
                            <Text fontSize="11px" opacity={0.5}>
                            Por padrão, o sistema irá pesquisar as CNDs Estaduais apenas para o Estado de cadastro da pessoa. Caso deseje pesquisar CNDs Estaduais para mais de um Estado, por favor selecione os demais Estados que deverão ser pesquisados.
                          </Text>
                          </Flex>
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <Checkbox mb={4}
                        colorScheme="green"
                        onChange={() => checkAllStates()}
                        isChecked={selectedStates.length === allStates.length}
                      >
                        <Text fontSize={12}>Selecionar tudo</Text>
                      </Checkbox>
                      <Grid templateColumns='repeat(3, 1fr)' gap={6}>
                        {allStates.map((_el, _id) => (
                          <GridItem>
                            <Flex maxW="160px" minW="100px" minH="15px" key={_id}>
                              <Tooltip label={_el.value === getValues('state') ? `Pessoa já cadastrada em ${_el.label}` : null}>
                                <Flex>
                                  <Checkbox
                                    colorScheme="green"
                                    isDisabled={_el.value === getValues('state')}
                                    onChange={() => handleMultiState(_el.value)}
                                    isChecked={selectedStates.find((stateId) => stateId === _el.value) ? true : false}
                                  >
                                    <Text fontSize={11}>{_el.label}</Text>
                                  </Checkbox>
                                </Flex>
                              </Tooltip>
                            </Flex>
                          </GridItem>
                        ))}
                      </Grid>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </Flex>

              <Flex alignItems="stretch" direction="row" gap={2} flexGrow={1} mt={4} justifyContent="center">
                <FormButton onClick={handleSubmit(handleFormInfo)} disabled={submitDisabled}>
                  {isLoading ? <MiniLoading size={20} /> : 'Cadastrar pessoa'}
                </FormButton>
              </Flex>
            </Flex>
          </ModalBody>

        </ModalContent>
      </Modal>
    </>
  );
}