import { ICertificate } from "../../../../_services/interface/certificate.interface";

export function getCertificatePropertyValuesRecursively(
    certificate: ICertificate,
    propertyName: keyof ICertificate,
    resultArray: any[] = []
): any[] {  
    if (certificate[propertyName]) {
      resultArray.push(certificate[propertyName]);
    }
  
    if (certificate.children) {
      for (const child of certificate.children) {
        getCertificatePropertyValuesRecursively(child, propertyName, resultArray);
      }
    }
  
    return resultArray;
}

export function getCertificatesPropertyValuesRecursively(
    certificates: ICertificate[],
    propertyName: keyof ICertificate
): any[] {
    const values: any[] = [];
    for (const certificate of certificates) {
        values.push(getCertificatePropertyValuesRecursively(certificate, propertyName));
    }
  
    return values;
}


// This code filters an array of certificates based on the value of a specified property.
// It recursively iterates through the certificates array and its children arrays.
// If a certificate has a child with the specified property value, the certificate is included
// in the filtered array. If a certificate does not have a child with the specified property value,
// the certificate is not included in the filtered array.
export function filterCertificatesByPropertyAndValue(
  certificates: ICertificate[],
  propertyName: keyof ICertificate,
  propertyValue: any
): ICertificate[] {
  const filteredCertificates: ICertificate[] = [];

  function recursiveFilter(certificatesToFilter: ICertificate[]): void {
    for (const certificate of certificatesToFilter) {
      const parentHasProperty = certificate[propertyName] === propertyValue;
      const childWithProperty = certificate.children?.some(
        (child) => child[propertyName] === propertyValue
      );

      if (parentHasProperty || childWithProperty) {
        filteredCertificates.push(certificate);
      }

      if (certificate.children) {
        recursiveFilter(certificate.children);
      }
    }
  }

  recursiveFilter(certificates);

  return filteredCertificates;
}

export function getCertificateRecursivelyByProperty(
  certificates: ICertificate[],
  propertyName: keyof ICertificate,
  propertyValue: any
): ICertificate[] {
  const filteredCertificates: ICertificate[] = [];

  function recursiveFilter(certificatesToFilter: ICertificate[]): void {
    for (const certificate of certificatesToFilter) {
      if (certificate[propertyName] === propertyValue) {
        filteredCertificates.push(certificate);
      }

      if (certificate.children) {
        recursiveFilter(certificate.children);
      }
    }
  }

  recursiveFilter(certificates);

  return filteredCertificates;
}


// certificates: An array of certificates.
// slug: The slug of the certificate to be found.
// Returns the certificate whose slug matches the slug passed as an argument to the function. If there are no certificates with the passed slug, it returns null.
export function getCertificateBySlug(certificates: ICertificate[], slug: string) {
  const certificatesFiltered = filterCertificatesByPropertyAndValue(certificates, 'slug', slug);
  if (certificatesFiltered.length > 0) {
    return certificatesFiltered[0];
  }
    
  return null;
}

// Function that gets the value of a property from a certificate by its slug
export function getPropertyBySlug(certificates: ICertificate[], property: keyof ICertificate, slug: string) {
  const certificate = getCertificateRecursivelyByProperty(certificates, 'slug', slug);

  if (certificate[0] && certificate[0][property]) {
    return certificate[0][property];
  }

  return null;
}

export function getAllCertificatesSeparately(certificate: ICertificate) {
  const certificatesSet = new Set<ICertificate>();

  function traverseAndAddCertificates(cert: ICertificate) {
    if (cert) {
      certificatesSet.add(cert);

      if (cert.children) {
        for (const child of cert.children) {
          traverseAndAddCertificates(child);
        }
      }
    }
  }

  traverseAndAddCertificates(certificate);

  // Converter o conjunto para um array e ordenar pelos slugs
  const certificatesArray = Array.from(certificatesSet).sort((a, b) =>
    a.slug.localeCompare(b.slug as string)
  );

  return certificatesArray;
}  