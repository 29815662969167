import { 
    Flex, 
    Modal, 
    ModalBody, 
    ModalCloseButton, 
    ModalContent, 
    ModalHeader, 
    ModalOverlay, 
    useDisclosure, 
    useToast, 
    Text,
    Tooltip,
    Image,
    Checkbox,
    Select,
    Accordion,
    AccordionItem,
    AccordionButton,
    Box,
    AccordionIcon,
    AccordionPanel,
    Grid,
    GridItem
} from "@chakra-ui/react";

import { useEffect, useState } from "react";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from "react-hook-form";
import useLoginFormStore from "../../../../store/useLoginFormStore";
import { DashInput, DashInputMask } from "../../Users/components/DashInput";
import { FormButton } from "../../../../components/login/button.component";
import MiniLoading from "../../../../components/miniLoading";
import { getPersona, postEditPersona } from "../../../../_services/persona.service";
import { clearCpf } from "../utils/cpf";
import { checkClientPermission } from "../../Users/utils/checkPermission";
import { IResponse } from "../../../../_services/interface/user.interface";
import { getCityByState, getStates } from "../../../../_services/region.service";
import { IValueLabel } from "../../Companies/Company/modals/EditCompanyModal";
import { ICityResponse, IRegionResponse } from "../../../../_services/interface/region.interface";
import { IPersona } from "../../../../_services/interface/persona.interface";
import CustomAsyncSelect from "../../../../components/CustomAsyncSelect";

interface IPersonaEditForm {
  name: string;
  document: string;
  state: number;
  city: number;
  cep: string;
  isScheduled: boolean;
  searchFederal: boolean;
  searchState: boolean;
}

interface IEditPersonaModalProps {
  flushHook: React.Dispatch<React.SetStateAction<boolean>>;
  guid_client: string;
  guid_persona: string;
  openModal: boolean;
  openModalHook: React.Dispatch<React.SetStateAction<boolean>>;
}

export const EditPersonaModal = (props: IEditPersonaModalProps) => {

  const YupPersonaChangeForm = Yup.object().shape({
    name: Yup.string().required('Nome'),
    document: Yup.string().required('CPF'),
    state: Yup.number().required('Estado'),
    city: Yup.number().required('Municipio'),
    cep: Yup.string(),
    isScheduled: Yup.bool(),
    searchFederal: Yup.bool(),
    searchState: Yup.bool(),
  });

  const resolverForm = { resolver: yupResolver(YupPersonaChangeForm) };

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [allStates, setAllStates] = useState<IValueLabel[]>([]);
  const [allCities, setAllCities] = useState<IValueLabel[]>([]);

  const [isStateChanged, setStateChanged] = useState<number>();

  const [currentSelectedState, setSelectedState] = useState<number>();
  const [currentSelectedCity, setSelectedCity] = useState<number>();

  const [isLoadingData, setLoadingData] = useState<boolean>(true);

  const [persona, setPersona] = useState<IPersona>();

  const [selectedStates, setSelectedStates] = useState<number[]>([]);
  const [showMultiState, setShowMultiState] = useState<boolean>(false)

  
  const [isScheduled, setIsScheduled] = useState<boolean>(false);
  const [searchFederal, setSearchFederal] = useState<boolean>(false);
  const [searchState, setSearchState] = useState<boolean>(false);
  const [accErrorMessage, setAccErrorMessage] = useState<string[]>([]);
  const [simpleErrorMessage, setSimpleErrorMessage] = useState<string>();
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { validate } = useLoginFormStore();
  const { formState: { errors }, setValue, getValues, register, watch, handleSubmit, reset } = useForm<IPersonaEditForm>(resolverForm);
  const toast = useToast();

  const loadPersonaInfo = async () => {
    setIsLoading(true);
    const { status, response } = await getPersona(props.guid_client, props.guid_persona);
    if (status === 200 && 'guid_persona' in response) {
      setPersona(response as IPersona);
    }

    setIsLoading(false);
  }

  const stateList = async (selectedState: number) => {
    const { status, response } = await getStates();
    if (status === 200) {
      const organizeStates: IValueLabel[] = [];
      (response as IRegionResponse[]).forEach((_e) => {
        organizeStates.push({
          value: _e.id_state,
          label: _e.name,
          initials: _e.initials
        })
      });

      //Load all the states into a state
      setAllStates(organizeStates);

      const optionValue = organizeStates.find((_x) => _x.value === selectedState);
      if (optionValue) {
        //Mark the selected state
        setSelectedState(optionValue.value);
        //Load all the cities now
      } else {
        setSelectedState(organizeStates[0].value);
        //Set the city by the first state
      }
    }
  };

  const cityList = async (id_state: number, selectedCity: number | null = null) => {
    const { status, response } = await getCityByState(id_state);
    if (status === 200) {
      const orgnizeCities: IValueLabel[] = [];
      (response as ICityResponse).cities.forEach((_x) => {
        orgnizeCities.push({
          value: _x.id_city,
          label: _x.name,
          initials: "",
        });
      });

      setAllCities(orgnizeCities);

      if (selectedCity) {
        const optionValue = orgnizeCities.find((_x) => _x.value === selectedCity);
        if (optionValue) {
          //Mark the selected state
          setSelectedCity(optionValue.value);
          //setValue("city", optionValue.value.toString());
        }
      } else {
        setSelectedCity(orgnizeCities[0].value);
        //City not selected so the system will enforce the first value
        //setValue("city", orgnizeCities[0].value.toString());
      }
      setLoadingData(false)
    } else {
      toast({
        title: 'Falha ao Listar as cidades',
        description: `Algum erro ocorreu ao tentar listar as cidades, por favor, tente novamente mais tarde.`,
        status: 'error',
        isClosable: true,
        duration: 5000,
      });
      //Close the modal to avoid more errors
      onClose();
    }
  }

  useEffect(() => {
    if (isStateChanged) {
      cityList(isStateChanged, null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStateChanged]);

  useEffect(() => {
    setIsScheduled(getValues('isScheduled'));
  }, [watch('isScheduled')])

  useEffect(() => {
    setSearchFederal(getValues('searchFederal'));
  }, [watch('searchFederal')])

  useEffect(() => {
    setSearchState(getValues('searchState'));

    setShowMultiState(getValues('searchState'))
  }, [watch('searchState')])

  useEffect(() => {
    watch((value) => {
      //Check if the state is different
      if (value.state !== currentSelectedState) {
        if (!isLoadingData) {
          setSelectedState(value.state);
          setStateChanged(value.state);
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch, currentSelectedState, currentSelectedCity, isLoadingData]);

  useEffect(() => {
    if(persona) {
      if (currentSelectedState) {
        setValue("state", currentSelectedState);
        cityList(currentSelectedState, persona.id_city);
      }
  
      if (currentSelectedCity) {
        setValue("city", currentSelectedCity);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSelectedState, currentSelectedCity, persona]);

  useEffect(() => {
    if(persona) {
      setValue('name', persona.name);
      setValue('document', persona.document);
      setValue('cep', persona.cep);
      setValue('isScheduled', persona.isScheduled);
      setValue('searchFederal', persona.searchFederal);
      setValue('searchState', persona.searchState);

      setShowMultiState(persona.searchState);

      if(persona.states) {
        setSelectedStates(persona.states.split(',').map(state => Number(state)));
      }

      stateList(persona.id_state);
      setLoadingData(true)
    }
  }, [persona]);

  //Check Form Errors
  useEffect(() => {
    if (errors) {
      setAccErrorMessage(Object.values(errors).map((_x) => _x.message) as string[]);
    }
  }, [errors]);

  useEffect(() => {
    if (isOpen) {
      //Reset all the fields
      reset();
      //Reset any error message
      setAccErrorMessage([]);
      setSimpleErrorMessage("");

      setSelectedStates([]);
      loadPersonaInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (props.openModal) {
      onOpen();
      props.openModalHook(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.openModal]);

  const handleFormInfo = async (data: IPersonaEditForm) => {
    setSubmitDisabled(true);
    setIsLoading(true);

    const { status } = await postEditPersona(props.guid_client, props.guid_persona, {
      name: data.name,
      document: clearCpf(data.document),
      id_state: data.state,
      id_city: data.city,
      cep: data.cep,
      isScheduled: data.isScheduled,
      searchFederal: data.searchFederal,
      searchState: data.searchState,
      states: selectedStates.join(','),
    });

    if (status === 200) {
        toast({
            title: 'Pessoa editada com sucesso',
            description: `A pessoa ${data.name} foi editada com sucesso`,
            status: 'success',
            duration: 5000,
            isClosable: true
        });

        //Reset the entire form
        reset({
            name: '',
            document: ''
        });
        //Refresh the list
        props.flushHook(true);
        //Close the modal
        onClose();
    } else {
        toast({
            title: 'Falha ao editar a pessoa',
            description: `A pessoa ${data.name} não foi editada.`,
            status: 'error',
            duration: 5000,
            isClosable: true
        });
        //Refresh the list
        props.flushHook(true);
        //Close the modal
        onClose();
    }
    setIsLoading(false)
    setSubmitDisabled(false);
  };

  const handleMultiState = (stateId: number) => {
    setSelectedStates((prevSelectedStates) => {
      const states = [...prevSelectedStates];
      const stateIndex = states.findIndex((state) => state === stateId);
      if (stateIndex !== -1) {
        states.splice(stateIndex, 1);
      } else {
        states.push(stateId);
      }
      return states;
    });
  }
  
  const checkAllStates = () => {
    setSelectedStates((prevSelectedStates) => {
      if (prevSelectedStates.length !== allStates.length) {
        return allStates.map((state) => state.value);
      } else {
        return [];
      }
    });
  }

  return (
    <>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px) ' alignItems="center" />
        <ModalContent flexGrow={1} flexShrink={1} borderRadius={8} borderLeft="12px solid #0263FF">

          <ModalHeader fontFamily="Poppins-Medium">Edição de Pessoa</ModalHeader>
          <ModalCloseButton />
          <ModalBody fontFamily="Poppins-Medium">
            {accErrorMessage.length > 0 ? (
              <Flex bg="#FFCFCF" color="#A93333" alignItems="flex-start" flexDirection="column" fontSize="12px" p={2} borderRadius={5}>
                <Text>Por favor, informe os campos obrigatórios:</Text>
                {accErrorMessage.map((_x, _i) => (
                  <Flex key={_i}>{_x}</Flex>
                ))}
              </Flex>
            ) : null}
            {simpleErrorMessage ? (
              <Flex bg="#FFCFCF" color="#A93333" alignItems="flex-start" flexDirection="column" fontSize="12px" p={2} borderRadius={5}>
                <Flex>{simpleErrorMessage}</Flex>
              </Flex>
            ) : null}
            <Flex alignItems="stretch" direction="column" gap={2} flexGrow={1} pb={4}>

              <DashInput placeholder="Nome" {...register("name", { required: true })} />
              <Flex direction="row" gap={2} flexGrow={1}>
                <DashInputMask mask="999.999.999-99" placeholder="CPF" {...register("document", { required: true })} />
              </Flex>

              
              <Flex direction="row" gap={2} flexGrow={1}>
                <Flex w="50%" direction="column">
                  <CustomAsyncSelect
                    placeholder="Selecione o estado"
                    options={allStates}
                    setValue={(value: string | number) => {
                      setValue('state', Number(value));
                    }}
                    value={getValues('state')}
                  />
                </Flex>
                <Flex w="50%" direction="column">
                  <CustomAsyncSelect
                    placeholder="Selecione a cidade"
                    options={allCities}
                    setValue={(value: string | number) => {
                      setValue('city', Number(value));
                    }}
                    value={getValues('city')}
                  />
                </Flex>
              </Flex>

              <Flex direction="row" gap={2} flexGrow={1}>
                <DashInputMask mask="99.999-999" type="text" placeholder="CEP" {...register("cep")} />
              </Flex>

              {checkClientPermission(validate as IResponse, props.guid_client, 'cnd.write') ? (
                <Flex direction="column" gap={2} flexGrow={1} mt={4}>
                  <Checkbox size='sm' isChecked={isScheduled} colorScheme='green' {...register("isScheduled")}>
                    <Text fontSize={14}>Agendar pesquisa recorrente das certidões para esta pessoa?</Text>
                    <Text fontSize="11px" opacity={0.5}>
                      Marcando esta opção, o sistema buscará recorrentemente as certidões cadastradas na plataforma para esta pessoa nas datas de recorrências definidas na aba "Configurações" ao lado.
                    </Text>
                  </Checkbox>
                </Flex>
              ) : null}

              <Flex direction="column">
                <Accordion allowToggle>
                  <AccordionItem>
                    <h2>
                      <AccordionButton>
                        <Box as="span" flex='1' textAlign='left'>
                          <Flex direction="column">
                            <Text fontSize="14px">Selecionar jurisdições para buscar certidões e consultas</Text>
                            <Text fontSize="11px" opacity={0.5}>
                              Por padrão, o sistema irá pesquisar por certidões de origem Federal e Estadual (quando disponíveis). Caso deseje que o sistema busque por somente um tipo, por favor selecione abaixo:
                            </Text>
                          </Flex>
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <Flex direction="row" gap={4}>
                        <Checkbox size="sm" colorScheme="green" isChecked={searchFederal} {...register("searchFederal")}>Federal</Checkbox>
                        <Checkbox size="sm" colorScheme="green" isChecked={searchState} {...register("searchState")}>Estadual</Checkbox>
                      </Flex>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </Flex>

              <Flex direction="column">
                <Accordion allowToggle index={showMultiState ? undefined : -1 }>
                  <AccordionItem borderTop="none" isDisabled={!showMultiState}>
                    <h2>
                      <AccordionButton>
                        <Box as="span" flex='1' textAlign='left'>
                          <Flex direction="column">
                            <Text fontSize="14px">Selecionar outros estados para emitir certidões para esta pessoa</Text>
                            <Text fontSize="11px" opacity={0.5}>
                            Por padrão, o sistema irá pesquisar as CNDs Estaduais apenas para o Estado de cadastro da pessoa. Caso deseje pesquisar CNDs Estaduais para mais de um Estado, por favor selecione os demais Estados que deverão ser pesquisados.
                          </Text>
                          </Flex>
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <Checkbox mb={4}
                        colorScheme="green"
                        onChange={() => checkAllStates()}
                        isChecked={selectedStates.length === allStates.length}
                      >
                        <Text fontSize={12}>Selecionar tudo</Text>
                      </Checkbox>
                      <Grid templateColumns='repeat(3, 1fr)' gap={6}>
                        {allStates.map((_el, _id) => (
                          <GridItem>
                            <Flex maxW="160px" minW="100px" minH="15px" key={_id}>
                              <Tooltip label={_el.value === currentSelectedState ? `Pessoa já cadastrada em ${_el.label}` : null}>
                                <Flex>
                                  <Checkbox
                                    colorScheme="green"
                                    isDisabled={_el.value === currentSelectedState}
                                    onChange={() => handleMultiState(_el.value)}
                                    isChecked={selectedStates.find((stateId) => stateId === _el.value) ? true : false}
                                  >
                                    <Text fontSize={11}>{_el.label}</Text>
                                  </Checkbox>
                                </Flex>
                              </Tooltip>
                            </Flex>
                          </GridItem>
                        ))}
                      </Grid>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </Flex>

              <Flex alignItems="stretch" direction="row" gap={2} flexGrow={1} mt={4} justifyContent="center">
                <FormButton onClick={handleSubmit(handleFormInfo)} disabled={submitDisabled}>
                  {isLoading ? <MiniLoading size={20} /> : 'Atualizar dados'}
                </FormButton>
              </Flex>
            </Flex>
          </ModalBody>

        </ModalContent>
      </Modal>
    </>
  );
}